<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Získajte na Bory Bývanie úrok nižší o 3 % ročne</h1>
          <p>Financujte svoje bývanie úverom na bývanie s nižšou úrokovou sadzbou o 3&nbsp;%&nbsp;p.&nbsp;a. pri dvojročnej fixácii.</p>
          <b-button @click="scrollTo('finance-box')" squared variant="primary">Chcem vedieť viac</b-button>
        </div>
      </div>
    </div>
    <section class="procare-program py-5 px-3" id="finance-box">
      <div class="container">
        <p class="title text-center mt-md-4">Výhody financovania so zníženým Bory úrokom </p>
        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <div class="box">
              <div class="icon">
              <img src="../assets/images/znizeny-urok/ikona1-urok.svg" alt="">
            </div>
              <p>Nižšia úroková sadzba o&nbsp;3&nbsp;%&nbsp;p.&nbsp;a. platí pri akejkoľvek schválenej výške úveru na bývanie</p>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="box">
              <div class="icon">
              <img src="../assets/images/znizeny-urok/ikona2-zmluva.svg" alt="">
            </div>
              <p>Znížený úrok je stanovený zmluvne, priamo v úverovej zmluve</p>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="box">
              <div class="icon">
              <img src="../assets/images/znizeny-urok/ikona3-kalendar.svg" alt="">
            </div>
              <p>Nižšiu splátku máte garantovanú počas prvých 2&nbsp;rokov pri úvere na bývanie s&nbsp;2&#8209;ročnou fixáciou úrokovej sadzby</p>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="box">
              <div class="icon">
              <img src="../assets/images/znizeny-urok/tb_logo_biele.png" alt="">
            </div>
              <p>Platí pre žiadosti o úver na bývanie podané v ktorejkoľvek pobočke Tatra banky alebo prostredníctvom kontaktného centra DIALOG Live</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section-3 top p-3 pt-5">
      <div>
        <div class="container mb-10">
          <div class="row px-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-5 col-12 px-md-5 py-3">
              <h2>Financujte bez <br>zložitých procesov</h2>
            </div>
            <div class="col-12 col-md-5 pr-md-5">
              <div class="box">
                <p>Znížený Bory úrok o 3&nbsp;%&nbsp;p.&nbsp;a. budete mať zazmluvnený priamo v úverovej zmluve.</p>
                <p>Mesačne tak budete splácať rovno nižšiu sumu splátky, bez toho, aby vám developer cenový rozdiel doplácal neskôr, či už vo forme jednorazovej alebo mesačnej dotácie.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section-3 p-3">
      <div>
        <div class="row">
          <div class="col-md-7 mb-4 mb-md-0">
            <img src="../assets/images/znizeny-urok/img-1.jpg">
          </div>
          <div class="col-12 col-md-5 px-md-4 py-3">
            <h2>Kto môže využiť <br>znížený Bory úrok?</h2>
            <p>Znížený úrok o 3 % p. a. môže využiť ktorýkoľvek záujemca o kúpu bytu na Boroch, ktorý žiada o&nbsp;poskytnutie úveru na bývanie v Tatra banke.</p>
            <p class="mb-10">O poskytnutie úveru na bývanie môže záujemca žiadať sám alebo prostredníctvom nášho hypotekárneho poradcu.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="loan-example py-5">
      <div class="container">
        <h2 class="px-4 text-center">Modelový príklad mesačnej splátky úveru na bývanie so&nbsp;zníženým Bory úrokom</h2>
        <div class="row mb-5">
          <div class="col-12 col-md-4 px-4 mb-5 mb-md-0">
            <div class="box p-4">
              <h3 class="mb-4 text-center">Splátka s komerčným úrokom</h3>
              <div class="d-flex item">
                <div class="left">Výška hypotéky</div>
                <div class="right">240 000 €</div>
              </div>
              <div class="d-flex item">
                <div class="left">Doba splatnosti</div>
                <div class="right">30 rokov</div>
              </div>
              <div class="d-flex item">
                <div class="left">Doba fixácie</div>
                <div class="right">2 roky</div>
              </div>
              <div class="d-flex item">
                <div class="left">Úroková sadzba na hypotekárnej zmluve</div>
                <div class="right">4,69 % p. a.</div>
              </div>
              <div class="d-flex item mb-5">
                <div class="left">Úroková sadzba pre výpočet splátky</div>
                <div class="right">4,69 % p. a.</div>
              </div>
              <div class="d-flex item">
                <div class="left">Mesačná splátka</div>
                <div class="right">1 243,29 €</div>
              </div>
              <div class="d-flex item mb-5">
                <div class="left">Dlžná suma na konci 2.&nbsp;roka</div>
                <div class="right">232&nbsp;334,08&nbsp;€</div>
              </div>
              <div class="d-flex item">
                <div class="left">Ušetrený rozdiel na splátkach</div>
                <div class="right">-</div>
              </div>
              <div class="d-flex item">
                <div class="left">Ušetrený rozdiel na istine</div>
                <div class="right">-</div>
              </div>
              <div class="d-flex item">
                <div class="left">Spolu ušetríte</div>
                <div class="right">-</div>
              </div>
            </div>
            
          </div>

          <div class="col-12 col-md-4 px-4 mb-5 mb-md-0">
            <div class="box p-4">
              <h3 class="mb-4 text-center">Splátka s bežnou dotovanou hypotékou</h3>
              <div class="d-flex item">
                <div class="left">Výška hypotéky</div>
                <div class="right">240 000 €</div>
              </div>
              <div class="d-flex item">
                <div class="left">Doba splatnosti</div>
                <div class="right">30 rokov</div>
              </div>
              <div class="d-flex item">
                <div class="left">Doba fixácie</div>
                <div class="right">2 roky</div>
              </div>
              <div class="d-flex item">
                <div class="left">Úroková sadzba na hypotekárnej zmluve</div>
                <div class="right">4,69 % p. a.</div>
              </div>
              <div class="d-flex item mb-5">
                <div class="left">Úroková sadzba pre výpočet splátky</div>
                <div class="right">1,69 % p. a.</div>
              </div>
              <div class="d-flex item">
                <div class="left">Mesačná splátka</div>
                <div class="right">850,35 €</div>
              </div>
              <div class="d-flex item mb-5">
                <div class="left">Dlžná suma na konci 2.&nbsp;roka</div>
                <div class="right">232&nbsp;334,08&nbsp;€</div>
              </div>
              <div class="d-flex item">
                <div class="left">Ušetrený rozdiel na splátkach</div>
                <div class="right">9 430,56 €</div>
              </div>
              <div class="d-flex item">
                <div class="left">Ušetrený rozdiel na istine</div>
                <div class="right">-</div>
              </div>
              <div class="d-flex item">
                <div class="left">Spolu ušetríte</div>
                <div class="right">9 430,56 €</div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 px-4">
            <div class="box p-4 bory">
              <h3 class="text-blue mb-4 text-center">Splátka so zníženým Bory&nbsp;úrokom</h3>
              <div class="d-flex item">
                <div class="left">Výška hypotéky</div>
                <div class="right">240 000 €</div>
              </div>
              <div class="d-flex item">
                <div class="left">Doba splatnosti</div>
                <div class="right">30 rokov</div>
              </div>
              <div class="d-flex item">
                <div class="left">Doba fixácie</div>
                <div class="right">2 roky</div>
              </div>
              <div class="d-flex item">
                <div class="left">Úroková sadzba na hypotekárnej zmluve</div>
                <div class="right">1,69 % p. a.</div>
              </div>
              <div class="d-flex item mb-5">
                <div class="left">Úroková sadzba pre výpočet splátky</div>
                <div class="right">1,69 % p. a.</div>
              </div>
              <div class="d-flex item border-blue">
                <div class="left">Mesačná splátka</div>
                <div class="right font-weight-bold">850,35 €</div>
              </div>
              <div class="d-flex item mb-5">
                <div class="left">Dlžná suma na konci 2.&nbsp;roka</div>
                <div class="right">227&nbsp;502,39&nbsp;€</div>
              </div>
              <div class="d-flex item">
                <div class="left">Ušetrený rozdiel na splátkach</div>
                <div class="right">9 430,56 €</div>
              </div>
              <div class="d-flex item">
                <div class="left">Ušetrený rozdiel na istine</div>
                <div class="right">4 831,69 €</div>
              </div>
              <div class="d-flex item border-blue">
                <div class="left">Spolu ušetríte</div>
                <div class="right font-weight-bold">14 262,25 €</div>
              </div>
            </div>
          </div>
        </div>
        <div class="note">
          <p class="mb-4">*Modelový príklad sme pre vás vypočítali z&nbsp;ponuky 3-izbového bytu, ktorého cena aj s&nbsp;parkovacím miestom a&nbsp;kobkou predstavuje 300&nbsp;000 €. <br>Výška hypotekárneho úveru, pri financovaní 80 % z hodnoty zakladanej nehnuteľnosti a 30-ročnej splatnosti, by tak bola 240 000 €.</p>
          <p class="font-size-default mb-4">Kompletné podmienky akcie nájdete v&nbsp;<a href="../../docs/Podmienky_akcie_Znizeny_urok.pdf" target="_blank" class="w-fw-700">Podmienky akcie „Znížený úrok“</a>.</p>
          <p class="font-size-default">Ponuka úverov na bývanie Tatra banky vrátane reprezentatívneho príkladu je dostupná na <a href="https://www.tatrabanka.sk/sk/personal/uvery/hypoteka/" target="_blank">webovej stránke</a>.</p>
        </div>
      </div>
    </section>

    <section class="temp-block px-3 px-md-0 py-3">
      <div class="d-flex flex-wrap px-0 pl-md-5 pr-md-3 mb-0 justify-content-between">
        <div class="col-12 col-lg-4 mb-4 mb-lg-0 px-0 py-lg-5">
          <h2 class="mb-md-5 mb-4 pr-md-5">Nájdite svoje bývanie na Boroch</h2>
          <p class="mb-5">Vyberte si z&nbsp;voľných bytov v&nbsp;jednotlivých etapách projektu Bory Bývanie</p>
          <div class="row">
            <div class="col-12 col-md-8 mb-4">
              <a href="./byvanie/novy-dvor" class="btn btn-primary rounded-0 d-block">Bory Nový Dvor</a>
            </div>
            <div class="col-12 col-md-8 mb-4">
              <a href="./byvanie/bory3" class="btn btn-primary rounded-0 d-block">Bory Promenáda</a>
            </div>
            <div class="col-12 col-md-8 mb-4">
              <a href="./byvanie/na-hradzi" class="btn btn-primary rounded-0 d-block">Bory Na Hrádzi</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7 px-0">
          <div class="img-2"></div>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>

  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/ContactForm";

export default {
  name: 'Procare',
  components: {
    ContactForm
  },

  data() {
    return {
      procareModal: false,
      modalClass: '',
      gallerySlick: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        arrows: false
      },
    }
  },

  methods: {
    scrollTo(id) {
      const map = document.getElementById(id)
      map.scrollIntoView({behavior: "smooth", block: 'center'})

    },
    showProcareModal(className) {
      this.$refs['procare-modal'].show()
      this.modalClass = className
    },
    scrollToContact() {
      const contactForm = document.getElementById('contact-form')
      if (contactForm) {
        contactForm.scrollIntoView({behavior: "smooth", block: 'center'})
      } else {
        this.$router.push({ path: 'kontakt#contact-form' })
      }

    },
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.loan-example
  background: var(--darkBlue)

  .box
    border: 1px solid rgba(255, 255, 255, .2)

    &.bory
      border: none
      background: var(--textBright)

  h2
    font-size: 55px
    line-height: 60px
    font-weight: 500
    margin-bottom: 100px
    letter-spacing: -3px
    margin-left: -4px
    
    @media (max-width: $md)
      font-size: 30px
      line-height: 30px
      letter-spacing: -2px
      margin-bottom: 40px

  .item
    border-bottom: 1px solid #ccc
    display: flex
    justify-content: space-between
    align-items: center
    padding: 8px
    font-size: 14px

    &.border-blue
      background: var(--blue)

    @media (max-width: $md)
      font-size: 14px

    .left
      max-width: 60%

    .right
      font-size: 18px
      font-weight: 700

      @media (max-width: $md)
        font-size: 18px

  .note
    p
      font-size: 14px
      text-align: center
      &.font-size-default
        font-size: 16px


.faq
  background: var(--textBright)
  padding: 70px 0

  .text
    align-items: center
    justify-content: space-between
    width: 100%
    @media (max-width: $md)
      flex-direction: column
    p
     max-width: 480px
     font-size: 16px
     line-height: 24px
     margin-bottom: 0
     @media (max-width: $md)
      margin-bottom: 15px

     a
      color: var(--blue)

  @media (max-width: $md)
    padding: 40px 0

  h3
    font-weight: 300
    font-size: 55px
    line-height: 60px

    @media (max-width: $md)
      font-size: 34px
      line-height: 38px
      margin-bottom: 20px

  .question-wrapper
    border-top: 1px solid rgba(255, 255, 255, 0.20)
    padding: 25px 0

    &:last-child
      border-bottom: 1px solid rgba(255, 255, 255, 0.20)

    @media (max-width: $md)
      padding: 10px 0

    .question
      padding: 15px 0
      font-size: 20px
      font-weight: 700
      line-height: 40px
      padding-left: 50px
      position: relative

      @media (max-width: $md)
        line-height: 24px

      &.not-collapsed
        &:before
          background-image: url(../assets/images/procare/minus.svg)

      &:before
        content: ""
        width: 32px
        height: 32px
        background-color: rgba(255, 255, 255, 0.05)
        background-image: url(../assets/images/procare/plus.svg)
        background-repeat: no-repeat
        background-position: center
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)

    .answer
      font-size: 16px
      font-weight: 400
      line-height: 24px
      padding-left: 50px

.procare-program
  .title
    font-size: 30px
    font-weight: 500
    letter-spacing: -0.24px
    margin-bottom: 50px
    line-height: 28px

    @media (max-width: $md)
      padding-left: 0
      font-size: 20px
  .box
    border: 1px solid rgba(255, 255, 255, 0.20)
    padding: 56px 40px 70px
    text-align: center
    position: relative
    min-height: 462px

    @media (max-width: $md)
      padding: 20px 20px 40px
      margin-bottom: 15px
      min-height: auto

    .link
      position: absolute
      right: 15px
      bottom: 15px
      font-size: 14px

    .icon
      height: 100px
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 40px

      img
        max-height: 100%
        height: 100%

    p
      font-size: 18px
      font-weight: 500
      margin-bottom: 40px

    .text-blue
      color: var(--blue)
      font-weight: 700
      font-size: 20px
      line-height: 24px
      margin-bottom: 0

  .notes
    padding-left: 30px
    
    @media (max-width: $md)
      padding-left: 0
    p
      margin-bottom: 0
      color: rgba(255, 255, 255, 0.50)
      font-size: 14px


.home
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/images/znizeny-urok/intro.jpg") left/cover no-repeat
  position: relative
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url("../assets/images/Novy_Dvor_zima.jpg") center/cover no-repeat

  .procare-badge
    position: absolute
    left: 65%
    top: 20%

    img
      max-width: 266px

      @media (max-width: $md)
        max-width: 100%

    @media (max-width: 1200px)
      top: 80px
      left: 50%
      transform: translate(-50%, 0)

    @media (max-width: $md)
      transform: translate(-50%, 0)
      top: 80px
      width: 140px
      height: 140px
  .intro-text
    p
      max-width: 640px
      margin-bottom: 30px

  #fixed-info
    position: absolute
    bottom: 0
    right: 0
    z-index: 999999
    background: var(--blue)

    @media (max-width: $md)
      display: none

    .box
      position: relative
      padding: 45px 25px 25px
      max-width: 460px
      .big
        font-size: 84px
        font-weight: 800
        position: absolute
        top: -66px
        left: 25px

      .border-bottom-white
        padding-bottom: 20px
        border-bottom: 1px solid #fff
        margin-bottom: 20px
        font-size: 28px
        line-height: 28px
        font-weight: normal

      .text-dark
        margin-bottom: 0
        font-size: 16px
        font-weight: 400

        a
          font-weight: 600
          color: #343a40
          text-decoration: underline
          
          &::after
            content: ""
            width: 100%
            height: 100%
            position: absolute
            left: 0
            bottom: 0
            cursor: pointer
.interactive-map
  //height: 92vh
  position: relative
  .overlay
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url("../assets/images/mapoverlay2.png") no-repeat
    background-size: cover
.section-3
  background: var(--textDark)
  color: #fff
  text-align: left

  &.top
    background: var(--textBright)

  .mb-10
    margin-bottom: 60px

    @media (max-width: $md)
      margin-bottom: 0

  .box
    padding: 40px 30px
    @media (max-width: $md)
      padding: 0
  .col-p0
    @media (max-width: $md)
      padding: 0
  .bg-img
    background: url("../assets/images/znizeny-urok/img-1.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 30px
    letter-spacing: -3px
    margin-left: -4px
    @media (max-width: $md)
      font-size: 30px
      line-height: 30px
      letter-spacing: -2px
  p
    font-weight: 500
    font-size: 18px
    max-width: 430px
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/call-centrum-bg.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/SMS_1.jpg") center/cover no-repeat
    @media (max-width: $md)
      background-position: top
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/klientska-zona.jpg") center/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px

  .services
    p
      font-size: 1rem
      font-weight: 400
    .icons
      .icon
        background: rgba(255, 255, 255, 0.05)
        width: 48px
        height: 48px
        display: flex
        align-items: center
        justify-content: center

        @media (max-width: $md)
          flex: none

.temp-block
  background: var(--textBright)
  h2
    margin-bottom: 0
    @media (max-width: $md)
      margin-bottom: 30px
  p
    @media (max-width: $md)
      br
        display: none

  a
    &.with-arrow
      padding-left: 45px
      position: relative

      &:before
        content: ""
        width: 16px
        height: 8px
        background: url(../assets/images/procare/arrow-dark.svg) no-repeat
        background-size: contain
        position: absolute
        left: 15px
        top: 50%
        transform: translateY(-50%)

  .notes
    p
      max-width: 100%
      font-size: 14px
      line-height: 22px
      color: rgba(255, 255, 255, 0.50)
      margin-bottom: 0

      a
        text-decoration: underline
        color: rgba(255, 255, 255, 0.50)
  .img-1
    width: 100%
    height: 100%
    background: url("../assets/images/procare/procare-recepcia.jpg") no-repeat center
    background-position: center -70px
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

  .img-2
    width: 100%
    height: 100%
    background: url("../assets/images/znizeny-urok/img-2.jpg") no-repeat center
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

.procare-program
  background: var(--darkBlue)

</style>
