<template>
  <div class="rules-page px-3">
    <div class="container">
      <div class="row">
        <h1 class="mt-md-5 mb-4 mb-md-0 text-center w-100">Pravidlá spracúvania osobných údajov</h1>
        <div class="col-9 mx-auto py-md-5 px-4">
          <ol class="c1 lst-kix_list_7-0 start" start="1">
              <li class="c0 c5 li-bullet-0"><span class="c9 c8">&Uacute;vod</span></li>
          </ol>
          <p class="c0"><span class="c10">Ochrana Va&scaron;ich osobn&yacute;ch &uacute;dajov je pre n&aacute;s
                  d&ocirc;le&#382;it&aacute; a venujeme jej n&aacute;le&#382;it&uacute; pozornos&#357;. Preto sa
                  sna&#382;&iacute;me o&nbsp;zabezpe&#269;enie vysokej &uacute;rovne ochrany Va&scaron;ich osobn&yacute;ch
                  &uacute;dajov pri ich sprac&uacute;van&iacute;, ako aj o&nbsp;zachovanie transparentnosti ich
                  sprac&uacute;vania v&nbsp;s&uacute;lade s Nariaden&iacute;m Eur&oacute;pskeho parlamentu a&nbsp;Rady
                  (E&Uacute;) 2016/679 z&nbsp;27. apr&iacute;la 2016, o&nbsp;ochrane osobn&yacute;ch &uacute;dajov
                  ktor&yacute;m sa zru&scaron;uje smernica 95/45/ES (v&scaron;eobecn&eacute; nariadenie o&nbsp;ochrane
                  &uacute;dajov) (&#271;alej len &bdquo;</span><span class="c8">GDPR</span><span class="c3">&ldquo;).</span>
          </p>
          <p class="c0"><span class="c3">Ni&#382;&scaron;ie V&aacute;m poskytujeme inform&aacute;cie o&nbsp;tom, ak&eacute;
                  osobn&eacute; &uacute;daje o&nbsp;V&aacute;s sprac&uacute;vame, na ak&eacute; &uacute;&#269;ely a ak&eacute;
                  s&uacute; &#271;al&scaron;ie podmienky ich sprac&uacute;vania. Z&aacute;rove&#328; V&aacute;m poskytujeme
                  inform&aacute;cie o&nbsp;Va&scaron;ich pr&aacute;vach vypl&yacute;vaj&uacute;cich z&nbsp;ochrany
                  osobn&yacute;ch &uacute;dajov. </span></p>
          <p class="c0"><span class="c10">Na &uacute;&#269;ely GDPR je prev&aacute;dzkovate&#318;om, ur&#269;uj&uacute;cim
                  &uacute;&#269;el a&nbsp;rozsah sprac&uacute;vania osobn&yacute;ch &uacute;dajov, ktor&eacute; ste n&aacute;m
                  poskytli v&nbsp;s&uacute;vislosti s&nbsp;Va&scaron;&iacute;m z&aacute;ujmom o&nbsp;z&iacute;skanie
                  plnen&iacute;, v&nbsp;zmysle akcie s n&aacute;zvom </span><span class="c2">&bdquo;Odporu&#269;te projekt
                  Bory B&yacute;vanie&ldquo;</span><span class="c10">, spolo&#269;nost&iacute; </span><span class="c8">Bory
                  Home s. r. o.</span><span class="c10">, so s&iacute;dlom Digital Park II, Einsteinova 25, 851 01 Bratislava,
                  Slovensk&aacute; republika, I&#268;O: 48&nbsp;099 171, zap&iacute;sanej v&nbsp;Obchodnom registri
                  Mestsk&eacute;ho s&uacute;du Bratislava III, oddiel Sro, vlo&#382;ka &#269;. 103386/B; </span><span
                  class="c8">Bory Home II s.r.o.</span><span class="c10">, so s&iacute;dlom Digital Park II, Einsteinova 25,
                  851 01 Bratislava &ndash; mestsk&aacute; &#269;as&#357; Petr&#382;alka, Slovensk&aacute; republika,
                  I&#268;O: 51&nbsp;035 073, zap&iacute;sanej v&nbsp;Obchodnom registri Mestsk&eacute;ho s&uacute;du
                  Bratislava III, oddiel Sro, vlo&#382;ka &#269;. 122328/B; a </span><span class="c8">Bory Home III
                  s.r.o.</span><span class="c10">, so s&iacute;dlom Digital Park II, Einsteinova 25, 851 01 Bratislava &ndash;
                  mestsk&aacute; &#269;as&#357; Petr&#382;alka, Slovensk&aacute; republika, I&#268;O: 51&nbsp;719 011,
                  zap&iacute;sanej v&nbsp;Obchodnom registri Mestsk&eacute;ho s&uacute;du Bratislava III, oddiel Sro,
                  vlo&#382;ka &#269;. 134974/B (&#271;alej len </span><span
                  class="c8">&bdquo;Poskytovatelia&ldquo;</span><span class="c10">), spolo&#269;nos&#357; </span><span
                  class="c8">Penta Real Estate, s. r. o.</span><span class="c10">,</span><span class="c8">&nbsp;</span><span
                  class="c10">so s&iacute;dlom Digital Park II, Einsteinova 25, Bratislava 851 01, I&#268;O:
                  51&nbsp;284&nbsp;502, zap&iacute;san&aacute; v&nbsp;obchodnom registri Mestsk&eacute;ho s&uacute;du
                  Bratislava III, oddiel: Sro, vlo&#382;ka &#269;. 124856/B (&#271;alej len &bdquo;</span><span
                  class="c8">Prev&aacute;dzkovate&#318;</span><span class="c3">&ldquo;). Poskytovatelia, s&uacute; na
                  &uacute;&#269;ely GDPR, ka&#382;d&yacute; samostatne, sprostredkovate&#318;om, ktor&yacute; sprac&uacute;va
                  osobn&eacute; &uacute;daje v&nbsp;mene Prev&aacute;dzkovate&#318;a.</span></p>
          <p class="c0"><span class="c10">Ak m&aacute;te ak&eacute;ko&#318;vek ot&aacute;zky t&yacute;kaj&uacute;ce sa
                  sprac&uacute;vania osobn&yacute;ch &uacute;dajov, ktor&eacute; ste n&aacute;m poskytli a&nbsp;tie&#382;
                  v&nbsp;pr&iacute;pade, ak chcete uplatni&#357; niektor&eacute; z&nbsp;ni&#382;&scaron;ie uveden&yacute;ch
                  pr&aacute;v vypl&yacute;vaj&uacute;cich pre V&aacute;s z&nbsp;GDPR, m&ocirc;&#382;ete sa s Va&scaron;imi
                  p&iacute;somn&yacute;mi podaniami obr&aacute;ti&#357; na vy&scaron;&scaron;ie uveden&uacute; adresu
                  s&iacute;dla Prev&aacute;dzkovate&#318;a alebo v&nbsp;pr&iacute;pade elektronick&yacute;ch podan&iacute; na
                  e-mailov&uacute; adresu </span><span class="c10 c14"><a class="c12"
                      href="mailto:GDPR_PRE_SR@pentarealestate.com">GDPR_PRE_SR@pentarealestate.com</a></span><span
                  class="c3">.</span></p>
          <ol class="c1 lst-kix_list_7-0" start="2">
              <li class="c0 c5 li-bullet-0"><span class="c9 c8">Z&iacute;skavanie osobn&yacute;ch &uacute;dajov</span></li>
          </ol>
          <p class="c0"><span class="c10">Tieto pravidl&aacute; sa aplikuj&uacute; na sprac&uacute;vanie osobn&yacute;ch
                  &uacute;dajov, ktor&eacute; od V&aacute;s z&iacute;skavame priamo alebo nepriamo, prostredn&iacute;ctvom,
                  Vami poveren&yacute;ch/splnomocnen&yacute;ch os&ocirc;b &ndash; kupuj&uacute;cich bytov alebo
                  apartm&aacute;nov v&nbsp;realitn&yacute;ch projektoch Poskytovate&#318;ov, ktor&iacute; V&aacute;s
                  ozna&#269;ia ako osobu, v&nbsp;nadv&auml;znosti na odpor&uacute;&#269;anie, ktorej ku k&uacute;pe
                  bytu/apartm&aacute;nu v&nbsp;projekte prist&uacute;pili, a to v&nbsp;nadv&auml;znosti na V&aacute;&scaron;
                  z&aacute;ujem&nbsp;o&nbsp;z&iacute;skanie plnen&iacute;, v&nbsp;zmysle podmienok akcie Poskytovate&#318;ov
                  s&nbsp;n&aacute;zvom </span><span class="c2">&bdquo;Odporu&#269;te projekt Bory
                  B&yacute;vanie&ldquo;</span><span class="c3">.</span></p>
          <p class="c0"><span class="c3">Z&iacute;skanie Va&scaron;ich osobn&yacute;ch &uacute;dajov je nevyhnutn&eacute; na
                  &uacute;&#269;ely &scaron;pecifikovan&eacute; v&nbsp;&#269;l&aacute;nku 4 t&yacute;chto pravidiel.</span>
          </p>
          <ol class="c1 lst-kix_list_7-0" start="3">
              <li class="c0 c5 li-bullet-0"><span class="c9 c8">Osobn&eacute; &uacute;daje, ktor&eacute; o&nbsp;V&aacute;s
                      sprac&uacute;vame</span></li>
          </ol>
          <p class="c0"><span class="c3">Z&iacute;skan&eacute; osobn&eacute; &uacute;daje &nbsp;sprac&uacute;vame
                  v&nbsp;s&uacute;lade so z&aacute;sadou minimaliz&aacute;cie sprac&uacute;vania osobn&yacute;ch &uacute;dajov
                  upravenou GDPR a&nbsp;preto ich sprac&uacute;vame len v&nbsp;nevyhnutnej miere a v&nbsp;rozsahu &#271;alej
                  vymedzenom v&nbsp;tomto &#269;l&aacute;nku. </span></p>
          <p class="c0"><span class="c10">Na &uacute;&#269;ely &scaron;pecifikovan&eacute; v&nbsp;&#269;l&aacute;nku 4
                  t&yacute;chto pravidiel sprac&uacute;vame Va&scaron;e osobn&eacute; &uacute;daje v&nbsp;rozsahu: titul; meno
                  a&nbsp;priezvisko; e-mail; telef&oacute;nne &#269;&iacute;slo; IBAN (&#269;&iacute;slo v&aacute;&scaron;ho
                  &uacute;&#269;tu) ako aj &uacute;daje o&nbsp;stave plnenia jednotliv&yacute;ch podmienok akcie
                  &bdquo;</span><span class="c2">Odporu&#269;te projekt Bory B&yacute;vanie&ldquo; </span><span
                  class="c10">a&nbsp;&uacute;daje o odovzdan&iacute;/poskytnut&iacute; odmeny (bonusu) a&nbsp;jej
                  v&yacute;&scaron;ky</span><span class="c2">, </span><span class="c3">zo strany
                  pr&iacute;slu&scaron;n&eacute;ho Poskytovate&#318;a </span></p>
          <p class="c0"><span class="c3">Je ve&#318;mi d&ocirc;le&#382;it&eacute;, aby osobn&eacute; &uacute;daje,
                  ktor&eacute; o&nbsp;V&aacute;s sprac&uacute;vame boli &uacute;pln&eacute;, pravdiv&eacute;
                  a&nbsp;aktu&aacute;lne. Informujte n&aacute;s, ak sa Va&scaron;e osobn&eacute; &uacute;daje po&#269;as
                  trvania sprac&uacute;vania zmenia.</span></p>
          <ol class="c1 lst-kix_list_7-0" start="4">
              <li class="c0 c5 li-bullet-0"><span class="c8 c9">&Uacute;&#269;ely a pr&aacute;vny z&aacute;klad
                      sprac&uacute;vania Va&scaron;ich osobn&yacute;ch &uacute;dajov</span></li>
          </ol>
          <p class="c0"><span class="c10">Va&scaron;e osobn&eacute; &uacute;daje sprac&uacute;vame na &uacute;&#269;el plnenia
                  povinnost&iacute; vypl&yacute;vaj&uacute;cich z&nbsp;podmienok akcie Poskytovate&#318;ov
                  s&nbsp;n&aacute;zvom </span><span class="c2">&bdquo;Odporu&#269;te projekt Bory
                  B&yacute;vanie&ldquo;</span><span class="c3">, a&nbsp;to predov&scaron;etk&yacute;m za &uacute;&#269;elom
                  overenia pravdivosti Va&scaron;ich &uacute;dajov, z&aacute;ujmu o&nbsp;splnenie podmienok/z&iacute;skanie
                  plnenia, n&aacute;sledn&eacute;ho pouk&aacute;zania plnenia, v&nbsp;pr&iacute;pade
                  &uacute;spe&scaron;n&eacute;ho splnenia podmienok akcie a&nbsp;evidencie (preuk&aacute;zania) riadneho
                  odovzdania pr&iacute;slu&scaron;n&yacute;ch plnen&iacute; zo strany Poskytovate&#318;ov a&nbsp;ich rozsahu.
                  &nbsp; </span></p>
          <p class="c0"><span class="c3">Pr&aacute;vnym z&aacute;kladom pre sprac&uacute;vanie Va&scaron;ich osobn&yacute;ch
                  &uacute;dajov na &uacute;&#269;el &scaron;pecifikovan&yacute; v&nbsp;tomto &#269;l&aacute;nku 4 je plnenie
                  zmluvy pod&#318;a &#269;l. 6 ods. 1, p&iacute;sm. b) GDPR.</span></p>
          <ol class="c1 lst-kix_list_7-0" start="5">
              <li class="c0 c5 li-bullet-0"><span class="c9 c8">Doba sprac&uacute;vania Va&scaron;ich osobn&yacute;ch
                      &uacute;dajov</span></li>
          </ol>
          <p class="c0"><span class="c10">Va&scaron;e osobn&eacute; &uacute;daje uchov&aacute;vame a&nbsp;inak
                  sprac&uacute;vame &nbsp;tak dlho, ako je to potrebn&eacute; na splnenie &uacute;&#269;elov, na ktor&eacute;
                  sme ich z&iacute;skali, t. j. dobou sprac&uacute;vania je doba trvania akcie Poskytovate&#318;ov
                  s&nbsp;n&aacute;zvom </span><span class="c2">&bdquo;Odporu&#269;te projekt Bory
                  B&yacute;vanie&ldquo;</span><span class="c3">&nbsp;a z&nbsp;nej vypl&yacute;vaj&uacute;cich pr&aacute;v a
                  povinnost&iacute;. </span></p>
          <p class="c0"><span class="c3">Plynutie doby sprac&uacute;vania osobn&yacute;ch &uacute;dajov pravidelne
                  kontrolujeme a&nbsp;v&nbsp;pr&iacute;pade ak uplynie, resp. sprac&uacute;vanie Va&scaron;ich osobn&yacute;ch
                  &uacute;dajov u&#382; nie je nevyhnutn&eacute;, sprac&uacute;vanie ukon&#269;&iacute;me a&nbsp;osobn&eacute;
                  &uacute;daje vyma&#382;eme.</span></p>
          <ol class="c1 lst-kix_list_7-0" start="6">
              <li class="c0 c5 li-bullet-0"><span class="c9 c8">Zdie&#318;anie Va&scaron;ich osobn&yacute;ch &uacute;dajov
                      s&nbsp;tret&iacute;mi stranami</span></li>
          </ol>
          <p class="c0"><span class="c10">V&nbsp;s&uacute;lade s&nbsp;&uacute;&#269;elom spracovania &scaron;pecifikovanom
                  v&nbsp;t&yacute;chto pravidl&aacute;ch sa realizuje sprac&uacute;vanie Va&scaron;ich osobn&yacute;ch
                  &uacute;dajov aj prostredn&iacute;ctvom tret&iacute;ch os&ocirc;b, ktor&eacute; s&uacute;
                  sprostredkovate&#318;mi. S&uacute; nimi najm&auml; spolo&#269;nos&#357; Poskytovatelia,
                  prostredn&iacute;ctvom ktor&yacute;ch Prev&aacute;dzkovate&#318; realizuje development, v&yacute;stavbu
                  a&nbsp;predaj nehnute&#318;nost&iacute; v&nbsp;r&aacute;mci Projektu, spolo&#269;nos&#357; Penta Investments
                  s.r.o., advok&aacute;tska kancel&aacute;ria poskytuj&uacute;ca pr&aacute;vne slu&#382;by pri v&yacute;stavbe
                  a&nbsp;predaji nehnute&#318;nost&iacute; a&nbsp;v&nbsp;s&uacute;vislosti s&nbsp;akciou Poskytovate&#318;ov
                  s&nbsp;n&aacute;zvom </span><span class="c2">&bdquo;Odporu&#269;te projekt Bory
                  B&yacute;vanie&ldquo;</span><span class="c3">&nbsp;a poskytovate&#318; softwarov&eacute;ho syst&eacute;mu,
                  v&nbsp;r&aacute;mci ktor&eacute;ho s&uacute; sprac&uacute;van&eacute; Va&scaron;e osobn&eacute;
                  &uacute;daje. Prev&aacute;dzkovate&#318; uzatvoril so sprostredkovate&#318;mi zmluvy
                  o&nbsp;sprac&uacute;van&iacute; osobn&yacute;ch &uacute;dajov. Sprostredkovatelia s&uacute; zaviazan&iacute;
                  sprac&uacute;va&#357; Va&scaron;e osobn&eacute; &uacute;daje v&yacute;lu&#269;ne na &uacute;&#269;ely, ako
                  s&uacute; pop&iacute;san&eacute; v&nbsp;t&yacute;chto pravidl&aacute;ch. </span></p>
          <p class="c0"><span class="c3">Va&scaron;e d&aacute;ta v&scaron;ak nie s&uacute; spr&iacute;stupnen&eacute;
                  tret&iacute;m stran&aacute;m na &uacute;&#269;ely ich nez&aacute;visl&eacute;ho vyu&#382;itia alebo
                  spracovania nad r&aacute;mec inform&aacute;ci&iacute; poskytnut&yacute;ch v&nbsp;t&yacute;chto
                  pravidl&aacute;ch.</span></p>
          <ol class="c1 lst-kix_list_7-0" start="7">
              <li class="c0 c5 li-bullet-0"><span class="c9 c8">Medzin&aacute;rodn&yacute; prenos Va&scaron;ich
                      osobn&yacute;ch &uacute;dajov</span></li>
          </ol>
          <p class="c0"><span class="c3">Softwarov&yacute; syst&eacute;m, v&nbsp;ktorom s&uacute; Va&scaron;e osobn&eacute;
                  &uacute;daje sprac&uacute;van&eacute; na z&aacute;klade licen&#269;nej zmluvy medzi
                  Prev&aacute;dzkovate&#318;om a&nbsp;poskytovate&#318;om softwarov&eacute;ho syst&eacute;mu, je
                  ulo&#382;en&yacute; na serveroch poskytovate&#318;a tohto syst&eacute;mu so s&iacute;dlom v Spojenom
                  kr&aacute;&#318;ovstve Ve&#318;kej Brit&aacute;nie a&nbsp;Severn&eacute;ho &Iacute;rska.</span></p>
          <ol class="c1 lst-kix_list_7-0" start="8">
              <li class="c0 c20"><span class="c9 c8">Va&scaron;e pr&aacute;va s&uacute;visiace so sprac&uacute;van&iacute;m
                      Va&scaron;ich osobn&yacute;ch &uacute;dajov</span></li>
          </ol>
          <p class="c0"><span class="c3">GDPR V&aacute;m prizn&aacute;va zna&#269;n&yacute; rozsah pr&aacute;v, ktor&eacute;
                  re&scaron;pektujeme a&nbsp;umo&#382;n&iacute;me V&aacute;m uplat&#328;ova&#357; ich v&nbsp;plnom rozsahu.
                  V&nbsp;r&aacute;mci uplatnenia Va&scaron;ich pr&aacute;v ste opr&aacute;vnen&iacute; najm&auml;:</span></p>
          <ul class="c1 lst-kix_list_4-0 start">
              <li class="c0 c6 li-bullet-1"><span class="c3">po&#382;iada&#357; n&aacute;s o&nbsp;k&oacute;piu osobn&yacute;ch
                      &uacute;dajov, ktor&eacute; o&nbsp;V&aacute;s vedieme, a&nbsp;opis toho ako tieto osobn&eacute;
                      &uacute;daje pou&#382;&iacute;vame;</span></li>
              <li class="c4 li-bullet-1"><span class="c3">po&#382;iada&#357; o&nbsp;n&aacute;pravu, resp. opravu
                      osobn&yacute;ch &uacute;dajov, ktor&eacute; o&nbsp;V&aacute;s vedieme, ak sa domnievate, &#382;e tieto
                      osobn&eacute; &uacute;daje m&ocirc;&#382;u by&#357; nepresn&eacute;, neaktu&aacute;lne,
                      nespr&aacute;vne;</span></li>
              <li class="c4 li-bullet-1"><span class="c3">po&#382;iada&#357; o&nbsp;poskytnutie Va&scaron;ich osobn&yacute;ch
                      &uacute;dajov, ktor&eacute; o&nbsp;V&aacute;s sprac&uacute;vame automatizovan&yacute;mi prostriedkami na
                      z&aacute;klade plnenia zmluvy alebo predzmluvn&yacute;ch rokovan&iacute;, tretej strane;</span></li>
              <li class="c4 li-bullet-1"><span class="c3">po&#382;iada&#357;, aby sme obmedzili pou&#382;&iacute;vanie
                      Va&scaron;ich osobn&yacute;ch &uacute;dajov alebo &uacute;&#269;el ich sprac&uacute;vania
                      v&nbsp;ur&#269;it&yacute;ch obmedzen&yacute;ch pr&iacute;padoch; </span></li>
              <li class="c4 li-bullet-1"><span class="c3">V&aacute;&scaron; s&uacute;hlas so sprac&uacute;van&iacute;m
                      Va&scaron;ich osobn&yacute;ch &uacute;dajov odvola&#357;;</span></li>
              <li class="c4 li-bullet-1"><span class="c3">po&#382;iada&#357; o&nbsp;v&yacute;maz osobn&yacute;ch
                      &uacute;dajov, ktor&eacute; o&nbsp;V&aacute;s sprac&uacute;vame v&nbsp;pr&iacute;pade ak Va&scaron;e
                      &uacute;daje sprac&uacute;vame na z&aacute;klade s&uacute;hlasu.</span></li>
          </ul>
          <p class="c0"><span class="c3">Na&scaron;im z&aacute;ujmom je zodpoveda&#357; ak&eacute;ko&#318;vek Va&scaron;e
                  ot&aacute;zky a&nbsp;vyrie&scaron;i&#357; ak&uacute;ko&#318;vek Va&scaron;u s&#357;a&#382;nos&#357;
                  s&uacute;visiacu so sprac&uacute;van&iacute;m osobn&yacute;ch &uacute;dajov. Ak sa v&scaron;ak domnievate,
                  &#382;e sme Va&scaron;u s&#357;a&#382;nos&#357; nevyrie&scaron;ili, m&aacute;te pr&aacute;vo
                  s&#357;a&#382;ova&#357; sa na &Uacute;rade na ochranu osobn&yacute;ch &uacute;dajov Slovenskej
                  republiky.</span></p>
          <p class="c11 c19"><span class="c3"></span></p>
          <p class="c11"><span class="c3">Tieto pravidl&aacute; sprac&uacute;vania osobn&yacute;ch &uacute;dajov
                  m&ocirc;&#382;u by&#357; jednostranne kedyko&#318;vek doplnen&eacute; alebo zmenen&eacute;.
                  O&nbsp;pr&iacute;padn&yacute;ch &nbsp;zmen&aacute;ch V&aacute;s budeme elektronicky alebo p&iacute;somne
                  informova&#357;.</span></p>
          <p class="c0"><span class="c3">V&nbsp;Bratislave, d&#328;a 15.6.2023.</span></p>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  name: "GDPROdporucteBory",
  components: {ContactForm},
}
</script>

<style lang="sass" scoped>
.rules-page
  padding-top: 85px
  background: #2D323A

  h1
    @media (max-width: 768px)
      font-size: 30px

  .rule
    margin-bottom: 40px

    h3
      font-size: 22px

  p
    font-size: 16px
    font-weight: 400

  ol
    margin-top: 50px
    padding-left: 0
    li
      font-weight: 600

  a
    color: #fff
    font-weight: 500
</style>