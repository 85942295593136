<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Na Boroch máte <br>o zdravie postarané</h1>
          <p>Vyberte si byt z našej ponuky a my vám pridáme BONUS, program osobnej starostlivosti v sieti polikliník ProCare na rok zadarmo.</p>
          <b-button @click="scrollTo('procare-info')" squared variant="primary">Viac informácií</b-button>
        </div>
        <div class="procare-badge">
          <img src="../assets/images/procare/procare.png" alt="">
        </div>
      </div>
    </div>
    <section class="temp-block px-3 px-md-0 pt-4 py-md-3" id="procare-info">
      <div class="d-flex flex-wrap px-0 pl-md-5 pr-md-3 justify-content-between">
        <div class="col-12 col-lg-4 mb-3 mb-lg-0 px-0 py-lg-5">
          <h2 class="mb-5">Získajte ProCare program na&nbsp;1&nbsp;rok zadarmo</h2>
          <p class="mb-5">Kúpte si akýkoľvek byt v štvrti Bory a my vám po&nbsp;podpise kúpnej zmluvy zaplatíme ročný program osobnej starostlivosti v sieti polikliník ProCare.</p>
          <b-button to="/byty" squared variant="primary">Pozrieť byty</b-button>
        </div>
        <div class="col-12 col-lg-7 px-0">
          <div class="img-1"></div>
        </div>
      </div>
    </section>
    <section class="procare-program py-5 px-3">
      <p class="title">Program osobnej starostlivosti ProCare sa odvíja od typu kupovaného bytu:</p>
      <div class="row mb-4">
        <div class="col-12 col-md-3">
          <div class="box" @click="showProcareModal('flat-1')">
            <div class="icon">
              <img src="../assets/images/procare/flat-1.svg" alt="">
            </div>
            <p>1 až 1,5 izbový byt</p>
            <p class="text-blue">program SMART*<br><br></p>
            <div class="link">Zobraziť viac</div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="box" @click="showProcareModal('flat-1 flat-3')">
            <div class="icon">
              <img src="../assets/images/procare/flat-2.svg" alt="">
            </div>
            <p>2-izbový byt</p>
            <p class="text-blue">program SMART*</p>
            <p class="text-blue">program Exclusive**</p>
            <div class="link">Zobraziť viac</div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="box" @click="showProcareModal('flat-2')">
            <div class="icon">
              <img src="../assets/images/procare/flat-3.svg" alt="">
            </div>
            <p>3-izbový byt</p>
            <p class="text-blue">program Family SMART<br><br></p>
            <div class="link">Zobraziť viac</div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="box" @click="showProcareModal('flat-4')">
            <div class="icon">
              <img src="../assets/images/procare/flat-4.svg" alt="">
            </div>
            <p>4-izbový byt</p>
            <p class="text-blue">program Family Exclusive<br><br></p>
            <div class="link">Zobraziť viac</div>
          </div>
        </div>
      </div>
      <div class="notes">
        <p>*v prípade dvoch kupujúcich, kt. budú uvedení v kúpne zmluve, bude príslušný program ProCare SMART aktivovaný obom kupujúcim</p>
        <p>**v prípade jedného kupujúceho, kt. bude uvedený v kúpnej zmluve, bude kupujúcemu aktivovaný program ProCare Exclusive</p>
      </div>
    </section>
    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 col-p0 px-md-5 py-3">
            <h2>Všetko, čo potrebujete, máte na dosah</h2>
            <p>Bývajte na Boroch so všetkým, čo pre život potrebujete. Nielen s plnou občianskou vybavenosťou, ale aj so špičkovou zdravotnou starostlivosťou, ktorú vám poskytne tím profesionálnych odborníkov pre rôzne medicínske špecializácie.</p>
          </div>
        </div>
        <div class="row px-md-2 mb-5">
          <div class="col-md-4">
            <div class="col-md-12 p-md-5 bg-col bg-col-1">
              <h3>Privátne call centrum<br> a poradenstvo</h3>
              <p>K dispozícii máte tiež profesionálny servis s privátnym call centrom pre poradenstvo s možnosťou objednania na akékoľvek vyšetrenie.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>SMS / e-mailové notifikácie <br>vždy na dosach</h3>
              <p>Spolu so službou Dvojitá notifikácia už nemusíte myslieť na termíny. Táto služba vám bude k&nbsp;dispozícii s&nbsp;ktorýmkoľvek programom.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>Komfortná online <br>klientska zóna</h3>
              <p>Nepotrebujete žiadne papiere. Online klientska zóna je vám k&nbsp;dispozícii 24/7, vďaka čomu si môžete kedykoľvek naplánovať vyšetrenie, požiadať o&nbsp;e&#8209;recept, telefonickú konzultáciu či nahliadnuť do svojich záznamov vyšetrení či lekárskych správ.</p>
            </div>
          </div>
        </div>
        <div class="services pb-4">
          <p class="mb-4 pl-4">Ďalšie výhody programov osobnej starostlivosti Procare</p>
          <div class="icons px-4">
            <div class="row">
              <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
                <div class="icon mr-3">
                  <img src="../assets/images/procare/heart-hand.svg" alt="">
                </div>
                <div class="text">Benefitové balíky (programy Exclusive)</div>
              </div>
              <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
                <div class="icon mr-3">
                  <img src="../assets/images/procare/medical-cross.svg" alt="">
                </div>
                <div class="text">Zvýhodnené komplexné prehliadky</div>
              </div>
              <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
                <div class="icon mr-3">
                  <img src="../assets/images/procare/phone.svg" alt="">
                </div>
                <div class="text">Nonstop odborník na telefóne</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="temp-block px-3 px-md-0 py-3 pb-5">
      <div class="d-flex flex-wrap px-0 pl-md-5 pr-md-3 mb-4 justify-content-between">
        <div class="col-12 col-lg-4 mb-4 mb-lg-0 px-0 py-lg-5">
          <div class="procare-logo mb-3">
            <img src="../assets/images/procare/procare-logo.svg" alt="">
          </div>
          <h2 class="mb-md-5 mb-4 pr-md-5">Za vyšetrením nemusíte dochádzať ďaleko</h2>
          <p class="mb-5">Najbližšiu polikliniku siete ProCare nájdete v nákupno-zábavnom centre Bory Mall, ktorý máte len pár minút od vášho vysnívaného bývania. </p>
          <a href="https://kz.procare.sk/ponuka-programov" class="btn btn-primary rounded-0 with-arrow" target="_blank">Zobraziť benefity programov</a>
        </div>
        <div class="col-12 col-lg-7 px-0">
          <div class="img-2"></div>
        </div>
      </div>
      <div class="notes pl-md-5">
        <p>Kompletné podmienky akcie nájdete v <a href="./docs/podmienky-akcie-procare.pdf" target="_blank">Podmienky akcie BONUS Program osobnej starostlivosti ProCare</a></p>
      </div>
    </section>

    <section class="faq">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <h3>Najčastejšie otázky</h3>
          </div>
          <div class="col-12 col-md-8">
            <div class="accordion mb-4 mb-md-5" role="tablist">
                <div class="question-wrapper">
                  <div v-b-toggle.accordion-1 class="question">Odkedy budem mať program osobnej starostlivosti v sieti ProCare aktívny?</div>
                  <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" class="answer">
                    <div>Ak pri podpise kúpnej zmluvy vyjadríte záujem o bonus – program osobnej starostlivosti ProCare na rok zadarmo – môžete priamo na mieste vyplniť a podpísať registračný formulár do siete ProCare, v ktorom si sami vyberiete dátum, od ktorého si želáte mať program osobnej starostlivosti ProCare aktivovaný.<br><br>Tento formulár odošleme do siete ProCare za vás.<br><br>Následne je potrebné navštíviť ľubovoľnú zo siete poliklinik ProCare a podpísať Zmluvu o poskytovaní služieb, v ktorej je uvedený vami určený dátum aktivácie programu.<br><br>Po podpise tejto zmluvy vám začne, od vami uvedeného dátumu aktivácie, plynúť 1-ročná lehota programu osobnej starostlivosti zadarmo.</div>
                  </b-collapse>
                </div>
                <div class="question-wrapper">
                  <div v-b-toggle.accordion-2 class="question">Byt kupujeme dvaja, dostane program osobnej starostlivosti každý z&nbsp;nás?</div>
                  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="answer">
                    <div>Typ bonusového programu osobnej starostlivosti sa odvíja od typu kupovaného bytu (viď vyššie na stránke). Ak kupujete 1 alebo 2-izbový byt dvaja, obom sa aktivuje príslušný program.</div>
                  </b-collapse>
                </div>
                <div class="question-wrapper">
                  <div v-b-toggle.accordion-3 class="question">Čo ak pri kúpe už program osobnej starostlivosti v&nbsp;sieti ProCare mám?</div>
                  <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="answer">
                    <div>V prípade, ak pri podpise kúpnej zmluvy program osobnej starostlivosti&nbsp;ProCare už máte, automaticky sa vám jeho platnosť predlžuje na ďalšie obdobie 1 roka zadarmo.</div>
                  </b-collapse>
                </div>
                <div class="question-wrapper">
                  <div v-b-toggle.accordion-4 class="question">Ako dlho bude program osobnej starostlivosti v&nbsp;sieti ProCare aktívny?</div>
                  <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="answer">
                    <div>Program je aktívny 12 mesiacov odo dňa aktivácie. Dátum aktivácie si sami určíte pri vyplnení a podpise registračného formulára. Registračný formulár môžete vyplniť a podpísať priamo s predajcom pri podpise kúpnej zmluvy.<br>(Príklad: Ak si dátum aktivácie stanovíte napr. na 15. 10. 2023, jeho platnosť bude trvať do 14. 10. 2024)</div>
                  </b-collapse>
                </div>
                <div class="question-wrapper">
                  <div v-b-toggle.accordion-5 class="question">Ako sa po roku dozviem o&nbsp;vypršaní programu osobnej starostlivosti?</div>
                  <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="answer">
                    <div>Sieť ProCare informuje každého klienta v&nbsp;dostatočnom časovom predstihu o&nbsp;blížiacom sa termíne, v&nbsp;ktorom platnosť programu osobnej starostlivosti vyprší. Následne je možné si program predĺžiť na ďalšie obdobie. Ak si program nepredĺžite, jeho platnosť vyprší automaticky.</div>
                  </b-collapse>
                </div>
                <div class="question-wrapper">
                  <div v-b-toggle.accordion-6 class="question">Ako budú v&nbsp;ostatných poliklinikach vedieť, že mám aktivovaný program osobnej starostlivosti?</div>
                  <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="answer">
                    <div>Po tom, ako podpíšete Zmluvu o poskytovaní služieb pri prvej návšteve ľubovoľnej polikliniky v sieti ProCare, môžete naplno využívať benefity daného programu. Môžete sa zaregistrovať v&nbsp;online klientskej zóne alebo kedykoľvek zavolať na call centrum a&nbsp;objednať sa na ľubovoľné vyšetrenie.<br>Navyše vám pri návšteve polikliniky, priamo na recepcii &nbsp;vydajú aj fyzickú klientsku kartu, ktorou sa môžete preukazovať pri každej ďalšej návšteve v&nbsp;ktorejkoľvek z&nbsp;polikliník v sieti ProCare.</div>
                  </b-collapse>
                </div>
            </div>
            <div class="row">
              <div class="d-flex text px-3">
                <p>V prípade akýchkoľvek otázok ohľadne poskytnutia bonusu nás neváhajte kontaktovať na <a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></p>
                <b-button to="/kontakt" squared variant="primary">Ďalšie kontakty</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal id="procare-modal" ref="procare-modal" hide-footer hide-header centered>
      <div class="d-block text-center">
        <h3>Porovnanie balíčkov<br> osobnej starostlivosti</h3>
        <div class="close-modal" @click="$bvModal.hide('procare-modal')">Zatvoriť <img src="../assets/icons/close-gallery.svg"></div>
        <div class="table-responsive">
          <table class="table" :class="modalClass">
            <thead class="border-light">
              <tr>
                <th scope="col"></th>
                <th scope="col"><strong>SMART</strong></th>
                <th scope="col"><strong>Family SMART</strong></th>
                <th scope="col"><strong>Exclusive</strong></th>
                <th scope="col"><strong>Family Exclusive</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="text-left">Dĺžka programu</th>
                <td class="text-blue">12 mesiacov</td>
                <td class="text-blue">12 mesiacov</td>
                <td class="text-blue">12 mesiacov</td>
                <td class="text-blue">12 mesiacov</td>
              </tr>
              <tr>
                <th scope="row">Osobná recepčná/ý</th>
                <td>-</td>
                <td>-</td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th scope="row">Dva benefitové balíky</th>
                <td>-</td>
                <td>-</td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th scope="row">Priorita dovolateľnosti na call centrum</th>
                <td>-</td>
                <td>-</td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th scope="row">Obsahuje 4 programy pre členov rodiny</th>
                <td>-</td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <th scope="row">Ďalšie deti do 18 rokov s cenovým zvýhodnením</th>
                <td>-</td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <th scope="row">Neobmedzený počet programov pre vlastné deti bez ohľadu na vek</th>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>Komplexný manažment klienta</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>Služby klientskeho centra</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>Služby privátneho call centra</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>Osobná klientska zóna</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>Online klientska zóna</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>SMS / e-mail notifikácie</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>Zvýhodnená cena komplexných prehliadok</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
              <tr>
                <th>50 % zľava na cenu spoplatnených potvrdení</th>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
                <td><img src="../assets/images/procare/circle-check.svg"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>

    <NewsSlider></NewsSlider>

    <ContactForm></ContactForm>

  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/ContactForm";
import NewsSlider from "@/components/NewsSlider";

export default {
  name: 'Procare',
  components: {
    ContactForm,
    NewsSlider
  },

  data() {
    return {
      procareModal: false,
      modalClass: '',
      gallerySlick: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        arrows: false
      },
    }
  },

  methods: {
    scrollTo(id) {
      const map = document.getElementById(id)
      map.scrollIntoView({behavior: "smooth", block: 'center'})

    },
    showProcareModal(className) {
      this.$refs['procare-modal'].show()
      this.modalClass = className
    },
    scrollToContact() {
      const contactForm = document.getElementById('contact-form')
      if (contactForm) {
        contactForm.scrollIntoView({behavior: "smooth", block: 'center'})
      } else {
        this.$router.push({ path: 'kontakt#contact-form' })
      }

    },
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>
$md: 768px


.faq
  background: var(--textBright)
  padding: 70px 0

  .text
    align-items: center
    justify-content: space-between
    width: 100%
    @media (max-width: $md)
      flex-direction: column
    p
     max-width: 480px
     font-size: 16px
     line-height: 24px
     margin-bottom: 0
     @media (max-width: $md)
      margin-bottom: 15px

     a
      color: var(--blue)

  @media (max-width: $md)
    padding: 40px 0

  h3
    font-weight: 300
    font-size: 55px
    line-height: 60px

    @media (max-width: $md)
      font-size: 34px
      line-height: 38px
      margin-bottom: 20px

  .question-wrapper
    border-top: 1px solid rgba(255, 255, 255, 0.20)
    padding: 25px 0

    &:last-child
      border-bottom: 1px solid rgba(255, 255, 255, 0.20)

    @media (max-width: $md)
      padding: 10px 0

    .question
      padding: 15px 0
      font-size: 20px
      font-weight: 700
      line-height: 40px
      padding-left: 50px
      position: relative

      @media (max-width: $md)
        line-height: 24px

      &.not-collapsed
        &:before
          background-image: url(../assets/images/procare/minus.svg)

      &:before
        content: ""
        width: 32px
        height: 32px
        background-color: rgba(255, 255, 255, 0.05)
        background-image: url(../assets/images/procare/plus.svg)
        background-repeat: no-repeat
        background-position: center
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)

    .answer
      font-size: 16px
      font-weight: 400
      line-height: 24px
      padding-left: 50px

.procare-program
  .title
    font-size: 24px
    font-weight: 400
    letter-spacing: -0.24px
    margin-bottom: 30px
    padding-left: 30px
    line-height: 28px

    @media (max-width: $md)
      padding-left: 0
      font-size: 20px
  .box
    border: 1px solid rgba(255, 255, 255, 0.20)
    padding: 56px 40px 70px
    text-align: center
    position: relative
    cursor: pointer

    @media (max-width: $md)
      padding: 20px 20px 40px
      margin-bottom: 15px

    &:hover
      border-color: var(--blue)
      .link
        color: var(--blue)

    .link
      position: absolute
      right: 15px
      bottom: 15px
      font-size: 14px

    .icon
      height: 100px
      display: flex
      align-items: center
      justify-content: center
      margin-bottom: 40px

    p
      font-size: 18px
      font-weight: 500
      margin-bottom: 40px

    .text-blue
      color: var(--blue)
      font-weight: 700
      font-size: 20px
      line-height: 24px
      margin-bottom: 0

  .notes
    padding-left: 30px
    
    @media (max-width: $md)
      padding-left: 0
    p
      margin-bottom: 0
      color: rgba(255, 255, 255, 0.50)
      font-size: 14px


.home
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/images/procare_banner.jpg") center/cover no-repeat
  position: relative
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url("../assets/images/Novy_Dvor_zima.jpg") center/cover no-repeat

  .procare-badge
    position: absolute
    left: 65%
    top: 20%

    img
      max-width: 266px

      @media (max-width: $md)
        max-width: 100%

    @media (max-width: 1200px)
      top: 80px
      left: 50%
      transform: translate(-50%, 0)

    @media (max-width: $md)
      transform: translate(-50%, 0)
      top: 80px
      width: 140px
      height: 140px
  .intro-text
    p
      max-width: 640px
      margin-bottom: 30px

  #fixed-info
    position: absolute
    bottom: 0
    right: 0
    z-index: 999999
    background: var(--blue)

    @media (max-width: $md)
      display: none

    .box
      position: relative
      padding: 45px 25px 25px
      max-width: 460px
      .big
        font-size: 84px
        font-weight: 800
        position: absolute
        top: -66px
        left: 25px

      .border-bottom-white
        padding-bottom: 20px
        border-bottom: 1px solid #fff
        margin-bottom: 20px
        font-size: 28px
        line-height: 28px
        font-weight: normal

      .text-dark
        margin-bottom: 0
        font-size: 16px
        font-weight: 400

        a
          font-weight: 600
          color: #343a40
          text-decoration: underline
          
          &::after
            content: ""
            width: 100%
            height: 100%
            position: absolute
            left: 0
            bottom: 0
            cursor: pointer
.interactive-map
  //height: 92vh
  position: relative
  .overlay
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url("../assets/images/mapoverlay2.png") no-repeat
    background-size: cover
.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .col-p0
    @media (max-width: $md)
      padding: 0
  .bg-img
    background: url("../assets/images/procare/Main_banner.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 30px
    letter-spacing: -3px
    margin-left: -4px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    max-width: 525px
    font-weight: 500
    font-size: 18px
    line-height: 150%
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 30px
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/call-centrum-bg.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/SMS_1.jpg") center/cover no-repeat
    @media (max-width: $md)
      background-position: top
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/procare/klientska-zona.jpg") center/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px

  .services
    p
      font-size: 1rem
      font-weight: 400
    .icons
      .icon
        background: rgba(255, 255, 255, 0.05)
        width: 48px
        height: 48px
        display: flex
        align-items: center
        justify-content: center

        @media (max-width: $md)
          flex: none

.temp-block
  background: var(--darkBlue)
  h2
    margin-bottom: 0
    @media (max-width: $md)
      margin-bottom: 30px
  p
    @media (max-width: $md)
      br
        display: none

  a
    &.with-arrow
      padding-left: 45px
      position: relative

      &:before
        content: ""
        width: 16px
        height: 8px
        background: url(../assets/images/procare/arrow-dark.svg) no-repeat
        background-size: contain
        position: absolute
        left: 15px
        top: 50%
        transform: translateY(-50%)

  .notes
    p
      max-width: 100%
      font-size: 14px
      line-height: 22px
      color: rgba(255, 255, 255, 0.50)
      margin-bottom: 0

      a
        text-decoration: underline
        color: rgba(255, 255, 255, 0.50)
  .img-1
    width: 100%
    height: 100%
    background: url("../assets/images/procare/procare-recepcia.jpg") no-repeat center
    background-position: center -70px
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

  .img-2
    width: 100%
    height: 100%
    background: url("../assets/images/procare/Za_vysetrenim_nie_daleko.jpg") no-repeat center
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px

.procare-program
  background: var(--darkBlue)

</style>
