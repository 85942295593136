<template>
  <section class="contact p-3" id="contact-form" tabindex="0" @focus="handleFocus">
    <div class="row">
      <div class="col-md-7">
        <div class="image-bg h-100"></div>
      </div>
      <div class="col-md-5 p-4 pr-md-5 pb-5 pt-3">
        <p class="w-fw-500">Kontaktný formulár</p>
        <h2 class="w-fw-300">Chcem vedieť viac</h2>
        <p class="m-0">Zaujala vás ponuka bytov alebo sa chcete poradiť?</p>
        <p class="m-0">Nechajte nám na seba kontakt a čoskoro sa vám ozveme.</p>
        <b-form class="mt-4" @submit="sendForm" novalidate @submit.stop.prevent="sendForm">
          <div class="d-flex name-wrapper">
            <b-form-input v-model="v$.contactForm.name.$model" placeholder="Meno" class="rounded-0" :state="validateState('name')" ref="inputName" type="text"></b-form-input>
            <b-form-input v-model="v$.contactForm.surname.$model" placeholder="Priezvisko" class="rounded-0" :state="validateState('surname')"></b-form-input>
          </div>
          <b-form-input type="email" v-model="v$.contactForm.email.$model" placeholder="E-mail" class="rounded-0 my-2" :state="validateState('email')"></b-form-input>
          <VuePhoneNumberInput 
            v-model="vuePhone.phone" 
            size="lg" 
            v-bind="vuePhone.props"
            @update="onUpdate"
          />
          <div class="custom-alert-box alert mt-3 alert-danger" v-if="v$.$errors.length || vuePhone.phone && !vuePhone.isValid">
            <div v-for="error of v$.$errors" :key="error.$uid">
              <p v-if="error.$property == 'name' && error.$validator == 'minLength'">Meno - minimálny počet znakov: 3</p>
              <p v-if="error.$property == 'name' && error.$validator == 'onlyString'">Meno - obsahuje nepovolené znaky</p>
              <p v-if="error.$property == 'name' && error.$validator == 'required'">Meno - povinné pole</p>
              <p v-if="error.$property == 'surname' && error.$validator == 'minLength'">Priezvisko - minimálny počet znakov: 3</p>
              <p v-if="error.$property == 'surname' && error.$validator == 'onlyString'">Priezvisko - obsahuje nepovolené znaky</p>
              <p v-if="error.$property == 'surname' && error.$validator == 'required'">Priezvisko - povinné pole</p>
              <p v-if="error.$property == 'email' && error.$validator == 'email'">Email - nesprávny formát</p>
              <p v-if="error.$property == 'email' && error.$validator == 'required'">Email - povinné pole</p>
            </div>
            <p v-if="vuePhone.phone && !vuePhone.isValid">Telefónne číslo je neplatné</p>
          </div>
          <b-form-group class="mb-4" v-if="!internalId">
            <p class="mt-3">Vyberte, v ktorej etape máte záujem o byt</p>
            <div class="position-relative">
              <div class="dropdown d-flex justify-content-between" @click="showDropdown()">
                <div class="d-flex">
                  <div v-if="!contactForm.interests.length">Vybrať etapu</div>
                  <div v-if="contactForm.interests.includes('bory2b')">Bory Nový Dvor &nbsp;</div>
                  <div v-if="contactForm.interests.includes('bory3')">Bory Promenáda &nbsp;</div>
                  <div v-if="contactForm.interests.includes('bory4')">Bory Na Hrádzi</div>
                </div>
                <div>
                  <svg data-v-46e105de="" mlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="country-selector__toggle__arrow"><path data-v-46e105de="" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" class="arrow"></path><path data-v-46e105de="" fill="none" d="M0 0h24v24H0V0z"></path></svg>
                </div>
              </div>
              <b-form-checkbox-group name="radio-sub-component" class="dropdown-content" :class="{active: dropdownActive}">
                <b-form-checkbox v-model="contactForm.interests" value="bory2b" size="lg" class="check-label">Bory Nový Dvor</b-form-checkbox>
                <b-form-checkbox v-model="contactForm.interests" value="bory3" size="lg" class="check-label">Bory Promenáda</b-form-checkbox>
                <b-form-checkbox v-model="contactForm.interests" value="bory4" size="lg" class="check-label">Bory Na Hrádzi</b-form-checkbox>
              </b-form-checkbox-group>
              <b-alert v-if="this.v$.contactForm['interests'].$error" class="mt-3" show variant="danger">Vyberte etapu/ etapy, o ktoré máte záujem.</b-alert>
            </div>
          </b-form-group>
          <b-form-checkbox
              id="terms"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              v-model="contactForm.marketing_consent"
              size="lg"
              class="mb-3 small-font terms-check"
              required
          >
            <p class="text-small m-0">Súhlasím so zasielaním marketingových informácií vrátane reklamy elektronickou poštou alebo prostredníctvom SMS, aby mi neunikla žiadna novinka.</p>
          </b-form-checkbox>
          <div class="mb-4">
            <a class="mr-3 d-block d-md-inline" v-b-modal.agreements>Spracovanie osobných údajov</a>
            <a class="mr-3" v-b-modal.cookies>Cookies</a>
          </div>
          <div class="recaptcha" :class="{active: recaptchaActive}">
            <vue-recaptcha ref="recaptcha" @verify="onVerify" language="sk" :loadRecaptchaScript="true" sitekey="6LdGTd8ZAAAAAMVM63Wg5RyXBuImvgZpTcG-K7CV"></vue-recaptcha>
          </div>
          <b-button squared type="submit" variant="primary" class="mt-3">Mám záujem</b-button>
        </b-form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import VueRecaptcha from 'vue-recaptcha'
import cookies from '@/plugins/cookie-banner/getCookie'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { email, required, requiredIf, minLength, helpers } from '@vuelidate/validators';
// import { helpers } from 'vuelidate/lib/validators'
import { useVuelidate } from '@vuelidate/core';
const alpha = helpers.regex(/^[a-zA-Z \u00C0-\u024F]*$/)
export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "ContactForm",
  components: { 'vue-recaptcha': VueRecaptcha, VuePhoneNumberInput },
  props: ['internalId', 'stage'],
  data() {
    return {
      state: null,
      yourValue: null,
      dropdownActive: false,
      recaptchaActive: false,
      vuePhone: {
        phone: "",
        isValid: false,
        props: {
          clearable: true,
          preferredCountries: ["SK", "CZ", "AT", "PL"],
          defaultCountryCode: "SK",
          noExample: false,
          noFlags: true,
          error: false,
          translations: {
            countrySelectorLabel: "Kód krajiny",
            countrySelectorError: "Chyba",
            phoneNumberLabel: "Telefónne číslo",
            example: "Napríklad:"
          }
        }
      },
      contactForm: {
        "ga_client_id": "",
        "name": "",
        "surname": "",
        "email": "",
        "phone": "",
        "interests": [],
        "marketing_consent": false,
        "g-recaptcha-response": "",
        "internal_id": ""
      },
      validation: false,
      interestsError: false
    }
  },
  validations() {
    return {
      contactForm: {
        name: {
          required,
          minLength: minLength(3),
          onlyString: alpha
        },
        surname: {
          required,
          minLength: minLength(3),
          onlyString: alpha
        },
        email: { required, email },
        interests: {
           required: requiredIf(function() {
            return this.internalId ? false : true;
           })
        }
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.v$.contactForm[name];
      return $dirty ? !$error : null;
    },
    onUpdate: function(payload) {
      if (payload.isValid) {
        this.vuePhone.props.error = false
        this.vuePhone.isValid = true
      }
      this.contactForm["phone"] = payload.formattedNumber;
    },
    handleFocus() {
      if (this.dropdownActive) {
        this.dropdownActive = !this.dropdownActive
      }
    },
    showDropdown() {
      this.dropdownActive = !this.dropdownActive;
    },
    onVerify: function (response) {
      this.contactForm["g-recaptcha-response"] = response
    },
    async sendForm(e) {
      if (!this.recaptchaActive) {
        this.recaptchaActive = true
        return
      }
      const result = await this.v$.$validate()
      if (!result || !this.vuePhone.isValid) {
        if (!this.vuePhone.isValid) {
          this.vuePhone.props.error = true
          return
        }
        return
      }

      this.contactForm["first_interaction_source"] = cookies.getCookieByName("first_interaction")['utm_source']
      this.contactForm["first_interaction_medium"] = cookies.getCookieByName("first_interaction")['utm_medium']
      this.contactForm["first_interaction_campaign"] = cookies.getCookieByName("first_interaction")['utm_campaign']
      this.contactForm["first_interaction_content"] = cookies.getCookieByName("first_interaction")['utm_content']
      this.contactForm["first_interaction_gclid"] = cookies.getCookieByName("first_interaction")['gclid']
      this.contactForm["first_interaction_fbclid"] = cookies.getCookieByName("first_interaction")['fbclid']
      this.contactForm["last_interaction_source"] = cookies.getCookieByName("last_interaction")['utm_source']
      this.contactForm["last_interaction_medium"] = cookies.getCookieByName("last_interaction")['utm_medium']
      this.contactForm["last_interaction_campaign"] = cookies.getCookieByName("last_interaction")['utm_campaign']
      this.contactForm["last_interaction_content"] = cookies.getCookieByName("last_interaction")['utm_content']
      this.contactForm["last_interaction_gclid"] = cookies.getCookieByName("last_interaction")['gclid']
      this.contactForm["last_interaction_fbclid"] = cookies.getCookieByName("last_interaction")['fbclid']
      this.contactForm["internal_id"] = this.internalId ? this.internalId : ""
      if (this.stage) {
        this.contactForm.interests = [this.stage]
      }
      this.contactForm['ga_client_id'] = cookies.getCookieByName('_ga') ? cookies.getCookieByName('_ga').split('.').slice(-2).join(".") : 0

      const form = this.contactForm
      e.preventDefault()
      axios
          .post(`${process.env.VUE_APP_API_URL}/api/v1/contact-form`, form)
          .then(() => {
            this.contactForm.name = ""
            this.contactForm.surname = ""
            this.contactForm.email = ""
            this.contactForm.phone = ""
            this.contactForm.interests = []
            this.contactForm['marketing_consent'] = false
            this.contactForm["g-recaptcha-response"] = ""
            this.contactForm["internal_id"] = ""
            this.$router.push({
              name: 'FormularOdoslany'
            })
          }).catch((error) => {
            this.$refs.recaptcha.reset()
            // handle error
            console.log(error.response.data.error);
            this.interestsError = !!error.response.data.error.interests;
            this.validation = true
      })
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.name-wrapper
  gap: 0 8px

.dropdown
  background: #fff
  padding: 14px 8px
  color: #6C757D
  cursor: pointer

.recaptcha
  display: none

  &.active
    display: block

.dropdown-content
  background: #fff
  padding: 8px 12px
  border-top: 0.5px solid rgba(0, 0, 0, 0.2)
  position: absolute
  width: 100%
  z-index: 999
  display: none

  &.active
    display: block

  .check-label
    display: block
    color: #6C757D
    font-weight: 400
    cursor: pointer
    margin: 0

    &:hover
      background: rgba(124, 182, 217, 0.15)

.custom-alert-box
  p
    margin-bottom: 0
    font-size: 16px
    font-weight: normal

.contact
  background: #576675
  h2
    font-size: 55px
    line-height: 60px
    margin-bottom: 20px
    margin-top: 16px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    @media (max-width: $md)
      display: inline
      padding-right: 5px
  .image-bg
    background: url(../assets/images/contact-right.jpg) center/cover no-repeat
  .bv-no-focus-ring
    &:focus
      outline: none
  form
    .text-small
      font-size: 14px
      line-height: 20px
    .check-label
      font-size: 16px
      @media (max-width: $md)
        font-size: 14px
    .form-control
      height: 50px
      border: none
    .col-form-label
      margin-bottom: 5px
      font-size: 18px
    .custom-control-label
      font-size: 18px
    .small-font
      .custom-control-label
        font-size: 14px
    a
      color: var(--blue)
      font-size: 14px
      font-weight: 500
    button
      @media (max-width: $md)
        width: 100%
</style>