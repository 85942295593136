import axios from 'axios'
import collect from 'collect.js'

export default {
	namespaced: true,

	state: {
    flats: []
	},

	getters: {
    flats: state => state.flats,
    filteredFlats: state => filter => {
      let filtered = [...state.flats]

      if (!Object.keys(filter).length)
        return state.flats

      if (filter.stages && filter.stages.length) {
        // custom zobrazenie 2 roznych stage na jednej obrazovke novy dvor
        if (window.location.search === '?stage=complete' && filter.stages[0] === 'bory2b') {
          filter.stages = ['bory2', 'bory2b']
        }
        filtered = filtered.filter(flat => filter.stages.includes(flat.building.building_stage))
      }

      if (filter.dates && filter.dates.length)
        filtered = filtered.filter(flat => filter.dates.includes(flat.building.stage_completion_date))

      if (filter.buildings && filter.buildings.length)
        filtered = filtered.filter(flat => filter.buildings.includes(flat.building.building_name))

      if (filter.rooms && filter.rooms.length)
        filtered = filtered.filter(flat => filter.rooms.includes(flat.flat_number_of_rooms))

      if (filter.floors && filter.floors.length)
        filtered = filtered.filter(flat => filter.floors.includes(flat.building.floor_number))

      if (Number.isInteger(Number(filter.availability))) {
        filtered = filtered.filter(flat => String(filter.availability) === flat.flat_status)
      }

      if (filter.priceVat) {
        filtered = filtered.filter(flat => {
          const price = Number(flat.flat_price)
          return price >= filter.priceVat[0] && price <= filter.priceVat[1]
        })
      }

      if (filter.totalArea) {
        filtered = filtered.filter(flat => {
          const totalArea = Number(flat.flat_area)
          return totalArea >= filter.totalArea[0] && totalArea <= filter.totalArea[1]
        })
      }

      filtered.forEach(flat => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        flat.building.stage_completion_date = flat.building.stage_completion_date ? flat.building.stage_completion_date : '3.Q.2023'
      })

      return filtered
    },

    getFreeFlatsByBuildings: state => (stage, building) => {
      let stages = stage
      if (window.location.search === '?stage=complete' && stage === 'bory2b')
        stages = ['bory2', 'bory2b']
      const filtered = state.flats.filter( f => {
        return f.building.building_name == building
          && stages.includes(f.building.building_stage)
          && f.flat_status == 0
      })

      return filtered.length
    },
    getPreReservedFlatsByBuildings: state => (stage, building) => {
      const stages = stage
      const filtered = state.flats.filter( f => {
        return f.building.building_name == building
          && stages.includes(f.building.building_stage)
          && (f.flat_status == 1 || f.flat_status == 4 || f.flat_status == 5)
      })

      return filtered.length
    },
    getFlatsStatusByBuildings: state => (stage, building) => {
      let stages = stage
      if (window.location.search === '?stage=complete' && stage === 'bory2b')
        stages = ['bory2', 'bory2b']
      const filtered = state.flats.filter( f => {
        return f.building.building_name == building
          && stages.includes(f.building.building_stage)
          && f.flat_status != 3
      })

      return filtered.length
    },
    getFreeFlatsByBuildingAndFloor: state => (stage, building, floor) => {
      const filtered = state.flats.filter( f => {
        return f.building.building_name == building
          && f.building.building_stage == stage
          && f.building.floor_number == floor
      })

      return filtered.length
    },
    getFreeFlatsBySize: state => (stage) => {
      let stages = stage
      if (stage === 'bory2b')
        stages = ['bory2', 'bory2b']
      const sizes = {}
      const filtered = state.flats.filter( f => {
        const size = f.flat_number_of_rooms == "1,5" ? "1" : f.flat_number_of_rooms
        if(f.flat_status != 0 || !stages.includes(f.building.building_stage))
          return

        !sizes[size]
          ? sizes[size] = 1
          : sizes[size] ++
      })

      return sizes
    }
	},

	mutations: {
		retrievedFlats(state, flats) {
      // Custom filter, kvoli tomu, ze budovy A2 a B2 nie su este v predaji, no mozu im nastavit status ze je predrezervovany a pod, 
      // ale nechceme ich zobrazovat v tabulke kedze este nie je budova v predaji
      state.flats = flats
      // const A2flats = flats.filter(flat => flat.building.building_name === 'B2' && flat.building.building_stage === "bory4")

      

			const filter = flats.filter((flat) => {
          return flat
      })

      state.flats = filter
    }
	},

	actions: {
		async loadFlats({ commit }) {
			try {
        if(this.state.flats.length)
          return

				const resp = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/apartment-houses/filter?status[]=0&status[]=1&status[]=2&status[]=4&status[]=5`)
				commit('retrievedFlats', resp.data.data)
			} catch(err) { console.error(err) }
		}
	}

}
