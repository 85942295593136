<template>
  <section class="temp-block px-3 px-md-0 py-3">
    <div class="d-flex flex-wrap px-0 pl-md-5 pr-md-3 justify-content-between">
      <div class="col-12 col-lg-4 mb-3 mb-lg-0 px-0 py-lg-5">
        <h2 class="mb-5">Bývajte s&nbsp;úrokom zníženým o&nbsp;3 %</h2>
        <p class="mb-5">Financujte svoje bývanie úverom na bývanie s&nbsp;nižšou úrokovou sadzbou o&nbsp;3 % p. a. pri dvojročnej fixácii.</p>
        <b-button to="/znizeny-urok" squared variant="primary">Zistiť viac</b-button>
      </div>
      <div class="col-12 col-lg-7 px-0">
        <div class="img"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "TempBlock"
}
</script>

<style lang="sass" scoped>
$md: 768px
.temp-block
  background: var(--darkBlue)
  //background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.7) 100%), url("../assets/images/bory-jazierko/bory-jazierko.jpg") center/cover no-repeat
  //@media (max-width: $md)
  //  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory-jazierko/bory-jazierko.jpg") center/cover no-repeat
  h2
    margin-bottom: 0
    @media (max-width: $md)
      margin-bottom: 30px
  p
    @media (max-width: $md)
      br
        display: none
  .img
    width: 100%
    height: 100%
    background: url("../assets/images/nahradzi.jpg") no-repeat center
    background-size: cover
    @media (max-width: $md)
      min-height: 250px
      margin-bottom: 20px
</style>