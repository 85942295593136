<template>
  <div>
    <section class="b2-map">
      <SvgMap :map="mapData" :path-click="pathClick" :marker-click="markerClick" ref="b2mapa" />
    </section>
    <Podlazie v-if="floor && building" ref="floor" :stage="stage" :floor="floor" :building="building" :filter="filter"/>
  </div>
</template>

<script>
import SvgMap from "./SvgMap";
import Podlazie from "./Podlazie";
import map from "@/store/maps.json"
import misc from "@/store/misc.json"
import store from "@/store"
import ScrollTo from "@/plugins/scrollTo.util"
import {mapActions} from "vuex";

export default {
  name: "B2Map",
  components: {SvgMap, Podlazie},
  data() {
    return {
      filter: null,
      stage: "bory2",
      mapUrl: '/map/b2.jpg',
      showMap: false,
      mapData: map.b2,
      building: this.$route.params.building || null,
      floor: this.$route.params.floor || null
    }
  },

  async mounted() {
    await this.loadFlats()
    this._updateMarkersText()
    this._updateFloorsClasses()
    this.$root.$on('filterChanged', (val) => {
      this.floor = val;
    })
  },

  methods: {
    ...mapActions('flats', ['loadFlats']),
    pathClick: function (oEvent) {
      const data = oEvent.target.dataset.map.split("-")
      this.building = data[0]
      this.floor = data[1]
      this.filter = {
        stages: this.stage,
        dates: [],
        buildings: [this.building],
        rooms: [],
        floors: [this.floor],
        availability: 0,
        priceVat: null,
        totalArea: null
      }

      this.$root.$emit('setFilter',
          {
            stages: this.stage,
            dates: [],
            buildings: [this.building],
            rooms: [],
            floors: [this.floor],
            availability: 0,
            priceVat: null,
            totalArea: null
          }
      )
      setTimeout(() => ScrollTo.id(this, 'floor-plan'), 500)
    },

    markerClick: function (oEvent) {
      if(oEvent.target.parentElement.classList.contains('empty')
          || oEvent.target.classList.contains('empty'))
        return

      const data = oEvent.target.dataset.label
          || oEvent.target.parentElement.dataset.label

      this.$root.$emit('setFilter',
          {
            stages: this.stage,
            dates: [],
            buildings: [data],
            rooms: [],
            floors: [],
            availability: 0,
            priceVat: null,
            totalArea: null
          }
      )
      ScrollTo.id(this, 'tabulka')
    },

    _updateFloorsClasses: function () {
      this.mapData.paths.forEach( (f, i) => {
        const data = f.data.mapId.split("-")
        const count = store.getters["flats/getFreeFlatsByBuildingAndFloor"](this.stage, data[0], data[1])
        this.mapData.paths[i].class = count > 0 ? 'path' : 'hidden'
      })
    },
    _updateMarkersText: function () {
      this.mapData.markers.forEach( (m, i) => {
        const count = store.getters["flats/getFreeFlatsByBuildings"](this.stage, m.label)
        if(count)
          this.mapData.markers[i].text = count + " " + misc.volneCount[count > 5 ? 5 : count]
        else
          this.mapData.markers[i].class = 'empty'
      })
      this.$refs.b2mapa.$forceUpdate()
    }
  }
}
</script>

<style lang="sass">
$md: 768px
.b2-map
  background-image: url("/map/b2.jpg")
  background-repeat: no-repeat
  background-size: cover
  position: relative

  @media (max-width: $md)
    transform: scale(2) translateX(-10vw)

  svg
    position: relative
    z-index: 2
    width: 100%
    height: auto
    .marker
      fill: #83cff0
      cursor: pointer
    .marker-wrapper.empty
      .empty
        fill: white
      text
        fill: #83cff0
    .marker-wrapper:not(.empty)
      text
        cursor: pointer
        fill: white
    .hidden
      display: none
    .path
      fill: transparent
      transition: all linear .2s
      &:hover
        fill: #83cff0
        cursor: pointer

</style>
