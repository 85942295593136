<template>
  <div class="rules-page px-3">
    <div class="container">
      <div class="row">
        <h1 class="mt-md-5 mb-4 mb-md-0 text-center w-100">Podmienky akcie Odporučte projekt Bory Bývanie</h1>
        <div class="col-9 mx-auto py-md-5 px-4">
          <p class="c17"><span class="c14">Spolo&#269;nos&#357;</span><span class="c15">&nbsp;Bory Home s. r. o.</span><span
            class="c14">, so s&iacute;dlom Digital Park II, Einsteinova 25, 851 01 Bratislava, Slovensk&aacute;
            republika, I&#268;O: 48&nbsp;099 171, zap&iacute;san&aacute; v&nbsp;Obchodnom registri Mestsk&eacute;ho
            s&uacute;du Bratislava III, oddiel Sro, vlo&#382;ka &#269;. 103386/B; </span><span class="c15">Bory Home II
            s.r.o.</span><span class="c14">, so s&iacute;dlom Digital Park II, Einsteinova 25, 851 01 Bratislava &ndash;
            mestsk&aacute; &#269;as&#357; Petr&#382;alka, Slovensk&aacute; republika, I&#268;O: 51&nbsp;035 073,
            zap&iacute;san&aacute; v&nbsp;Obchodnom registri Mestsk&eacute;ho s&uacute;du Bratislava III, oddiel Sro,
            vlo&#382;ka &#269;. 122328/B; a </span><span class="c15">Bory Home III s.r.o.</span><span class="c14">, so
            s&iacute;dlom Digital Park II, Einsteinova 25, 851 01 Bratislava &ndash; mestsk&aacute; &#269;as&#357;
            Petr&#382;alka, Slovensk&aacute; republika, I&#268;O: 51&nbsp;719 011, zap&iacute;san&aacute;
            v&nbsp;Obchodnom registri Mestsk&eacute;ho s&uacute;du Bratislava III, oddiel Sro, vlo&#382;ka &#269;.
            134974/B (&#271;alej jednotlivo len &bdquo;</span><span class="c15">poskytovate&#318;</span><span
            class="c3">&ldquo;), ka&#382;d&aacute; samostatne a&nbsp;nez&aacute;visle, vo vz&#357;ahu k&nbsp;&#328;ou
            realizovan&eacute;mu developersk&eacute;mu &ndash; realitn&eacute;mu projektu, v s&uacute;lade s &sect; 850
            a nasl. z&aacute;kona &#269;.&nbsp;40/1964 Zb. Ob&#269;iansky z&aacute;konn&iacute;k v&nbsp;znen&iacute;
            neskor&scaron;&iacute;ch predpisov, t&yacute;mto verejne vyhlasuje, &#382;e zaplat&iacute; odmenu (ako je
            &scaron;pecifikovan&aacute; ni&#382;&scaron;ie) v&scaron;etk&yacute;m fyzick&yacute;m osob&aacute;m
            (nepodnikate&#318;om), ktor&eacute; riadne a&nbsp;v&#269;as splnia podmienky stanoven&eacute;
            v&nbsp;t&yacute;chto podmienkach akcie.</span></p>
          <p class="c10"><span class="c3"></span></p>
          <ol class="c5 lst-kix_list_1-0 start" start="1">
              <li class="c19 li-bullet-0">
                  <h1 style="display:inline"><span class="c1">Podmienky</span></h1>
              </li>
          </ol>
          <p style="display:inline"><strong>1.1</strong> <span class="c3">Uch&aacute;dza&#269;ovi o odmenu vznik&aacute; n&aacute;rok na
                          odmenu, za predpokladu v&#269;asn&eacute;ho a&nbsp;riadneho kumulat&iacute;vneho
                          (s&uacute;&#269;asn&eacute;ho) splnenia nasledovn&yacute;ch podmienok:</span></p>
          <ol class="c5 lst-kix_list_2-1 start pl-5 mt-3" start="1" style="list-style-type: lower-latin;">
              <li class="c2 li-bullet-2">
                  <p style="display:inline"><span class="c6">uch&aacute;dza&#269; o&nbsp;odmenu uzatvoril
                          s&nbsp;poskytovate&#318;om (i) zmluvu o&nbsp;bud&uacute;cej zmluve o&nbsp;prevode
                          vlastn&iacute;ckeho pr&aacute;va, k&nbsp;niektor&eacute;mu bytu alebo apartm&aacute;nu,
                          budovan&eacute;mu v projekte Bory B&yacute;vanie, a&nbsp;to v projekte Bory Prv&eacute; Domy a/alebo
                          v projekte Bory Nov&yacute; Dvor a/alebo v projekte Bory Promen&aacute;da a/alebo v projekte Bory Na
                          Hr&aacute;dzi (&#271;alej jednotlivo len &bdquo;</span><span class="c9">projekt</span><span
                          class="c3">&ldquo;) alebo (ii) k&uacute;pnu zmluvu k&nbsp;bytu alebo apartm&aacute;nu
                          v&nbsp;projekte, pri&#269;om t&aacute;to je v&nbsp;&#269;ase splnenia v&scaron;etk&yacute;ch
                          podmienok st&aacute;le platn&aacute; a&nbsp;&uacute;&#269;inn&aacute; a&nbsp;z&aacute;rove&#328;,
                          v&nbsp;&#269;ase splnenia podmienky pod&#318;a p&iacute;sm. d) ni&#382;&scaron;ie, nie je
                          uch&aacute;dza&#269; o odmenu v&nbsp;ome&scaron;kan&iacute; s&nbsp;plnen&iacute;m akejko&#318;vek
                          platby pod&#318;a takejto zmluvy;</span></p>
              </li>
              <li class="c2 li-bullet-2">
                  <p style="display:inline"><span class="c6">uch&aacute;dza&#269; o&nbsp;odmenu ani z&aacute;ujemca (ako je
                          &scaron;pecifikovan&yacute; ni&#382;&scaron;ie) nie je zamestnancom ani &#269;lenom
                          &scaron;tatut&aacute;rneho org&aacute;nu, pr&iacute;padne &#269;lenom dozorn&eacute;ho org&aacute;nu
                          niektorej zo spolo&#269;nost&iacute; ovl&aacute;danej spolo&#269;nos&#357;ou PENTA REAL ESTATE
                          HOLDING LIMITED, zalo&#382;enou a existuj&uacute;cou pod&#318;a pr&aacute;va Cypru, so s&iacute;dlom
                          Agias Fylaxeos &amp; Polygnostou, 212 C &amp; I CENTER, 2nd floor, 3082 Limassol, Cyprus,
                          registrovanej Ministerstvom energetiky, obchodu a priemyslu Cypru, Oddelen&iacute;m
                          registr&aacute;tora spolo&#269;nost&iacute; a du&scaron;evn&eacute;ho vlastn&iacute;ctva, pod
                          &#269;&iacute;slom: HE 295402, ani jeho man&#382;elom alebo man&#382;elkou;</span><span>&nbsp;
                          &nbsp;</span></p>
              </li>
              <li class="c2 li-bullet-2">
                  <p style="display:inline"><span class="c6">uch&aacute;dza&#269; o&nbsp;odmenu odporu&#269;il tretej osobe,
                          t. j. osobe odli&scaron;nej od uch&aacute;dza&#269;a o odmenu (&#271;alej len &bdquo;</span><span
                          class="c9">z&aacute;ujemca</span><span class="c3">&ldquo;), ktor&aacute; z&aacute;rove&#328; nie je
                          jeho man&#382;elom alebo man&#382;elkou, pr&iacute;padne nie je pr&aacute;vnickou osobou,
                          v&nbsp;ktorej m&aacute; uch&aacute;dza&#269; o&nbsp;odmenu majetkov&yacute; podiel, podiel na
                          hlasovac&iacute;ch alebo in&yacute;ch pr&aacute;vach, k&uacute;pu bytu alebo apartm&aacute;nu
                          v&nbsp;projekte poskytovate&#318;a;</span></p>
              </li>
              <li class="c2 li-bullet-2">
                  <p id="h.gjdgxs" style="display:inline"><span class="c6">z&aacute;ujemca, ktor&eacute;mu
                          uch&aacute;dza&#269; o&nbsp;odmenu, odporu&#269;il k&uacute;pu bytu alebo apartm&aacute;nu
                          v&nbsp;projekte, uzatvor&iacute; s&nbsp;poskytovate&#318;om zmluvu o&nbsp;bud&uacute;cej zmluve
                          o&nbsp;prevode vlastn&iacute;ckeho pr&aacute;va, k&nbsp;niektor&eacute;mu bytu alebo
                          apartm&aacute;nu v&nbsp;projekte (&#271;alej len </span><span class="c9">&bdquo;bud&uacute;ca
                          zmluva&ldquo;</span><span class="c3">);</span></p>
              </li>
              <li class="c2 li-bullet-2">
                  <p style="display:inline"><span class="c6">z&aacute;ujemca najnesk&ocirc;r pri uzatvoren&iacute;
                          bud&uacute;cej zmluvy poskytovate&#318;ovi p&iacute;somne ozn&aacute;mi (vyhl&aacute;si), &#382;e
                          byt alebo apartm&aacute;n v&nbsp;projekte kupuje na z&aacute;klade odpor&uacute;&#269;ania
                          uch&aacute;dza&#269;a o&nbsp;odmenu, ktor&yacute; ho poveril, aby udelil
                          prev&aacute;dzkovate&#318;ovi sprac&uacute;vania osobn&yacute;ch &uacute;dajov</span><sup
                          class="c6 c28"><a href="#ftnt1" id="ftnt_ref1">[1]</a></sup><span class="c6">&nbsp;(</span><span
                          class="c9">&bdquo;prev&aacute;dzkovate&#318;&ldquo;</span><span class="c3">), s&uacute;hlas,
                          s&nbsp;poskytnut&iacute;m jeho osobn&yacute;ch &uacute;dajov na tieto &uacute;&#269;ely a&nbsp;s
                          n&aacute;sledn&yacute;m kontaktovan&iacute;m (elektronick&yacute;m/telefonick&yacute;m), zo strany
                          prev&aacute;dzkovate&#318;a, pri&#269;om z&aacute;ujemca neuvedie okrem uch&aacute;dza&#269;a
                          &#382;iadnu in&uacute; osobu ako osobu, na z&aacute;klade odpor&uacute;&#269;ania ktorej, ku
                          k&uacute;pe bytu alebo apartm&aacute;nu v&nbsp;projekte prist&uacute;pil;</span></p>
              </li>
              <li class="c2 li-bullet-2">
                  <p style="display:inline"><span class="c6">uch&aacute;dza&#269; o odmenu e-mailom na adresu </span><span
                          class="c6 c24"><a class="c7"
                              href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></span><span
                          class="c3">&nbsp;alebo telefonicky, prev&aacute;dzkovate&#318;ovi potvrd&iacute;, &#382;e
                          z&aacute;ujemcovi odporu&#269;il k&uacute;pu bytu v&nbsp;projekte a&nbsp;poskytne mu
                          &#269;&iacute;slo &uacute;&#269;tu (IBAN), na ktor&yacute; mu m&aacute; poskytovate&#318;
                          uhradi&#357; odmenu po splnen&iacute; v&scaron;etk&yacute;ch podmienok; a</span></p>
              </li>
              <li class="c2 li-bullet-2">
                  <p style="display:inline"><span class="c3">z&aacute;ujemca uzatvor&iacute; s&nbsp;poskytovate&#318;om
                          k&uacute;pnu zmluvu predpokladan&uacute; bud&uacute;cou zmluvou a uhrad&iacute; poskytovate&#318;ovi
                          k&uacute;pnu cenu ur&#269;en&uacute; v&nbsp;k&uacute;pnej zmluve v&nbsp;plnej
                          v&yacute;&scaron;ke.</span></p>
              </li>
          </ol>
          <ol class="c5 lst-kix_list_1-0" start="2">
              <li class="c8 li-bullet-3">
                  <h1 style="display:inline"><span class="c1">Odmena</span></h1>
              </li>
          </ol>
          <ol class="c5 lst-kix_list_8-0 start custom pl-4 mt-3" start="1">
              <li class="c16 li-bullet-2" style="list-style: upper-latin;"><span class="c0">Pre uch&aacute;dza&#269;a o odmenu</span></li>
              <div class="mt-3 pl-4">
                <p><span class="c6"><div class="custom-num">2.1</div> V&yacute;&scaron;ka jednorazovej odmeny pre
                        uch&aacute;dza&#269;a o&nbsp;odmenu bude predstavova&#357; sumu zodpovedaj&uacute;cu 1 %
                        z&nbsp;celkovej k&uacute;pnej ceny bytu alebo apartm&aacute;nu, ktor&yacute; z&aacute;ujemca
                        u&nbsp;poskytovate&#318;a zak&uacute;pil (&#271;alej len </span><span
                        class="c9">&bdquo;odmena&ldquo;</span><span class="c3">). Pre vyl&uacute;&#269;enie
                        pochybnost&iacute;, do celkovej ceny bytu alebo apartm&aacute;nu, z&nbsp;ktorej sa odmena
                        po&#269;&iacute;ta, sa nezapo&#269;&iacute;tava k&uacute;pna cena parkovac&iacute;ch a
                        gar&aacute;&#382;ov&yacute;ch st&aacute;t&iacute;, gar&aacute;&#382;&iacute;,
                        gar&aacute;&#382;ov&yacute;ch boxov, kobiek ani skladov a&nbsp;pod.. </span></p>
                <p><span class="c3"><div class="custom-num">2.2</div> Odmena bude uch&aacute;dza&#269;ovi o odmenu vyplaten&aacute; v
                        jednej spl&aacute;tke splatnej do 30 dn&iacute; po splnen&iacute; poslednej z podmienok pod&#318;a
                        bodu 1.1, na bankov&yacute; &uacute;&#269;et ur&#269;en&yacute; uch&aacute;dza&#269;om
                        o&nbsp;odmenu, nie v&scaron;ak sk&ocirc;r ako z&aacute;ujemca v&nbsp;plnom rozsahu uhrad&iacute;
                        k&uacute;pnu cenu pod&#318;a k&uacute;pnej zmluvy uzatvorenej na z&aacute;klade bud&uacute;cej
                        zmluvy.</span></p>
                <p><span class="c3"><div class="custom-num">2.3</div> Za splnenie v&scaron;etk&yacute;ch da&#328;ov&yacute;ch
                        povinnost&iacute; vypl&yacute;vaj&uacute;cich z prijatia odmeny pod&#318;a t&yacute;chto podmienok
                        je plnom rozsahu zodpovedn&yacute; uch&aacute;dza&#269; o&nbsp;odmenu, pri&#269;om
                        uch&aacute;dza&#269; o&nbsp;odmenu nem&aacute; pr&aacute;vny n&aacute;rok na ich &uacute;hradu alebo
                        n&aacute;hradu zo strany poskytovate&#318;a.</span></p>
                <p id="h.30j0zll"><span class="c3"><div class="custom-num">2.4</div> Pre vyl&uacute;&#269;enie pochybnost&iacute;,
                        odmenu uch&aacute;dza&#269;ovi o&nbsp;odmenu poskytne len ten z&nbsp;poskytovate&#318;ov,
                        v&nbsp;ktor&eacute;ho projekte byt alebo apartm&aacute;n z&aacute;ujemca zak&uacute;pi.</span></p>
              </div>
              <li class="c16 li-bullet-2" style="list-style: upper-latin;"><span class="c0">Pre z&aacute;ujemcu</span></li>
              <div class="mt-3 pl-4">
                <p><span class="c6"><div class="custom-num">2.5</div> Poskytovate&#318; sa rozhodol, &#382;e po splnen&iacute;
                        poslednej z podmienok pod&#318;a bodu 1.1, odmen&iacute; aj z&aacute;ujemcu. Jednorazov&aacute;
                        odmena z&aacute;ujemcu (</span><span class="c9">&bdquo;bonus&ldquo;</span><span class="c6">) bude
                        poskytnut&aacute; v&yacute;lu&#269;ne vo forme Bory Mall dar&#269;ekovej karty,
                        umo&#382;&#328;uj&uacute;cej z&aacute;ujemcovi n&aacute;kup v&nbsp;r&aacute;mci vybran&yacute;ch
                        prev&aacute;dzok n&aacute;kupn&eacute;ho centra Bory Mall, nach&aacute;dzaj&uacute;ceho sa
                        v&nbsp;Bratislave, v&nbsp;susedstve projektu. Podmienky vyu&#382;itia dar&#269;ekovej karty
                        n&aacute;jdete </span><span class="c6"><a href="https://www.borymall.sk/sk/darcekova-karta-nakup" target="_blank">tu.</a></span>
                </p>
                <p><span class="c3"><div class="custom-num">2.6</div> V&yacute;&scaron;ka bonusu, poskytnut&aacute;
                        prostredn&iacute;ctvom Bory Mall dar&#269;ekovej karty z&aacute;ujemcovi, bude stanoven&aacute;,
                        v&nbsp;z&aacute;vislosti od po&#269;tu izieb bytu alebo apartm&aacute;nu, ktor&yacute;
                        z&aacute;ujemca k&uacute;pnou zmluvou uzatvorenou na z&aacute;klade bud&uacute;cej zmluvy od
                        poskytovate&#318;a k&uacute;pi, a&nbsp;to nasledovne:</span></p>
                <p class="c18 pl-4"><span class="c3">1,5-izbov&yacute;
                        byt/apartm&aacute;n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bonus 1500 &euro;; <br>2-izbov&yacute;
                        byt/apartm&aacute;n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bonus 2000 &euro;;<br>3-izbov&yacute;
                        byt/apartm&aacute;n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bonus 2700 &euro;;<br>4-izbov&yacute;
                        byt/apartm&aacute;n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bonus 3300 &euro;;<br>5-izbov&yacute;
                        byt/apartm&aacute;n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bonus 4500 &euro;.</span></p>
                <p><span class="c6"><div class="custom-num">2.7</div> Z&aacute;ujemcovi za &#382;iadnych podmienok nevznikne
                        pr&aacute;vny n&aacute;rok na vyplatenie bonusu v&nbsp;peniazoch alebo inej forme ako vo forme Bory
                        Mall dar&#269;ekovej karty.</span></p>
                <p><span class="c6"><div class="custom-num">2.8</div> Bonus bude z&aacute;ujemcovi poskytnut&yacute;
                        (odovzdan&yacute;) do 30 dn&iacute; po splnen&iacute; poslednej z podmienok pod&#318;a bodu 1.1, nie
                        v&scaron;ak sk&ocirc;r ako z&aacute;ujemca v&nbsp;plnom rozsahu uhrad&iacute; k&uacute;pnu cenu
                        pod&#318;a k&uacute;pnej zmluvy, uzatvorenej na z&aacute;klade bud&uacute;cej zmluvy.
                        O&nbsp;odovzdan&iacute; bonusu bude medzi poskytovate&#318;om a&nbsp;z&aacute;ujemcov
                        sp&iacute;san&yacute; preberac&iacute; protokol.</span></p>
                <p><span class="c3"><div class="custom-num">2.9</div> Za splnenie v&scaron;etk&yacute;ch da&#328;ov&yacute;ch
                        povinnost&iacute; vypl&yacute;vaj&uacute;cich z prijatia bonusu je plnom rozsahu zodpovedn&yacute;
                        z&aacute;ujemca, pri&#269;om z&aacute;ujemca nem&aacute; pr&aacute;vny n&aacute;rok na ich
                        &uacute;hradu alebo n&aacute;hradu zo strany poskytovate&#318;a.</span></p>
                <p><span class="c3"><div class="custom-num">2.10</div> Pre vyl&uacute;&#269;enie pochybnost&iacute;, bonus
                        z&aacute;ujemcovi poskytne len ten z&nbsp;poskytovate&#318;ov, v&nbsp;ktor&eacute;ho projekte byt
                        alebo apartm&aacute;n z&aacute;ujemca zak&uacute;pi.</span></p>

              </div>
          </ol>
          <ol class="c5 lst-kix_list_1-0" start="3">
              <li class="c8 li-bullet-0">
                  <h1 style="display:inline"><span class="c1">Platnos&#357; a &uacute;&#269;innos&#357;</span></h1>
              </li>
              <div class="mt-3 pl-4">
                <p><span class="c3"><div class="custom-num">3.1</div> Tieto podmienky s&uacute; platn&eacute; a
                        &uacute;&#269;inn&eacute; od 15.6.2023 a platia pre uch&aacute;dza&#269;ov o&nbsp;odmenu a
                        z&aacute;ujemcov, ktor&iacute; splnia v&scaron;etky stanoven&eacute; podmienky podľa bodu 1.1 písm. a) až f) najnesk&ocirc;r do
                        31.12.2023.</span></p>
                <p><span class="c3"><div class="custom-num">3.2</div> Tieto podmienky m&ocirc;&#382;e poskytovate&#318;
                        kedyko&#318;vek bez vysvetlenia a/alebo predch&aacute;dzaj&uacute;ceho upozornenia upravi&#357;,
                        doplni&#357; alebo odvola&#357;. Inform&aacute;cie o&nbsp;ich &uacute;prave, doplnen&iacute; alebo
                        odvolan&iacute; poskytovate&#318; alebo n&iacute;m poveren&aacute; osoba zverejn&iacute; na
                        www.borybyvanie.sk. </span></p>
              </div>
          </ol>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  name: "OdporucteBoryPodmienky",
  components: {ContactForm},
}
</script>

<style lang="sass" scoped>
.rules-page
  padding-top: 85px
  background: #2D323A

  h1
    @media (max-width: 768px)
      font-size: 30px

  .rule
    margin-bottom: 40px

    h3
      font-size: 22px

  p
    font-size: 16px
    font-weight: 400

  ol
    margin-top: 50px
    padding-left: 0
    li
      font-weight: 600

  a
    color: #fff
    font-weight: 500

p
  position: relative

  .custom-num
    position: absolute
    left: -30px
    top: 0
    font-weight: bold
</style>