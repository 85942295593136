// Get cookies info
import cookies from '@/plugins/cookie-banner/getCookie'

export default {
	getUtmData(params) {
		if(cookies.getCookie() && cookies.getCookie().cookie_analytic) {
			if (!params) return

            const utm = {
                "utm_source": params.utm_source,
                "utm_medium": params.utm_medium,
                "utm_campaign": params.utm_campaign,
                "utm_content": params.utm_content,
                "fbclid": params.fbclid,
                "gclid": params.gclid
            }

            const date = new Date();
            date.setTime(date.getTime() + (365*24*60*60*1000));
            const expires = "; expires=" + date.toUTCString();

            if (!cookies.getCookieByName('first_interaction')) {
                document.cookie = 'first_interaction=' + JSON.stringify(utm) + expires + "; path=/";
            }

            document.cookie = 'last_interaction=' + JSON.stringify(utm) + expires + "; path=/";
            
		}
	}
}
