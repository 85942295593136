<template>
  <div class="bory-area-map">
    <div class="hand-icon">
      <img src="../../assets/icons/hand-icon.svg" alt="">
    </div>
    <div class="section-menu-wrapper">
      <div class="section-menu">
        <div class="link living active" @click="show('living')"><span class="icon icon-icon-living"></span> Bývanie</div>
        <div class="link shopping" @click="show('shopping')"><span class="icon icon-icon-shopping"></span>Nákupy</div>
        <div class="link transport" @click="show('transport')"><span class="icon icon-icon-transport"></span>Doprava</div>
        <div class="link relax" @click="show('relax')"><span class="icon icon-icon-relax"></span>Voľný čas</div>
        <div class="link services" @click="show('services')"><span class="icon icon-icon-services"></span>Služby</div>
      </div>
    </div>
    <div class="map">
      <div class="mobile">
        <img src="../../assets/images/stvrt-mapa2.jpg" alt="Bory štvrť" id="stvrt-default">
        <img src="../../assets/images/stvrt-icons/stvrt-mapa-centrum.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="centrum">
        <img src="../../assets/images/stvrt-icons/stvrt-mapa.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="zapad">
        <img src="../../assets/images/stvrt-icons/stvrt-mapa-sever.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="sever">
        <img src="../../assets/images/stvrt-icons/stvrt-novydvor.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="novydvor">
        <img src="../../assets/images/stvrt-icons/stvrt-hradza.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="hradza">
        <img src="../../assets/images/stvrt-icons/stvrt-promenada.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="promenada">
        <img src="../../assets/images/stvrt-icons/stvrt-prvedomy.jpg" alt="Bory štvrť" class="stvrt-img d-none" id="prvedomy">
        <div class="overlay active" id="living">
          <svg width="2401px" height="1606px" viewBox="0 0 2401 1606" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.6">
<!--                  <polygon @mouseover="showImg('centrum')" @mouseout="setDefault('centrum')" id="Path" fill="transparent" points="1826.7032 1596.6986 1440.0288 1528.3639 1186.6904 1426.6952 851.6837 823.3498 1795.0359 790.0158 2160.0432 1513.3636 2126.7092 1575.0315 2096.7086 1601.6987"></polygon>-->
                  <polygon @mouseover="showImg('centrum')" @mouseout="setDefault('centrum')" id="Path" fill="transparent" points="864 848 1141 1373 1373 1499 1903 1607 2131 1607 2157 1561 2142 1508 1824 832 1135 842 1139 677 1011 678 1008 842"></polygon>
            </g>
            <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.6">
                <polygon @mouseover="showImg('zapad')" @mouseout="setDefault('zapad')" id="Path" fill="transparent" points="369 80 629 99 576 319 1115 1328 1101 1343 64 905"></polygon>
            </g>
            <!-- <g>
              <polygon @mouseover="showImg('novydvor')" @mouseout="setDefault('novydvor')" class="st1" points="400.7,512.2 429.2,682.2 453.5,696.4 693.7,632.8 623.4,477.9 643.5,469.5 658.6,455.3 669.4,435.2 
		670.3,411.8 666.1,389.2 654.4,373.3 638.5,359.9 615,352.3 592.4,352.3 573.2,362.4 557.3,376.6 547.2,394.2 541.4,417.6 
		547.2,434.4 550.6,445.3 556.4,456.1 566.5,467 	" fill="transparent"/>
            </g> -->
            <g id="Page-3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.6">
                <polygon @mouseover="showImg('sever')" @mouseout="setDefault('sever')" id="Path1" fill="transparent" points="860 846 1828 827 1461 112 635 97 573 326"></polygon>
                <polygon @mouseover="showImg('sever')" @mouseout="setDefault('sever')" id="Path2" fill="transparent" points="1453 104 625 92 576 324 861 838 1007 847 1006 675 1141 671 1137 843 1815 824"></polygon>
            </g>
            <g id="Layer_2">
              <a href="./byvanie/bory3">
                <polygon @mouseover="showImg('promenada')" @mouseout="setDefault('promenada')" class="st0" points="1065.4,852.1 1012.7,852.1 1012.7,996.1 1374.4,988.6 1358.5,842.9 1078.8,851.3 1078.8,818.7 
                  1104,809.4 1119.9,796.1 1131.6,779.3 1136.6,755 1131.6,730.8 1117.4,709.8 1102.3,696.4 1080.5,689.7 1053.7,689.7 1031.1,703.1 
                  1012.7,724.9 1006,750.8 1010.2,778.5 1030.3,804.4 1047.9,817 1063.8,818.7 	" fill="transparent"/>
              </a>
              <a href="./byvanie/bory1">
                <polygon @mouseover="showImg('prvedomy')" @mouseout="setDefault('prvedomy')" class="st0" points="1260.5,724.1 1167.6,728.2 1021.1,758.4 1021.1,851.3 1366,837.9 1350.1,720.7 1276.4,724.1 
                  1275.6,672.1 1298.2,666.3 1320,647.9 1334.2,621.9 1333.4,595.1 1321.6,566.7 1292.3,544.9 1271.4,544.9 1250.5,544.9 
                  1226.2,558.3 1210.3,582.6 1204.4,609.4 1211.1,633.6 1224.5,655.4 1241.3,667.1 1262.2,672.1 	" fill="transparent"/>
              </a>
              <a href="./byvanie/novy-dvor">
                <polygon @mouseover="showImg('novydvor')" @mouseout="setDefault('novydvor')" class="st0" points="400.7,512.2 429.2,682.2 453.5,696.4 693.7,632.8 623.4,477.9 643.5,469.5 658.6,455.3 669.4,435.2 
                  670.3,411.8 666.1,389.2 654.4,373.3 638.5,359.9 615,352.3 592.4,352.3 573.2,362.4 557.3,376.6 547.2,394.2 541.4,417.6 
                  547.2,434.4 550.6,445.3 556.4,456.1 566.5,467 	" fill="transparent"/>
              </a>
              <a href="./byvanie/na-hradzi">
                <polygon @mouseover="showImg('hradza')" @mouseout="setDefault('hradza')" class="st0" points="424.1,408.4 402.4,496.3 605,462 578.2,380.8 520.4,388.3 520.4,317.2 549.7,306.3 565.6,289.5 
                  577.4,265.3 576.5,237.6 565.6,213.4 547.2,194.1 518.8,185.7 493.6,189.1 471.9,201.6 453.5,225.9 449.3,249.4 450.1,273.6 
                  467.7,299.6 486.1,311.3 503.7,316.3 503.7,392.5 	" fill="transparent"/>
              </a>
            </g>
          </svg>
        </div>
        <div class="overlay" id="shopping"></div>
        <div class="overlay" id="transport"></div>
        <div class="overlay" id="relax"></div>
        <div class="overlay" id="services"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StvrtMap",
  methods: {
    showImg(img) {
      if (img == 'centrum') {
        document.getElementById('Path1').classList.add('d-none')
      } else {
        document.getElementById('Path1').classList.remove('d-none')
      }
      if (img == 'centrum' || img == 'zapad' || img == 'sever')
        document.getElementById('living').classList.add('no-pins')
      else
        document.getElementById('living').classList.add('no-overlay')
      document.getElementById('stvrt-default').classList.remove('d-block')
      document.getElementById('stvrt-default').classList.add('d-none')
      document.getElementById(img).classList.add('d-block')
    },
    setDefault(img) {
      document.getElementById('living').classList.remove('no-pins')
      document.getElementById('living').classList.remove('no-overlay')
      document.getElementById('stvrt-default').classList.remove('d-none')
      document.getElementById('stvrt-default').classList.add('d-block')
      document.getElementById(img).classList.remove('d-block')
    },
    show(el) {
      document.querySelector(".overlay.active").classList.remove('active')
      document.querySelector(".link.active").classList.remove('active')
      document.querySelector('.' + el).classList.add('active')
      document.getElementById(el).classList.add('active')
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.bory-area-map
  position: relative

svg
  max-width: 100%
  height: auto
  position: relative
  z-index: 99
.map
  position: relative
  @media (max-width: $md)
    display: flex
    flex-wrap: nowrap
    overflow-x: auto
    height: 500px
    -webkit-overflow-scrolling: touch

    &::-webkit-scrollbar
      display: none
  .mobile
    @media (max-width: $md)
      transform: scale(2.8)
      top: 40%
      left: 80%
      position: absolute
      width: 100%

.section-menu
  // position: absolute
  // right: 150px
  // top: 150px
  width: 100%
  z-index: 999
  display: flex
  padding: 0 30px
  margin-bottom: 20px
  position: sticky
  top: 85px
  background: var(--darkBlue)
  @media (max-width: $md)
    display: flex
    flex-wrap: nowrap
    overflow-x: auto
    -webkit-overflow-scrolling: touch
    -ms-overflow-style: -ms-autohiding-scrollbar
    margin-bottom: 15px
    margin-right: -15px
    padding: 0
    padding-right: 80px

    &::-webkit-scrollbar
      display: none
.section-menu-wrapper
  @media (max-width: $md)
    position: relative

    &::after
      content: ""
      width: 98px
      height: 100%
      background: linear-gradient(270deg, #21252B 20.13%, rgba(33, 37, 43, 0) 100%)
      position: absolute
      right: 0
      top: 0
      pointer-events: none
      z-index: 99999
  .link
    padding: 10px 20px 10px 55px
    border-bottom: 1px solid #fff
    font-family: objektiv-mk1,sans-serif
    font-weight: 600
    cursor: pointer
    position: relative
    width: 20%
    @media (max-width: $md)
      width: auto
      flex: 0 0 auto
    .icon
      position: absolute
      left: 20px
      top: 50%
      transform: translateY(-50%)
      font-size: 18px
    &.active
      color: #77B9D9
      background: rgba(119, 185, 217, 0.2)
      border-bottom: 2px solid var(--grey)
    &:hover
      color: var(--blue)
      border-bottom: 2px solid var(--grey)
.overlay
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  opacity: 0
  transition: .2s opacity ease-in-out
  &.active
    opacity: 1
  &#living
    background: url(../../assets/images/stvrt-icons/overlay-living.png) no-repeat
    background-size: contain
    &.no-pins
      background: url(../../assets/images/stvrt-icons/overlay-living-2.png) no-repeat
      background-size: contain
    &.no-overlay
      background: none
  &#shopping
    background: url(../../assets/images/stvrt-icons/overlay-shopping.png) no-repeat
    background-size: contain
  &#transport
    background: url(../../assets/images/stvrt-icons/overlay-transport.png) no-repeat
    background-size: contain
  &#relax
    background: url(../../assets/images/stvrt-icons/overlay-relax.png) no-repeat
    background-size: contain
  &#services
    background: url(../../assets/images/stvrt-icons/overlay-services.png) no-repeat
    background-size: contain


</style>
