<template>
  <div>
    <div>
      <div class="headline p-md-5 d-flex align-items-end" :style="'background: linear-gradient(0.52deg, rgba(33, 37, 43, 0.53) 14.72%, rgba(33, 37, 43, 0) 168.66%), url(/news/custom-banner.jpg) center/cover'">
        <div class="text pb-4 pb-md-3 pt-5 px-3 px-md-5">
          <div class="category">Цікаво знати</div>
          <h1 class="m-0">Шлях до будинку вашої мрії не повинен бути складним</h1>
        </div>
      </div>
      <div class="news m-auto py-4 py-md-5 custom-article">
        <div class="news-wrapper detail">
          <div class="article-container">
            <p class="mb-5 strong">Придбайте для себе новий дім у районі Бори, який пропонує розвинену соціально-побутову інфраструктуру, безліч дитячих та спортивних майданчиків, греблю прямо під вашим будинком, а також дошкільний навчальний заклад і магазини всього за кілька хвилин від вашого дому.</p>
            <h6>Ви можете вибрати одну з квартир із серій: </h6>
            <p class="mb-2"><a href="/byvanie/novy-dvor">Bory Nový Dvor</a></p>
            <p class="mb-2"><a href="/byvanie/bory3">Bory Promenáda</a></p>
            <p class="mb-2"><a href="/byvanie/na-hradzi">Bory Na Hrádzi</a></p>
            <p>У продажу ви знайдете широкий вибір від одно- до чотирикімнатних квартир.</p>

            <h2>Як профінансувати придбання житла в проєкті Bory Bývanie?</h2>
            <p>Ми познайомимо вас з можливостями та процесом фінансування вашої нової нерухомості в наших проєктах.</p>
            <h3>Чи може громадянин України взяти іпотечний кредит у Словаччині?</h3>
            <p>Можливість фінансування нерухомості через іпотечний кредит для громадян України існує при виконанні стандартних умов банків, аналогічних умовам, які діють для громадян Словаччини. Головною умовою є підтвердження постійного доходу з місця роботи або доходу від підприємницької діяльності. Другою умовою є документально підтверджене тимчасове або постійне проживання в Словаччині.</p>

            <h3>Процес фінансування за 8 чітких кроків</h3>
            <div class="timeline">
                <div class="step step-1">
                    <div class="num">1</div>
                    <div class="desc">
                        <p class="strong">Ви обираєте квартиру/квартири, які вас цікавлять.</p>
                    </div>
                </div>
                <div class="step step-2">
                    <div class="num">2</div>
                    <div class="desc">
                        <p class="strong">Ви звертаєтесь до нашого продавця, який проконсультує вас щодо вибору квартири, а після підготовки договору з'єднає вас з фахівцем з іпотечного кредитування.</p>
                    </div>
                </div>
                <div class="step step-3">
                    <div class="num">3</div>
                    <div class="desc">
                        <p class="strong">Наш іпотечний спеціаліст організує індивідуальну зустріч з вами та підготує для вас найвигідніші пропозиції стосовно фінансування протягом 24 годин після зустрічі.</p>
                    </div>
                </div>
                <div class="step step-4">
                    <div class="num">4</div>
                    <div class="desc">
                        <p class="strong">Він підготує заявку на отримання іпотечного кредиту в обраному вами банку/банках і здійснюватиме від вашого імені всю необхідну комунікацію та підготує документацію для банку.</p>
                    </div>
                </div>
                <div class="step step-5">
                    <div class="num">5</div>
                    <div class="desc">
                        <p class="strong">Ви сплачуєте 10% від вартості покупки.</p>
                        <p class="light">Ви можете використати для цього власні кошти або, в деяких ситуаціях, взяти кредит на перший внесок без застави квартири. Це можливо лише в обраних банках. У такому випадку ви здійснюєте щомісячний платіж лише за використану частину кредиту. Наш фахівець з іпотечного кредитування надасть вам більш детальну інформацію.</p>
                    </div>
                </div>
                <div class="step step-6">
                    <div class="num">6</div>
                    <div class="desc">
                        <p class="strong">Після схвалення кредиту ви заплатите решту 90% від вартості квартири.</p>
                        <p class="light">У більшості випадків ця частина фінансується за рахунок кредиту. Якщо ви вже взяли кредит на перші 10% від вартості покупки, решту 90% ви сплачуєте за рахунок кредиту.
                        </p>
                        <p class="light">В обох випадках наш іпотечний спеціаліст перевірить зміст іпотечного договору та підготує інші необхідні документи (майбутній договір купівлі-продажу, страхування предмету застави, копію страхового договору, рахунок-фактуру...). З цього моменту ви будете здійснювати повний щомісячний платіж відповідно до затвердженого іпотечного договору.</p>
                    </div>
                </div>
                <div class="step step-7">
                    <div class="num">7</div>
                    <div class="desc">
                        <p class="strong">Після сплати 100% від вартості покупки ви підписуєте договір купівлі-продажу, який ми подаємо до земельного кадастру разом із заявою про реєстрацію права власності на нерухомість. Якщо ви придбали квартиру за власні кошти, у вас є можливість відшкодувати їх за допомогою іпотечного кредиту.</p>
                    </div>
                </div>
                <div class="step step-8">
                    <div class="num">8</div>
                    <div class="desc">
                        <p class="strong">Ви отримаєте ключі від свого житла.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-banner">
            <div class="image"><img src="/news/custom-full-banner.jpg"></div>
            <div class="text">
                <h2>Перегляньте актуальну пропозицію квартир</h2><a href="/byty" class="btn">Переглянути пропозицію</a>
            </div>
        </div>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import news from "@/store/news.json"

export default {
  name: "UaFinancovanie",
  components: {ContactForm},
  data() {
    return {
      articleId: this.$route.params.id,
      news: news
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    article() {
      return news.items.find(
        article => article.slug === this.articleId
      )
    }
  },
  watch:{
    $route (to){
      this.articleId = to.params.id
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.full-banner
  margin-bottom: 0 !important
.news-wrapper.detail h2
  font-size: 40px
  line-height: 44px
  font-weight: bold
h1
  font-size: 64px
  line-height: 60px
  letter-spacing: -3px
  font-weight: 900
  max-width: 980px
  @media (max-width: $md)
    max-width: 100%
    font-size: 44px
    line-height: 44px
    letter-spacing: -2px
.category
  font-size: 28px
  line-height: 32px
  letter-spacing: -0.5px
  margin-bottom: 30px
  @media (max-width: $md)
    font-size: 18px
    line-height: 22px
    margin-bottom: 15px
.headline
  height: 550px
  background-size: cover
  background-position: center
  @media (max-width: $md)
    height: 70vh
.news
  max-width: 680px
  color: var(--darkBlue)
  @media (max-width: $md)
    max-width: 100%
    padding: 0 16px
  p, h2
    color: var(--darkBlue)
  h2
    font-size: 28px
    line-height: 32px
    letter-spacing: -0.5px
    margin-bottom: 25px
    font-weight: 400
  p
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 18px
      line-height: 22px
      font-weight: 400
  .figure
    margin: 50px 0
  .figure-caption
    color: var(--grey)
    font-size: 15px
    line-height: 23px
    margin-top: 5px
</style>