<template>
    <svg x="0px" y="0px" :viewBox="map.viewBox">
      <g v-for="(path, i) in map.paths" :key="i">
      <!-- LAYERS-->
        <path v-if="path.isPath"
              :class="path.class ? path.class : 'path'"
              :d="path.points"
              @mouseover="pathEnter" @mouseleave="pathLeave" @mousedown="pathClick"
              :data-map="path.data.mapId"
              :data-route="path.data.route"
        />
        <polygon v-else
                 :class="path.class ? path.class : 'path'"
                 :points="path.points"
                 @mouseover="pathEnter" @mouseleave="pathLeave" @mousedown="pathClick"
                 :data-map="path.data.mapId"
                 :data-route="path.data.route"
        />
      </g>
      <!-- MARKERS-->
      <svg v-for="(m, i) in map.markers" :key="`m${i}`" :class="m.class" class="marker-wrapper"
           :x="calcLeft(m)" :y="calcTop(m)" @mousedown="markerClick" :data-label="m.label">
        <path :d="m.text ? markers.text.path : markers.simple.path"
            :class="m.class ? m.class : 'marker'" />
        <text :x="m.text ? markers.text.width / 2 : markers.simple.width / 2"
              y="22" dominant-baseline="middle" text-anchor="middle" class="label">{{m.label}}</text>
        <text v-if="m.text" :x="m.text ? markers.text.width / 2 : markers.simple.width / 2"
              y="48" dominant-baseline="middle" text-anchor="middle" class="text">{{m.text}}</text>
      </svg>
      <g>
        <slot></slot>
      </g>
    </svg>
</template>

<style lang="sass" scoped>
  .label
    font-family: objektiv-mk2,sans-serif
    font-size: 80%
    font-weight: 900
    fill: white
    pointer-events: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
  .text
    font-family: objektiv-mk2,sans-serif
    font-size: 60%
    letter-spacing: -0.5
    font-weight: 400
    fill: white
    pointer-events: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

</style>

<script>

export default {
  name: "SvgMap",
  props: {
    map: {
      type: [Object]
    },
    pathEnter: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    },
    pathLeave: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    },
    pathClick: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    },
    markerClick: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    }
  },
  data() {
    return {
      markers: {
        simple: {
          path: "M41.83,20.81C41.83,9.32,32.47,0,20.92,0C9.37,0,0,9.32,0,20.81c0,9.39,6.25,17.32,14.84,19.91l5.93,7.94 l5.91-7.86C35.42,38.31,41.83,30.31,41.83,20.81z",
          width: 41.83,
          height: 48.66
        },
        text: {
          path: "M87.43,37.1H58.01c4.83-3.81,7.93-9.69,7.93-16.29C65.94,9.32,56.58,0,45.03,0S24.11,9.32,24.11,20.81 c0,6.61,3.1,12.48,7.93,16.29H2.62C1.17,37.1,0,38.27,0,39.72V55.4c0,1.45,1.17,2.62,2.62,2.62h35.54l6.7,8.26l6.75-8.26h35.82 c1.45,0,2.62-1.17,2.62-2.62V39.72C90.05,38.27,88.88,37.1,87.43,37.1z",
          width: 90.05,
          height: 66.28
        }
      }
    }
  },
  methods: {
    calcLeft(m) {
      return m.text ? m.left - (this.markers.text.width/2) : m.left - (this.markers.simple.width/2)
    },
    calcTop(m) {
      return m.text ? m.top - this.markers.text.height : m.top - this.markers.simple.height
    }
  }
}
</script>

