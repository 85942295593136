<template>
  <div>
    <div class="home d-flex">
      <video playsinline autoplay muted loop poster="../assets/video/bory-video-cover.jpeg" id="bgvid">
        <source src="../assets/video/bory-video.webm" type="video/webm">
        <source src="../assets/video/bory-video.mp4" type="video/mp4">
      </video>
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Bory Nový Dvor</h1>
          <p>Bývajte s vodou priamo pod oknami. Bory Nový Dvor je ideálnym miestom na prechádzky či príjemné posedenie, kým deti sa hrajú na bezpečnom detskom ihrisku. Voliteľný štandard a praktické dispozície sú samozrejmosťou. Bývanie pri vode nemá každý. A vy?
          </p>
          <b-button href="https://borybyvanie.sk/nasa-stvrt" target="_blank" class="d-block d-md-inline-block" squared variant="primary">O bývaní na Boroch</b-button>
          <!--          <b-button class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0" squared variant="light">Stiahnuť brožúru</b-button>-->
        </div>
      </div>
    </div>

    <section class="district p-md-3 pt-3 pt-md-5">
      <div class="d-flex flex-wrap align-items-start top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">Mesto tak, ako ho poznáte</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">Bory kombinujú aktívne rodinné bývanie s bohatým verejným priestorom, kompletnou vybavenosťou, množstvom obchodov a pohodlnou dopravou do centra.</p>
        </div>
      </div>
      <BJazierkoMap/>
    </section>

    <section id="tabulka">
      <FlatsTable :stages="['bory2b']" :showStageAndDate="false" :show-vr="true" :showDate="this.$route.query.stage === 'complete'"></FlatsTable>
    </section>

    <section class="schedule p-3 p-md-5">
      <h2 class="mb-5">Harmonogram výstavby</h2>
      <div class="d-flex flex-wrap">
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3>1. fáza</h3>
          <h3>Začiatok<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2018</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3>1. fáza</h3>
          <h3>Koniec<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2020</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3>1. fáza</h3>
          <h3>Kolaudačný<br> proces</h3>
          <div class="line fill"></div>
          <p class="date">4. kvartál 2020</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3>1. fáza</h3>
          <h3>Odovzdávanie<br> bytov</h3>
          <div class="line fill"></div>
          <p class="date">1-2. kvartál 2021</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3>2. fáza</h3>
          <h3>Začiatok<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">1. kvartál 2021</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3>2. fáza</h3>
          <h3>Koniec<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">2. kvartál 2023</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3>2. fáza</h3>
          <h3>Kolaudačný<br> proces</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2023</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3>2. fáza</h3>
          <h3>Odovzdávanie<br> bytov</h3>
          <div class="line fill"></div>
          <p class="date">4. kvartál 2023</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kluce-byvanie.svg">
          </div>
          <h3>&nbsp;</h3>
          <h3>Vaše<br> bývanie</h3>
          <div class="line fill transparent"></div>
        </div>
      </div>
    </section>

    <div class="sample p-md-3">
      <div class="d-flex flex-wrap">
        <div class="col-md-5 pt-md-4 pl-md-4 text-left">
          <h3>Vzorový byt</h3>
          <p class="big">Vzorový byt ľahko nájdete oproti pošte, na druhom poschodí</p>
          <p class="strong">Nákupné centrum Bory Mall</p>
          <p class="mb-4">2. poschodie</p>
          <p class="strong">Otváracie hodiny</p>
          <p>Pondelok – Nedeľa: 09.00 – 21.00</p>
        </div>
        <div class="col-md-7 m-3 m-md-0 img-bg">

        </div>
      </div>
    </div>

    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 p-0 px-md-5 py-3">
            <h2>Buďte pri tom,<br> čo vás baví</h2>
            <p>Bory Nový Dvor je so svojimi ihriskami a zeleňou vhodné pre rodiny s deťmi aj pre aktívnych mladých ľudí, ktorí majú radi pohyb a chcú mať na blízku obchody, kaviarne a pestrý výber gastronómie. Medzi domami nájdete príjemný zelený priestor a jazierko – miesta ideálne na stretávanie sa so susedmi alebo prechádzky s návštevami.</p>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-1">
              <h3>20 000 m2<br> zelene</h3>
              <p>Beh, cyklistika aj oddych. Veľkorysá plocha zelene, cyklotrasa a multifunkčné ihrisko sa postarajú o to, aby ste vedeli vydýchnuť.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>Jazierko<br> pred domom</h3>
              <p>Súčasťou areálu je jazierko napojené na mólo a zelenú promenádu. V lete sa tu môžete schladiť, dať si kávu alebo sa len tak prechádzať.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>Autom<br> až domov</h3>
              <p>Pohodlné parkovanie v bezprostrednej blízkosti domova zaručí dostatočný počet parkovacích miest a ulice tak zostanú zelené.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-bottom p-md-3 pl-md-5">
      <div class="d-flex flex-wrap">
        <div class="col-md-4 py-5">
          <h2 class="mb-4">Domov, ktorý vydrží</h2>
          <p class="mb-5">Na materiáloch, ktorých sa každý deň dotýkate, záleží. Štandard v bytoch sme nastavili tak, aby nielen dobre vyzeral, ale vydržal generácie. Vybrať si môžete z množstva materiálov, dekorov a povrchových úprav, ktorých kvalita spracovania vás nesklame.</p>
          <!--          <b-button squared variant="primary">Stiahnuť katalóg štandardov</b-button>-->
        </div>
        <div class="col-md-8 pb-3 pb-md-0 pr-md-0 right-img">
          <!-- <div id="standards-slider">
            <div class="novy-dvor">
              <slick
                  ref="slickStandard"
                  :options="slickOptions"
              >
                <div class="slide slide-1">
                  <img src="/standards/novy-dvor/slide-1.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-1" @click="toggleBox('info-1')">
                      <div class="box box-1 p-3" data-box="info-1">
                        <h4>PODLAHY</h4>
                        <p>Predsieň, chodba, obývacia izba, izby, kuchyňa – laminátová podlaha.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2" @click="toggleBox('info-2')">
                      <div class="box box-2 p-3" data-box="info-2">
                        <h4>VSTUPNÉ DVERE DO BYTU</h4>
                        <p>Bezpečnostné, požiarne odolné, bytové dvere v bezpečnostnej triede 3; bezpečnostné kovanie; farba dverí aj zárubní z vnútornej aj vonkajšej strany bytu vo farbe (béžová).</p>
                      </div>
                    </div>
                    <div class="button-toggle info-3" @click="toggleBox('info-3')">
                      <div class="box box-3 p-3" data-box="info-3">
                        <h4>INTERIÉROVÉ DVERE</h4>
                        <p>Podľa typu bytu bezfalcové/polodrážkové dvere, plné dverné krídlo z DTD, povrchová úprava CPL, obložková zárubňa, skryté/priznané závesy, magnetický zámok/klasické kovanie.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-4" @click="toggleBox('info-4')">
                      <div class="box box-4 p-3" data-box="info-4">
                        <h4>VZDUCHOTECHNIKA</h4>
                        <p>Predpríprava vzduchotechnického napojenia kuchynského digestora, nútené vetranie kúpeľne a WC ventilátorom ovládaným samostatným vypínačom s dobehom.<br>
                          Na účely hygienického vetrania izieb pri dodržaní hygienických a akustických noriem sú v okenných konštrukciách inštalované akustické prívodné mriežky. Podtlak zabezpečujú dvojotáčkové ventilátory vo WC a kúpeľni.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-2">
                  <img src="/standards/novy-dvor/slide-2.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-2-1" @click="toggleBox('info-2-1')">
                      <div class="box box-1 p-3" data-box="info-2-1">
                        <h4>VYKUROVANIE A PRÍPRAVA TEPLEJ ÚŽITKOVEJ VODY </h4>
                        <p>Zdrojom tepla na prípravu TÚV pre bytové domy je kotolňa umiestnená v podzemnom podlaží každého obytného bloku. Vykurovanie izieb je zabezpečené doskovými vykurovacími telesami s termoregulačnými hlavicami, v kúpeľni rebríkovým radiátorom.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2-2" @click="toggleBox('info-2-2')">
                      <div class="box box-2 p-3" data-box="info-2-2">
                        <h4>DELIACE MEDZIBYTOVÉ STENY</h4>
                        <p>Železobetónové s hrúbkou 250 mm a 220 mm spĺňajúce požiadavky na zvukovú izoláciu deliacich konštrukcií podľa príslušnej normy STN.</p>
                        <h4>DELIACE INTERIÉROVÉ PRIEČKY</h4>
                        <p>Murované z tehál s hrúbkou 115 mm alebo 140 mm.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2-3" @click="toggleBox('info-2-3')">
                      <div class="box box-3 p-3" data-box="info-2-3">
                        <h4>OKNÁ</h4>
                        <p>Plastové okná s izolačným trojsklom, farba rámu z vnútornej strany biela, z vonkajšej strany vo farbe (béžová), vonkajšie parapety vo farbe okenného rámu, vnútorný parapet bielej farby.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2-4" @click="toggleBox('info-2-4')">
                      <div class="box box-4 p-3" data-box="info-2-4">
                        <h4>TIENENIE</h4>
                        <p>Podľa typu bytu vonkajšie žalúzie alebo predpríprava na vonkajšie žalúzie alebo posuvné perforované tieniace panely.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-3">
                  <img src="/standards/novy-dvor/slide-3.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-3-1" @click="toggleBox('info-3-1')">
                      <div class="box box-1 p-3" data-box="info-3-1">
                        <h4>PODLAHA A OBKLAD</h4>
                        <p>Gresová dlažba a obklady.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-3-2" @click="toggleBox('info-3-2')">
                      <div class="box box-2 p-3" data-box="info-3-2">
                        <h4>UMÝVADLO</h4>
                        <p>Umývadlo bielej farby, umývadlová batéria.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-3-3" @click="toggleBox('info-3-3')">
                      <div class="box box-3 p-3" data-box="info-3-3">
                        <h4>VAŇA</h4>
                        <p>Smaltovaná vaňa s vaňovou batériou <br>a ručnou sprchou.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-4">
                  <img src="/standards/novy-dvor/slide-4.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-4-1" @click="toggleBox('info-4-1')">
                      <div class="box box-1 p-3" data-box="info-4-1">
                        <h4>SPRCHOVY KÚT</h4>
                        <p>Sprchový kút s odtokovým žľabom, sprchová zástena, sprchová batéria <br>a sprchový set.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-5">
                  <img src="/standards/novy-dvor/slide-5.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-5-1" @click="toggleBox('info-5-1')">
                      <div class="box box-1 p-3" data-box="info-5-1">
                        <h4>TOALETA</h4>
                        <p>Závesné rimless WC bielej farby so sedátkom; podomietkový splachovací systém vrátane tlačidla.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-5-2" @click="toggleBox('info-5-2')">
                      <div class="box box-2 p-3" data-box="info-5-2">
                        <h4>UMÝVADLO</h4>
                        <p>Malé umývadlo bielej farby vrátane umývadielkovej batérie.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </slick>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <section class="about-bottom-2">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 bg"></div>
        <div class="col-md-6 signature-section">
          <p class="mb-4">"Cieľom návrhu je priniesť kvalitný verejný priestor s množstvom zelene, doplnený jazierkom, multifunkčným športoviskom a taktiež niekoľkými retailovými priestormi. Ambíciou je vytvoriť novú rezidenčnú štvrť, ktorá bude svojím obsahom vytvárať príjemné prostredie na aktívne trávenie voľného času pre jej obyvateľov."</p>
          <p class="name">Sadovsky&Architects</p>
          <p class="name">Peter Hubinský, Dalibor Špilák, Oliver Sadovský</p>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import FlatsTable from "@/components/FlatsTable";
import BJazierkoMap from "@/components/maps/BJazierkoMap";
import ScrollTo from "@/plugins/scrollTo.util";
import ContactForm from "@/components/ContactForm";
import Slick from "vue-slick";
export default {
  name: "PriJazierku",
  components: {ContactForm, BJazierkoMap, FlatsTable},
  data() {
    return {
      slickOptions: {
        infinite: false,
        adaptiveHeight: true,
        dots: false,
        arrows: true
        // Any other options that can be got from plugin documentation
        // responsive: [
        //   {
        //     breakpoint: 1024,
        //     settings: {
        //       slidesToShow: 2
        //     }
        //   }
        //   // You can unslick at a given breakpoint now by adding:
        //   // settings: "unslick"
        //   // instead of a settings object
        // ]
      },
    }
  },
  mounted() {
    ScrollTo.hash(this,  () => window.scrollTo(0, 0))
  },
  methods: {
    toggleBox(val) {
      console.log(val)
      document.querySelector('.' + val).classList.toggle('clicked')
      document.querySelector('[data-box="' + val + '"]').classList.toggle('show')
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.sample
  background: #576975
  .img-bg
    background: url(../assets/images/about-bottom-new.jpg) bottom/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      order: 1
  .text-left
    padding-bottom: 70px
    @media (max-width: $md)
      order: 2
  h3
    font-size: 18px
    line-height: 20px
    letter-spacing: -0.5px
    font-weight: 500
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 14px
      line-height: 18px
      letter-spacing: -0.25px
  p
    font-weight: 500
    margin: 0
    &.strong
      font-weight: 700
    &.big
      font-size: 55px
      line-height: 60px
      letter-spacing: -3px
      font-weight: 300
      max-width: 500px
      margin-bottom: 80px
      @media (max-width: $md)
        font-size: 40px
        line-height: 40px
        letter-spacing: -2px
        max-width: 100%
        margin-bottom: 30px
#standards-slider
  max-width: 100%
  overflow: hidden
  img
    height: 100%
  .slide
    position: relative
  .novy-dvor
    .slide-1
      .standards-info
        .button-toggle
          &.info-1
            left: 22%
            top: 82%
          &.info-2
            left: 16%
            top: 43%
          &.info-3
            left: 16%
            top: 53%
          &.info-4
            left: 68%
            top: 24%
    .slide-2
      .standards-info
        .button-toggle
          &.info-2-1
            left: 47%
            top: 65%
          &.info-2-2
            left: 92%
            top: 30%
          &.info-2-3
            left: 53%
            top: 48%
          &.info-2-4
            left: 61%
            top: 48%
    .slide-3
      .standards-info
        .button-toggle
          &.info-3-1
            left: 55%
            top: 87%
          &.info-3-2
            left: 25%
            top: 20%
          &.info-3-3
            left: 50%
            top: 20%
    .slide-4
      .standards-info
        .button-toggle
          &.info-4-1
            left: 40%
            top: 40%
    .slide-5
      .standards-info
        .button-toggle
          &.info-5-1
            left: 55%
            top: 50%
          &.info-5-2
            left: 22%
            top: 45%

.novy-dvor
  .slide-1
    .standards-info
      .box
        &.box-1
          top: 0px
          left: 50px
        &.box-2
          top: auto
          bottom: 50px
          left: -120px
        &.box-3
          top: 50px
          left: -120px
          bottom: auto
        &.box-4
          top: 50px
          left: -160px
          bottom: auto
  .slide-2
    .standards-info
      .box
        &.box-1
          right: 50px
          top: -30px
        &.box-2
          right: 50px
          bottom: -60px
        &.box-3
          right: 50px
          bottom: 0px
        &.box-4
          left: 0
          top: 50px
  .slide-3
    .standards-info
      .box
        &.box-1
          left: -160px
          bottom: 50px
        &.box-2
          left: -160px
          top: 50px
        &.box-3
          left: -160px
          top: 50px
  .slide-4
    .standards-info
      .box
        &.box-1
          left: -160px
          top: 50px
  .slide-5
    .standards-info
      .box
        &.box-1
          left: -160px
          top: 50px
        &.box-2
          left: -160px
          top: 50px
.standards-info
  .box
    display: none
    position: absolute
    background: rgba(255, 255, 255, .8)
    width: 370px
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)

    &.show
      display: block

    h4, p
      color: var(--darkBlue)

    h4
      font-size: 16px
      font-weight: bold

    p
      margin-bottom: 0
      font-size: 14px
  .button-toggle
    position: absolute
    width: 44px
    height: 44px
    background: var(--blue)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) rotate(360deg)
    border-radius: 50%
    cursor: pointer
    z-index: 100
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)
    transition: 0.8s cubic-bezier(0.2, 0.6, 0.3, 1.1)

  .button-toggle:after
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 2px
    width: 50%
    background: white

  .button-toggle:before
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 50%
    width: 2px
    background: white

  .button-toggle.clicked
    //transform: translate(-50%, -50%) rotate(180deg)
    background: var(--textDark)

  .button-toggle.clicked:before
    width: 0

video
  object-fit: cover
  width: 100vw
  height: 100vh
  position: absolute
  top: 0
  left: 0
.about-bottom-2
  .bg
    background: url(../assets/images/bory_playground_big.jpg) bottom/cover no-repeat
.about-bottom
  padding-bottom: 11px!important
  .right-img
    min-height: auto
    background: url(../assets/images/bory-jazierko/bory-standard.jpg) center/cover no-repeat
.schedule
  background: var(--textBright)
  .phase
    @media (max-width: $md)
      border-left: 2px solid var(--textDark)
      padding-left: 15px !important
      display: flex
      flex-direction: column
      padding-bottom: 20px !important
      &.fill
        border-color: var(--blue)
        &:before
          background: var(--blue)
      &.fill-half
        &:before
          background: var(--blue)
        &:after
          content: ""
          width: 2px
          height: 60%
          background: var(--blue)
          position: absolute
          left: -2px
          top: 0
      &:last-child
        border-color: transparent
      &:before
        content: ""
        width: 12px
        height: 12px
        background: var(--textDark)
        border-radius: 50%
        position: absolute
        left: -7px
        top: 0
        margin-top: 0
  h3
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.5px
    @media (max-width: $md)
      order: 3
  p.date
    font-size: 16px
    line-height: 20px
    letter-spacing: -0.5px
    color: var(--blue)
    font-weight: 500
    @media (max-width: $md)
      order: 1
      margin-top: -3px
  .icon
    height: 40px
    margin-bottom: 10px
    @media (max-width: $md)
      order: 2
  .line
    margin: 20px 0
    height: 2px
    background: var(--textDark)
    position: relative
    @media (max-width: $md)
      display: none
    &:before
      content: ""
      width: 12px
      height: 12px
      background: var(--textDark)
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      margin-top: -5px
    &.fill
      background: var(--blue)
      &:before
        background: var(--blue)
    &.fill-min
      &:before
        background: var(--blue)
    &.fill-half
      &:before
        background: var(--blue)
      &:after
        content: ""
        width: 50%
        height: 100%
        background: var(--blue)
        position: absolute
        left: 0
        top: 0
    &.transparent
      background: transparent

.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .bg-img
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory_caffe_land.jpg") bottom/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 320px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory_courtyard.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory_lake2.jpg") center/cover no-repeat
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory_caffe.jpg") center/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: url("../assets/images/bory_lake_flip.jpg") bottom/cover no-repeat
  .intro-text
    position: relative
  p
    margin-bottom: 30px
    max-width: 770px

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>
