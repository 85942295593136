import Vue from 'vue'
import Vuex from 'vuex'

import flats from './modules/flats.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    flats
  }
})
