import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import 'slick-carousel/slick/slick.css'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import getCookie from './plugins/cookie-banner/getCookie'
//Styles
import '@/assets/theme/theme.sass'
// import WAnalytics from '@/plugins/analytics/index'
import WAnalytics2 from '@/plugins/analytics2.0/index'
import Hotjar from '@/plugins/hotjar/index'


WAnalytics2.addGoogleAnalytics(router)
WAnalytics2.addGoogleTagManager(router)
Hotjar.addHotjar()


// if(getCookie.getCookie() && getCookie.getCookie().cookie_analytic) {
//   WAnalytics2.addGoogleAnalytics(router)
//   WAnalytics2.addGoogleTagManager(router)
//   Hotjar.addHotjar()
// }

// if(getCookie.getCookie() && getCookie.getCookie().cookies_marketing) {
//   // WAnalytics.addFacebookPixel(router)
// }



Vue.use(BootstrapVue)
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 99999
    }
})

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
