<template>
  <div class="rules-page px-3">
    <div class="container">
      <div class="row">
        <h1 class="mt-md-5 mb-4 mb-md-0 text-center w-100">Pravidlá súťaže „Vyhrajte Darčekovú kartu Bory Mall“</h1>
        <div class="col-12 col-md-6 py-md-5 px-4">
          <div class="rule">
            <h3>1. Usporiadateľ Súťaže:</h3>
            <p>Usporiadateľom Súťaže „Vyhrajte Darčekovú kartu Bory Mall“ (ďalej len „Súťaž") je spoločnosť Penta Real Estate, s.r.o. so sídlom Einsteinova 25, 851 01 Bratislava (ďalej len „Usporiadateľ"). Usporiadateľ vydáva tieto Pravidlá Súťaže (ďalej len "Pravidlá").</p>
          </div>
          <div class="rule">
            <h3>2. Trvanie súťaže:</h3>
            <p>Súťaž sa uskutoční v dňoch od 21. 4. 2021 do 26. 4. 2021 do 15.00.</p>
          </div>
          <div class="rule">
            <h3>3. Účastníci Súťaže:</h3>
            <p>Účastníkom Súťaže sa na účely týchto Pravidiel rozumie fyzická osoba:</p>
            <ul>
              <li>s trvalým pobytom alebo prechodným pobytom na území Slovenskej republiky,</li>
              <li>ktorá v čase zapojenia sa do Súťaže dovŕšila 18. rok života a</li>
              <li>ktorá splní podmienky účasti v Súťaži podľa týchto Pravidiel.</li>
            </ul>
            <p>(ďalej len „Účastník súťaže”)</p>
            <p>Do súťaže sa nemôžu zapojiť zamestnanci Usporiadateľa, ako i spolupracujúce osoby, ktoré sa akokoľvek podieľajú na organizácii tejto Súťaže, vrátane ich zamestnancov.</p>
          </div>
          <div class="rule">
            <h3>4. Zaradenie do Súťaže:</h3>
            <p>Do žrebovania bude zaradený Účastník Súťaže podľa bodu 3 týchto Pravidiel, ktorý napíše do komentára pod príspevok odpoveď na otázku: „Ktorá etapa rezidenčného projektu Bory Bývanie sa vám zatiaľ najviac páči?“ V odpovedi tiež musí byť uvedený dôvod.</p>
            <p>Do Súťaže sa môže každý Účastník súťaže zapojiť viac krát.</p>
            <p>Usporiadateľ vyžrebuje dvoch výhercov.</p>
            <br>
            <p>Úplné znenie statusu:</p>
            <p>Vyhrajte Darčekovú kartu Bory Mall 🎁</p>
            <p>Ktorá etapa rezidenčného projektu Bory Bývanie sa vám zatiaľ najviac páči? Napíšte nám to do komentára spolu s dôvodom prečo. Nezabudnite etapu správne pomenovať ✅</p>
            <p>Zo všetkých komentárov vyžrebujeme v pondelok 26. 4. 2021 dvoch z vás, ktorí získajú Darčekovú kartu Bory Mall v hodnote 50 eur.</p>
            <p>V nákupnom centre Bory Mall vás čakajú kvalitné nákupy a až 190 obchodov, služieb, reštaurácií a kaviarní. Bory Mall sa nachádza v pešej dostupnosti projektu Bory Bývanie a je jedným z hlavných benefitov bývania v lokalite Bory 🏘</p>
            <p>Úplné znenie pravidiel súťaže nájdete na <a href="https://www.bit.ly/bory-byvanie-pravidla-sutaze" target="_blank">www.bit.ly/bory-byvanie-pravidla-sutaze</a></p>
          </div>
          <div class="rule">
            <h3>5. Žrebovanie:</h3>
            <p>Usporiadateľ po skončení Súťaže vyžrebuje dňa 26. 4.  2021 zo všetkých Účastníkov Súťaže, ktorí spĺňajú Pravidlá Súťaže, 2 výhercov.</p>
          </div>
          <div class="rule">
            <h3>6. Výhra:</h3>
            <p>Usporiadateľ odovzdá každému z dvoch výhercov nasledovnú výhru:</p>
            <p>1x Darčeková karta Bory Mall v hodnote 50 eur</p>
          </div>
          <div class="rule">
            <h3>7. Oznámenie výhry:</h3>
            <p>Výherca bude vyzvaný, aby Usporiadateľovi zaslal svoje kontaktné údaje do súkromnej fb správy.</p>
            <p>V prípade, že sa nepodarí s Výhercom skontaktovať do 7 dní od uskutočnenia žrebovania, bude vyžrebovaný nový Výherca.</p>
          </div>
        </div>
        <div class="col-12 col-md-6 py-md-5 px-4">
          <div class="rule">
            <h3>8. Osobné údaje:</h3>
            <p>Uverejnením komentára pod príspevkom udeľuje Účastník súťaže Usporiadateľovi v súlade s Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) dobrovoľný súhlas so spracovaním osobných údajov v rozsahu: e-mailová adresa, meno, priezvisko, telefónne číslo a adresa za účelom zaslania výhry. Uvedený súhlas sa udeľuje na dobu jedného mesiaca odo dňa začiatku súťaže. Udelený súhlas môže účastník súťaže kedykoľvek odvolať a to, e-mailom alebo písomne na kontaktné údaje uvedené v hypertextovom odkaze nižšie.</p>
            <p>Informácie ohľadom spracúvania osobných údajov, predovšetkým kontaktné údaje na prevádzkovateľa a zodpovednú osobu, ako aj práva účastníka súťaže ako dotknutej osoby, v súlade s Nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) sú prístupné na www.borybyvanie.sk .</p>
          </div>
          <div class="rule">
            <h3>9. Dane:</h3>
            <p>Výherca Súťaže nesie všetky riziká a záväzky súvisiace s výhrou a jej využitím. V zmysle zákona č. 595/2003 Z. z. o dani z príjmov v znení neskorších predpisov je Výherca povinný pri prijatí nepeňažnej výhry alebo ceny, ktorej hodnota prevyšuje sumu 350,- Eur uhradiť daň z príjmov.</p>
          </div>
          <div class="rule">
            <h3>10. Osobitné ustanovenia:</h3>
            <p>Usporiadateľ si vyhradzuje právo na zmenu Pravidiel Súťaže, na zrušenie Súťaže, zmenu dĺžky trvania ako aj podmienky zaradenia do Súťaže, najmä, ale nie výlučne, pravidlá žrebovania, dátum uskutočnenia žrebovania, počet výhercov, ako aj druhy výhier, ktoré budú predmetom žrebovania. Zmenu pravidiel a podmienok zaradenia do Súťaže Usporiadateľ vhodným spôsobom zverejní na facebookovej stránke. Usporiadateľ nezodpovedá za žiadne škody vzniknuté v súvislosti s nesprávnymi údajmi poskytnutými Účastníkmi Súťaže, ako i v súvislosti s neuplatnením, resp. nevyužitím výhry. Výherca nemá právny nárok na výmenu výhry alebo výplatu jej hodnoty formou peňažnej alebo inej kompenzácie.</p>
            <p>Účastník Súťaže berie na vedomie, že výhru nie je možné vymáhať súdnou cestou. V prípade akéhokoľvek sporu týkajúceho sa Súťaže, bude rozhodnutie Usporiadateľa konečné a záväzné. Účastníci Súťaže sa môžu s týmito Pravidlami oboznámiť na webovej adrese Súťaže.</p>
            <p>Usporiadateľ si vyhradzuje právo vyradiť zo súťaže Účastníka Súťaže, ak je obsah jeho súťažného príspevku vulgárny, urážajúci ľudskú dôstojnosť alebo odporujúci dobrým mravom a tento príspevok zmazať. Usporiadateľ si vyhradzuje právo bez udania dôvodu vylúčiť Účastníka Súťaže v prípade, že jeho správanie či profil na sociálnej sieti Facebook vykazuje známky nekalého, resp. podvodného konania, napr. duplicitný Facebook účet.</p>
            <p>Zapojením sa do Súťaže vyjadrujú Účastníci Súťaže svoj súhlas spravovať sa týmito Pravidlami. Usporiadateľ si vyhradzuje právo konečného rozhodnutia, či boli splnené podmienky pre výhru v zmysle Pravidiel.</p>
            <p>Usporiadateľ Súťaže nie je zodpovedný za nefunkčnosť siete, ako i iné technické či programové chyby a omyly vzniknuté počas Súťaže.</p>
            <p class="mt-5">V Bratislave, 21. 4. 2021</p>
          </div>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  name: "KartaBoryMall",
  components: {ContactForm},
}
</script>

<style lang="sass" scoped>
.rules-page
  padding-top: 85px
  background: #2D323A

  h1
    @media (max-width: 768px)
      font-size: 30px

  .rule
    margin-bottom: 40px

    h3
      font-size: 22px

    p
      font-size: 16px
      font-weight: 400
</style>