<template>
  <div>
    <b-navbar id="navBar" fixed="top" toggleable="lg" class="py-md-0 px-md-5 bory-navbar navbarbg" type="dark">
      <b-navbar-brand to="/" class="d-flex align-items-center p-0 mr-md-5">
        <div class="logo mr-3">
          <img src="../assets/images/bory-logo.svg" class="d-inline-block align-top" alt="Bory byvanie">
        </div>
        <div class="logo-text">Nákupná zóna</div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">Menu</b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="scrollTo('retailmap')">Mapa</b-nav-item>
          <b-nav-item @click="scrollTo('retail-footer')">Kontakt</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto d-flex align-items-center">
          <b-button to="/" squared variant="primary" class="border-0">Späť na Bory Bývanie</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>

export default {
  methods: {
    scrollTo(id) {
      const map = document.getElementById(id)
      map.scrollIntoView({behavior: "smooth", block: 'center'})

    },
    visible(event) {
      this.sidebarVisible = event
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    navToFlatList() {
      this.$router.push({
        name: 'Flats',
        hash: '#tabulka',
        query: { filter: JSON.stringify(this.flatsFilter) }
      })
      this.flatsFilter = {
        stages: [],
        dates: [],
        buildings: [],
        rooms: [],
        floors: [],
        availability: 'all',
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.bory-navbar
  @media (max-width: $md)
    padding-top: 8px
    padding-bottom: 12px
    background: rgba(33,37,43,0.9)
    .logo
      max-width: 62px
      position: absolute
      top: 0
    .logo-text
      padding-left: 75px
.navbarbg
  background: rgba(33,37,43,0.9)
.sidebar-button
  position: fixed
  right: -61px
  top: 50%
  margin-top: -23px
  background: rgba(33,37,43,0.9)
  padding: 10px 15px
  transform: rotate(90deg)
  outline: none
  z-index: 9999
  img
    position: absolute
    transform: rotate(90deg) translateY(25%)
    left: 50%
    bottom: -15px
  &:before
    content: ""
    border-bottom-left-radius: 50px
    border-bottom-right-radius: 50px
    height: 27px
    width: 50px
    position: absolute
    background: rgba(33,37,43,0.9)
    left: 50%
    margin-left: -25px
    bottom: 0
    margin-bottom: -27px
    z-index: -2
  a
    color: #fff
    font-size: 14px
    font-weight: 500
    text-decoration: none
.sidebar
  position: relative
  .close-sidebar
    position: absolute
    width: 50px
    height: 50px
    left: -25px
    top: 50%
    margin-top: -25px
    border-radius: 50%
    z-index: 9999
    display: flex
    align-items: center
    justify-content: center
    outline: none
    @media (max-width: $md)
      left: 0
    &:before
      content: ""
      border-bottom-left-radius: 50px
      border-top-left-radius: 50px
      height: 50px
      width: 27px
      position: absolute
      background: rgba(33,37,43,0.9)
      left: -2px
      top: 50%
      margin-top: -25px
      z-index: -2
      @media (max-width: $md)
        background: transparent
  .btn
    font-weight: 500
  .btn-outline-primary
    color: #fff
  h3
    font-size: 20px
    font-weight: 500
    margin-bottom: 26px
    margin-top: 30px
  .project
    position: relative
    .detail-link
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
    p
      margin: 15px 0 20px
      font-weight: 500
  img
    max-width: 100%
.navbar-dark
  z-index: 9999
  .navbar-brand
    font-weight: 800
    font-size: 32px
    letter-spacing: -1.5px
    @media (max-width: $md)
      font-size: 26px
      line-height: 27px
      letter-spacing: -1px
  .navbar-nav
    .nav-link
      color: #fff
      font-weight: bold
    .router-link-active
      border-bottom: 1px solid #fff
  .search
    color: #fff
    font-size: 18px
    font-weight: 500
    margin-right: 38px
    img
      margin-right: 10px
  .side-menu-top
    margin-left: 36px
    cursor: pointer
</style>
