<template>
  <div id="default-layout">
    <HeaderRetailZone/>
    <router-view/>
    <FooterRetailZone/>
  </div>
</template>

<script>
import HeaderRetailZone from "@/components/HeaderRetailZone";
import FooterRetailZone from "@/components/FooterRetailZone";
export default {
  components: {HeaderRetailZone, FooterRetailZone}
}
</script>