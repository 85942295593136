<template>
  <div class="rules-page px-3">
    <div class="container">
      <div class="row">
        <h1 class="mt-md-5 mb-4 mb-md-0 text-center w-100">Stanovisko spoločnosti Bory, a.s. k&nbsp;regulovanej cene plynu</h1>
        <div class="col-12 col-md-8 py-md-5 px-4 mx-auto">
          <div class="rule">
            <p>Spoločnosť Bory, a.s. ďalej len „Bory“, si touto cestou dovoľuje oboznámiť s&nbsp;dôvodmi prečo do dnešného dňa neboli domácnosti nachádzajúce sa v&nbsp;lokalite Bory (ďalej len „Bory zóne“) zaradené do okruhu zraniteľných odberateľov, ktorým prináleží právo odberu zemného plynu od spoločnosti Slovenský plynárenský priemysel, a.s. (SPP) za cenu regulovanú Úradom pre reguláciu sieťových odvetví.</p>
            <p>Dňa 28.04.2022 zaslali zástupcovia spoločnosti Bory <strong>Žiadosť o&nbsp;regulovanú cenu plynu pre odberateľov</strong> v&nbsp;Bory zóne spoločnosti Slovenský plynárensky priemysel, a.s. (ďalej len „SPP“). Spoločnosť Bory odoberá od spoločnosti SPP plyn na základe zmluvy a&nbsp;následne odobraný plyn ďalej dodáva samostatným tretím stranám (bytovým domom a&nbsp;iným subjektom), ktoré plyn spotrebúvajú. Pre vyvrátenie akýchkoľvek pochybnosti spoločnosť Bory dodáva, že  plyn dodaný tretím stranám je fakturovaný týmto stranám za tie isté ceny za aké ho odoberá spoločnosť Bory od spoločnosti SPP. Dodaný plyn tak nepredstavuje pre našu spoločnosť obchodovateľnú komoditu a&nbsp;na jeho dodávke netvoríme žiaden zisk. <br> Dňa 08.07.2022 obdržali zástupcovia spoločnosti Bory stanovisko od spoločnosti SPP k&nbsp;spomínanej žiadosti a&nbsp;to, že <strong>nepriznáva nárok</strong> na uplatňovanie regulovaných cien pre koncových spotrebiteľov zemného plynu z&nbsp;nasledujúceho dôvodu: </p>
            <p class="font-italic">Podružné meradlá pre meranie spotreby zemného plynu nie je možné akceptovať pre účely vyúčtovania dodávky plynu, tieto meradlá nie je možné používať na obchodné účely. Príslušné meradlo musí byť určeným meradlom vo vlastníctve prevádzkovateľa distribučnej siete (PDS) v zmysle príslušných ustanovení ZoE a ZoR.</p>
            <p>Z&nbsp;uvedeného vyplýva, že spoločnosť SPP vníma ako problém samotnú skutočnosť, že je spoločnosť Bory jediným vlastníkom distribučnej siete naprieč celou Bory zónou a&nbsp;že z&nbsp;tohto dôvodu nie je možné pripojenie podružných meradiel, pre meranie spotreby plynu, ktoré by boli vo vlastníctve SPP – distribúcia. </p>
            <p>Od momentu nepriznania nároku na regulovanú cenu zemného plynu vyvinula spoločnosť Bory mnohé právne aj administratívne kroky, ktorými sa snažila zvrátiť toto stanovisko. Okrem iného sme navrhli zmenu podružných meradiel pre meranie spotreby zemného plynu napriek tomu, že aktuálne namontované meradla spĺňajú všetky predpísané normy a&nbsp;štandardy. Zahájili sme rokovania o&nbsp;dodatkovaní aktuálne platných zmlúv, medzi spoločnosťou Bory, SPP a&nbsp;SPP – distribúcia, a.s. a&nbsp;tiež rozhovory o&nbsp;možných spôsoboch zmien dodávky zemného plynu do Bory zóny. </p>
            <p>V&nbsp;januári tohto roku nás Ministerstvo hospodárstva vyzvalo na predloženie podkladov potrebných na zaradenie do zoznamu dodávateľov, kvôli dotáciám na krytie dodatočných nákladov v&nbsp;dôsledku zvýšenia cien energií. Zaradenie do takéhoto zoznamu by mohlo pre obyvateľov Bory zóny znamenať aspoň čiastočne zníženie ceny za plyn z&nbsp;dôvodu preplatenia časti zvýšenej ceny. </p>
            <p>I keď spoločnosť Bory nie je dodávateľom energií podľa zákona č. 251/2012 Z.z. o&nbsp;energetike podarilo sa jej zástupcom presvedčiť ministerstvo o&nbsp;naliehavosti verejného záujmu a o&nbsp;potrebe zváženia zaradenia spoločnosti do zoznamu dodávateľov. Na výzvu sme reagovali a&nbsp;ministerstvu predložili všetky potrebné informácie a&nbsp;momentálne čakáme na rozhodnutie o&nbsp;zaradení či nezaradení do zoznamu dodávateľov. </p>
            <p>Týmto by sme chceli ubezpečiť každého, kto má podľa platných právnych aktov nárok na regulovanú cenu plynu, že spoločnosť Bory naďalej aktívne komunikuje s&nbsp;každým subjektom dotknutým problematikou. Medzi prebiehajúce rozhovory sme si dovolili zapojiť aj samotné Ministerstvo hospodárstva nakoľko je problém s&nbsp;nezaradením bytových domov medzi zraniteľných odberateľov rozšírený na celom Slovensku a&nbsp;klienti našej spoločnosti nie sú jedinými subjektami, ktorým bol tento status neprávom odmietnutý.</p>
            <p>Okrem prebiehajúcich rokovaní o&nbsp;prehodnotenie a&nbsp;zmenu zamietajúceho stanoviska na Žiadosť o&nbsp;regulovanú cenu plynu zvažuje naša spoločnosť aj iné právne a technické možnosti vyriešenia situácie. Tak ako sme už uviedli spoločnosť Bory netvorí na dodávke plynu žiaden zisk a&nbsp;tak  nie je v&nbsp;našom záujme akokoľvek ukracovať obyvateľov Bory zóny o&nbsp;ich práva a&nbsp;chránené záujmy. Tvorba zisku distribúciou zemného plynu nebola od začiatku spustenia Bory zóny našim záujmom či zámerom o&nbsp;čom svedčí aj fakt, že spoločnosť Bory nedisponuje opravením na podnikanie v&nbsp;energetike a&nbsp;nikdy neiniciovala získanie takého povolenia. Nakoľko nespĺňame podmienky podľa zákona č. 251/2012 Z.z. o&nbsp;energetike, na získanie oprávnenia pre podnikanie v&nbsp;energetike nie je zo zákona možné, aby spoločnosť Bory fakturovala koncovým spotrebiteľom iné ceny za plyn ako sú jej vystavené na  faktúre zo strany SPP.</p>
            <p>Týmto by sme chceli obyvateľov ubezpečiť o&nbsp;tom, že pokiaľ neprinesú prebiehajúce rokovania a&nbsp;snahy o&nbsp;zníženie ceny plynu  žiaden výsledok, v&nbsp;dohľadnej dobe podnikne spoločnosť právne kroky, ktorými sa bude snažiť domôcť práv svojich klientov. Veríme, že sa celá situácia čo najskôr vyrieši bez ohľadu na to či bude riešením úspešne vyrokovanie zmeny stanoviska &nbsp;SPP, zaujať právne kroky smerujúce na vymoženie práv klientov Bory zóny alebo technické zabezpečenie iného spôsobu dodávky zemného plynu. O&nbsp;konečnom riešení Vás budeme bezodkladne informovať.  </p>
          </div>
        </div>
      </div>
    </div>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  name: "KartaBoryMall",
  components: {ContactForm},
}
</script>

<style lang="sass" scoped>
.rules-page
  padding-top: 85px
  background: #2D323A

  h1
    @media (max-width: 768px)
      font-size: 30px

  .rule
    margin-bottom: 40px

    h3
      font-size: 22px

    p
      font-size: 16px
      font-weight: 400
</style>