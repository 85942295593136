class ScrollTo {

  private duration = 750

  public hash($this, fnElse) {
    const hashes = window.location.hash.split('#')
    if (hashes.length > 1) {

      const target = document.getElementById(hashes[1])
      const navBar = document.getElementById('navBar')
      if (target && navBar) {
        $this.$smoothScroll({
          duration: this.duration,
          scrollTo: target,
          offset: - navBar.clientHeight,
          easingFunction: this.easeOutExpo,
          updateHistory: false
        })
      }


    } else {
      fnElse()
    }
  }
  public id($this, sId) {
    const target = document.getElementById(sId)
    const navBar = document.getElementById('navBar')
    if (target && navBar)
      $this.$smoothScroll({
        duration: this.duration,
        scrollTo: target,
        offset: - navBar.clientHeight,
        easingFunction: this.easeOutExpo,
        updateHistory: false
      })
  }

  private easeOutExpo(x: number): number {
    return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
  }
}

export default new ScrollTo()
