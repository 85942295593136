<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Nakupovanie <br>a obchod</h1>
          <p>Bory Retail Zone je sedemdesiathektárová nákupná zóna, v ktorej <br>postupne vznikajú veľkokapacitné
            obchodné prevádzky s rozličnou <br>ponukou tovarov a služieb.</p>
          <b-button @click="scrollToMap" class="d-block d-md-inline-block" squared variant="primary">Pozrieť mapu</b-button>
        </div>
      </div>
    </div>
    <div class="retail-zone-map" id="retailmap">
      <div class="hand-icon">
        <img src="../assets/icons/hand-icon.svg" alt="">
      </div>
      <div class="zone-menu">
        <div @click="show('ready')" class="link ready active">V prevádzke</div>
        <div @click="show('preparing')" class="link preparing">V príprave</div>
        <div @click="show('free')" class="link free">Voľné</div>
      </div>
      <div class="map">
        <div class="overlay">
          <div class="pins pins-ready active"></div>
          <div class="pins pins-free"></div>
          <div class="pins pins-preparing"></div>
          <svg version="1.1" id="BH4B" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px"
              width="2800px" height="1273px" viewBox="0 0 2800 1273" style="enable-background:new 0 0 1351.48 1163.23;" xml:space="preserve">
                      <g id="mapa_x5F_2">
                      <!--          V PREVADZKE            -->
                        <g class="object object-ready active">
                          <g @click="showModal('ubytovanie-sestry')">
                              <polygon class="st17" points="784.24,649.71 811.96,639.53 828.58,679.51 801.5,690.73 		"/>

                                                  <rect x="800.33" y="661.08" transform="matrix(0.9273 -0.3742 0.3742 0.9273 -192.5728 349.4869)"
                                                        class="st19" width="6.73" height="19.13"/>
                          </g>
                          <g @click="showModal('nemocnica')">
        <polygon class="st17"
                points="811.96,639.53 878.75,613.21 889.32,614.31 932.7,677.72 843.08,713.25 828.58,679.51 		"/>
                            <polygon class="st19" points="828.63,649.68 851.64,706.82 877.51,696.47 876.3,692.62 888.63,687.77 889.62,688.77
                    897.11,686.01 881.92,648.25 885.88,646.71 883.24,640.88 879.61,642.53 873.33,628.04 847.57,637.9 847.02,640.11 		"/>
    </g>
                          <g @click="showModal('kaufland')">
        <path class="st17" d="M274.13,581.94l63.85,163.16c0,0,9.03-5.06,13.65,0.22l40.51-37.87l-16.07-18.72l1.76-4.18l-1.98-3.3
                    l-3.3,1.98l-2.64-2.42l-2.2,1.98l-0.22,4.84l-25.76-50.42l-9.47-20.26l-14.97-2.86l-15.85-43.6L274.13,581.94z"/>
                            <polygon class="st19" points="299.1,619.34 304.38,617.8 307.73,625.94 314.73,622.86 313.41,620.44 319.36,617.8 320.68,620.66
                    328.38,617.8 337.85,642.46 340.93,641.14 349.25,663.4 321.17,674.57 		"/>

                            <rect x="363.87" y="706.18" transform="matrix(0.7071 0.7071 -0.7071 0.7071 613.775 -50.3772)" class="st19"
                                  width="7.66" height="19.05"/>
    </g>
                      <g @click="showModal('mobelix')">
        <path class="st17" d="M839.19,859.63l-23.63-58.08l-72.44,31.13l39.27,38.24l4.65-0.97l12.88,12.22l15.63-13.26h18.94
                    C834.49,868.91,832.05,863.52,839.19,859.63z"/>
                            <polygon class="st19" points="763.74,826.18 794.73,814.36 813.03,858.51 795.24,866.1 777.44,860.6 		"/>
    </g>
                          <g @click="showModal('ptacek')">
                  <path class="st17" d="M640.36,815.23l-48.82-21.37c0,0-2.78,8.57-14.56,4.8l-6.78,17.68l16.33,11.04l47.9,22.87l9.42-21.29
                    C643.86,828.96,636.03,825.75,640.36,815.23z"/>
                            <polygon class="st19" points="596.23,806.16 589.03,819.66 591.87,821.47 589.42,826.25 597.94,830.25 599.49,828.06
                    621.58,839.55 631.13,822.37 617.7,814.76 616.28,816.05 		"/>
                </g>
                          <g @click="showModal('hornbach')">
                  <path class="st17" d="M350.75,745.54l68.48-64.07c0,0,8.81,7.05,16.07-1.76l57.91,43.82c0,0-7.49,22.46,15.19,13.21l24.44,18.05
                    l0.22,9.47l-5.5,5.28l0.44,2.86l-22.24,44.48l4.4,1.54l-2.2,11.89L403.6,799.48l-52.18-39.41
                    C351.41,760.07,359.34,752.37,350.75,745.54z"/>
                            <polygon class="st19" points="393.45,725.29 381.09,736.63 375.61,754.92 380.63,756.36 378.59,759.76 384.53,761.77
                    386.58,759.33 411.28,768.8 408.31,778.03 463.59,798.41 467.62,786.63 470.58,787.73 473.83,778.13 470.58,776.74 474.55,767.49
                    477.85,768.93 479.62,764.14 476.03,762.74 477.31,759.6 452.82,750.96 453.82,748.54 432.1,740.02 		"/>
                </g>
                          <g @click="showModal('shell')">
                  <polygon class="st17" points="540.25,841.04 548.59,821.84 563.4,818.65 568.73,829.72 578.73,836.09 584.12,843.1 582.98,854.14
                        "/>
                            <polygon class="st19" points="546.68,821.08 551.58,823.02 548.74,828.96 544.09,827.28 		"/>
                </g>
                          <g @click="showModal('mobydick')">
                  <polygon class="st17"
                          points="585.81,827.03 570.21,816.34 563.4,818.65 568.73,829.72 578.73,836.09 584.12,843.1 		"/>
                            <polygon class="st19" points="570.55,823.17 568.12,826.91 577.48,832.64 580.12,828.78 		"/>
                </g>
                          <g @click="showModal('hyundai')">
                  <path class="st17" d="M867.89,909.36l-2.14-11.01l-18.3-26.1c0,0-7.7,5.87-13.82-3.33h-18.07L800,880.94l18.8,17.9l37.55,9.2
                    L867.89,909.36z"/>
                            <polygon class="st19" points="822.82,879.04 844.78,886.14 842.8,894.73 831.9,891.09 830.91,895.06 813.08,889.28 815.39,881.52
                    821.83,883 		"/>
                </g>
                <g @click="showModal('siko')">
                    <path class="st17" d="M657.57,825.6l26.76,16.54l10.32,15.24c0,0-16.42,11.71-13.29,33.77l-33.97-1.07l-22.51-11.8l19.73-49.31
                                C644.61,828.96,647.65,831.79,657.57,825.6z"/>
                                        <path class="st19" d="M648.19,845.22c0.46-0.66,5.96-12.55,5.96-12.55l18.58,10.78c2.88,1.67,4.81,4.6,5.22,7.9l0.93,7.63
                                L648.19,845.22z"/>
                                        <path class="st19" d="M635.52,858.4l30.86,14.46c0,0,4.78,7.62-4.26,9.94c-9.04,2.32-10.98-2.71-10.98-2.71l-9.3-3.87h-4.26
                                l-8.26-4.52L635.52,858.4z"/>
                </g>
                          <g @click="showModal('borymall')">
                  <path class="st17" d="M792.33,731.84l7.76-5.37l1.65,2.09l13.82-4.41l117.14-46.43l37.16,52.35c0,0-6.33,3.2-6.83,6.26
                    c-0.5,3.07,0.88,18.01,14.92,11.41l58.62,66.22c0,0,17.56,26.02,13.07,45.95c-4.48,19.94-12.61,34.59-18.79,42.54
                    c0,0-30.19-14.02-32.14,18.02l0.5,2.93l-63.85,7.6l-72.08-11.54l4.93-11.62l-20.76-35.6c0,0,7.9-12.86-7.18-12.61l-46.18-112.75
                    C794.09,746.88,803.23,737.91,792.33,731.84z"/>
                            <path class="st19" d="M999.68,888.12c-0.33,0.11-10.9,7.6-10.9,7.6l-0.99-1.43l-14.86,10.79l-12.99,4.4l-8.7-18.17l9.47-11.23
                    l-2.09-4.18l-2.12-3.49l-11.98,3.82l-1.76-3.41l-17.83,7.38l-2.64-12.99l1.54-1.98l-4.07-5.06l-2.42,0.44l-18.83-19.49
                    l-14.86-36.22l2.2-1.98l-2.09-5.06l-2.64,0.55l-13.65-34.9l51.74-20.92l15.19,36.88l29.5-0.22l48,59.67l-29.39,20.37"/>
                </g>
                          <g @click="showModal('merkurymarket')">
                  <path class="st17" d="M455.56,695.56l36.55,28.57c0,0,11.64-5.54,14.49-2.52l3.65,3.06l9.92-16.27l4.83,0.36l1.74-3.03l-3.63-3.67
                    l40.55-47.03l-41.39-39.63l-34.77,41.05l-17.35,20.78L455.56,695.56z"/>
                            <polygon class="st19" points="519.48,697.52 488,667.14 509.48,645.76 539.98,674.67 		"/>
                </g>
                          <g @click="showModal('volvo')">
                  <path class="st17" d="M1030.85,902.45c0,0,7.76,5.48,7.42,15.44l70.12-2.37l-3.08-31.05l-12.77-19.22h-5.72l-14.37-12.27
                    l-22.31-2.86C1050.14,850.13,1052.17,875.3,1030.85,902.45z"/>
                            <polygon class="st19" points="1076.09,881.52 1076.09,885.04 1061.11,884.93 1060.89,882.18 1052.64,882.4 1045.72,890.27
                    1046.47,906.4 1071.02,905.3 1071.31,897.04 1076.53,897.04 1076.86,901.44 1088.75,901.44 1088.64,894.4 1086.33,894.4
                    1085.99,881.52 		"/>
                            <polygon class="st19" points="1065.63,868.75 1072.01,868.75 1072.45,875.13 1065.63,875.02 		"/>
                </g>
                          <g @click="showModal('metro')">
                  <polygon class="st17" points="174.72,328.62 213.03,429.03 229.21,435.3 270.49,418.46 272.15,410.2 276.77,405.91 268.91,386.82
                    309.47,369.25 313.19,283.12 300.33,282.49 193.31,321.92 		"/>
                            <polyline class="st19" points="232.22,324.9 251.45,317.08 269.81,361.45 249.92,370.03 250.14,372.02 240.45,376.64
                    239.35,374.22 215.12,383.96 209.5,372.4 206.69,373.39 204.88,368.43 208.35,367.11 203.02,352.42 196.63,355.06 190.25,337.23
                    199.05,333.48 201.48,337.23 226.47,327.21 225.44,325.49 231.34,323.01 		"/>

                            <rect x="268.68" y="308.82" transform="matrix(0.927 -0.375 0.375 0.927 -97.2662 125.747)" class="st21"
                                  width="11.48" height="7.87"/>
                </g>
                          <g @click="showModal('decathlon')">
                  <path class="st17" d="M589.21,781.43l46.24-54l92.49,93.65l-1.99,21.18l-5.02,17.47c0,0-18.11-7.74-26.69-1.57l-10.59-16.67
                    l-27.02-16.47c0,0,5.47-4.17,0.18-9.79c-5.28-5.62-14.17-6.49-16.44,0l-48.82-21.37C591.55,793.86,594.05,785.39,589.21,781.43z"
                  />
                            <polygon class="st19" points="631.23,753.79 626.44,758.16 624.26,756.39 616.76,763.05 619.05,765.75 616.97,767.31 634.14,785
                    641.13,777.61 643.11,779.97 652.46,770.85 638.1,756.07 635.7,758.36 		"/>
                </g>
                        </g>
                        <!--            V PRIPRAVE          -->
                        <g class="object object-preparing">

                          <g @click="showModal('xxxlutz')">
                            <polygon class="st17" points="895.92,603.74 985.54,568.51 1049.95,653.06 962.52,668.26 962.52,670.64 947.67,681.69 894.6,606.38 	"/>
    </g>
                          
                          <g @click="showModal('segum')">
        <path class="st17" d="M455.56,695.56l-20.26-15.85c0,0,3.08-8.15,0.66-11.23l20.7-20.92l20.48,21.14L455.56,695.56z"/>

                            <rect x="443.92" y="670.8" transform="matrix(0.774 0.6332 -0.6332 0.774 531.5082 -134.6923)" class="st19"
                                  width="21.05" height="12.87"/>
    </g>
                          <g @click="showModal('asko')">
        <path class="st17" d="M508.46,736.02l64.9,48.05c0,0,8.75-7.76,15.85-2.64l46.24-54l-71.78-72.39l-31.27,34.24l-9.28,12.79
                    l3.63,3.67l-1.74,3.03l-4.83-0.36l-9.92,16.27C510.25,724.67,509.1,735.4,508.46,736.02z"/>
                            <path class="st19" d="M519.17,714.61l35.2-36.22c0,0,3.8-3.67,8.36,0.89s52.68,50.4,52.68,50.4s3.55,2.41,0.89,4.69
                    c-2.66,2.28-20.26,21.15-20.26,21.15l-3.42-2.91l1.27-1.27l-11.02-10l-2.03,1.65l-14.56-13.8l2.03-2.15l-11.14-10.13l-1.39,1.65
                    l-3.04-3.04l-16.84,17.35c0,0-3.04,5.57-8.23,0.89S510.94,721.44,519.17,714.61z"/>
    </g>
                          <g @click="showModal('pavilon')">
        <polygon class="st17"
                points="659.89,641.06 668.31,664.22 676.02,682.35 676.73,693.58 652.33,707.51 623.72,679.87 		"/>
                            <polygon class="st19" points="662.71,669.45 656.86,663.64 653.34,667.06 651.35,665.3 635.83,680.93 644.35,689.27
                    652.01,681.26 648.16,677.19 650.14,675.53 655.43,680.71 661.48,674.76 659.39,672.67 		"/>
    </g>
                          <g @click="showModal('office')">
        <polygon class="st17" points="815.56,724.16 843.08,713.25 828.58,679.51 801.5,690.73 		"/>

                            <rect x="815.17" y="687.17" transform="matrix(0.9273 -0.3742 0.3742 0.9273 -201.1313 357.5864)"
                                  class="st19" width="10.2" height="19.13"/>
    </g>
                          <g @click="showModal('bauland')">
        <polygon class="st17" points="585.81,826.2 635.18,850.25 624.89,878.27 582.81,855.82 		"/>
                            <polygon class="st19" points="585.81,849.11 593.3,835.16 597.04,836.84 590.07,851.04 		"/>
                            <polygon class="st19" points="599.49,839.16 597.43,845.23 600.01,846.27 600.53,844.33 602.72,845.23 604.01,842.39
                    601.43,841.62 602.21,840.32 		"/>
                            <polygon class="st19" points="596.39,847.56 592.91,851.69 596.52,854.14 600.01,849.36 		"/>
                            <polygon class="st19" points="606.34,842.26 603.76,846.91 607.5,848.98 610.34,844.84 		"/>
                            <polygon class="st19" points="601.43,851.04 598.98,855.43 602.59,857.24 605.56,852.98 		"/>
                            <polygon class="st19" points="613.05,845.23 610.34,849.88 613.57,851.56 616.41,847.3 		"/>
                            <polygon class="st19" points="618.61,848.46 615.51,853.24 618.74,855.82 622.35,851.17 		"/>
                            <polygon class="st19" points="613.05,857.37 617.44,858.27 615.89,864.09 611.12,862.79 		"/>
                            <polygon class="st19" points="606.6,855.69 610.47,857.5 608.53,861.37 604.4,858.92 		"/>
    </g>
                          <g @click="showModal('todos')">
        <path class="st17" d="M800.47,904.29l0.03-12.63l-19.24-17.13l-3.99,2.03l-50.01-47.89l-1.58,14.44l-5.77,16.63
                    c0,0,13.77,7.41,12.87,26.07L800.47,904.29z"/>
                            <polygon class="st19" points="743.22,851.3 737.45,857.08 748.84,887.63 771.3,892.08 772.29,888.95 754.62,872.93 754.45,867.15
                    756.93,864.34 		"/>
    </g>
                        </g>
                        <!--          VOLNE            -->
                        <g class="object object-free">
                          <g @click="showModal('free1')">
        <path class="st17" d="M206.75,417.91l-35.89,13.87l9.03,23.56l28.84-11.67c0,0-2.2-6.61,3.08-13.21L206.75,417.91z"/>
    </g>
                          <g @click="showModal('free2')">
        <polygon class="st17" points="197.94,501.36 233.83,487.04 219.52,449.61 208.73,443.67 179.89,455.34 	"/>
    </g>
                          <g @click="showModal('free3')">
        <path class="st17"
              d="M276.77,405.91l-7.86-19.09l40.56-17.57l4.62,18.83l-23.9,22.77C290.19,410.85,288.66,402.27,276.77,405.91"
        />
    </g>
                          <g @click="showModal('free4')">
        <path class="st17" d="M532.84,754.79l41.28,29.67c0,0-1.49,11.44,3.08,13.95l-7,17.93l-6.82,2.32l-14.8,3.19l-7.24,17.71
                  l-34.21-9.24l3.01-11.89l-4.4-1.54L528,772.4l-0.44-2.86l5.5-5.28L532.84,754.79z"/>
    </g>
                        </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <b-modal id="zones-modal" v-model="modalShow" size="sm" hide-footer hide-backdrop centered>
      <template #modal-title>
        {{modalTitle}}
      </template>
      <div class="d-block">
        <div v-html="modalContent"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import zones from "@/store/retailZones.json"
export default {
  name: "RetailZone",
  data() {
    return {
      zones: zones,
      modalShow: false,
      modalTitle: '',
      modalContent: ''
    }
  },
  methods: {
    scrollToMap() {
      const map = document.getElementById('retailmap')
      map.scrollIntoView({behavior: "smooth", block: 'center'})

    },
    show(el) {
      document.querySelector(".pins.active").classList.remove('active')
      document.querySelector(".link.active").classList.remove('active')
      document.querySelector(".object.active").classList.remove('active')
      document.querySelector('.pins-' + el).classList.add('active')
      document.querySelector('.' + el).classList.add('active')
      document.querySelector('.object-' + el).classList.add('active')
    },

    showModal(el) {
      this.modalTitle = zones[el].title
      this.modalContent = zones[el].description
      this.modalShow = true
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
$lg: 1060px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/stvrt-mapa.jpg") bottom/cover no-repeat

  p
    margin-bottom: 30px
    max-width: 770px

.retail-zone-map
  position: relative
  background: var(--darkBlue)

  .hand-icon
    right: 15px
    left: auto
    bottom: auto
    top: 85px

  .map
    @media (max-width: $md)
      display: flex
      flex-wrap: nowrap
      overflow-x: auto
      height: 460px
      -webkit-overflow-scrolling: touch
      position: relative

      &::-webkit-scrollbar
        display: none

  .zone-menu
    // position: absolute
    // right: 48%
    // top: 15%
    // z-index: 999
    display: flex
    padding: 15px 30px

    @media (max-width: $lg)
      display: flex
      flex-wrap: nowrap
      overflow-x: auto
      -webkit-overflow-scrolling: touch
      position: relative
      padding: 15px

      &::-webkit-scrollbar
        display: none

    .link
      display: block
      color: #fff
      padding: 12px 15px 12px 15px
      border-bottom: 1px solid #fff
      position: relative
      font-weight: 700
      cursor: pointer
      width: 33.333%

      @media (max-width: $md)
        font-size: 16px
        padding-right: 20px
        width: auto
        flex: 0 0 auto

      &:hover
        background: rgba(119, 185, 217, 0.2)
        border-bottom: 1px solid #77B9D9

      &.active
        color: #77B9D9
        background: rgba(119, 185, 217, 0.2)
        border-bottom: 2px solid #77B9D9

        &:hover
          border-bottom: 2px solid #77B9D9

.overlay
  background: url(../assets/images/retailmapbg.png) center/cover no-repeat
  overflow: hidden
  position: relative

  @media (max-width: $md)
    width: 260vw
    position: absolute
    left: -120px

.pins
  width: 100%
  height: 100%
  position: absolute
  left: 0
  top: 0
  z-index: 999
  pointer-events: none
  display: none
  transform: scale(1.25) translateX(-4%) translateY(-2%)

  &.active
    display: block

.pins-ready
  background: url(../assets/images/retailmap-vprevadzke_2.png) center/cover no-repeat

.pins-free
  background: url(../assets/images/retailmap-volne.png) center/cover no-repeat

.pins-preparing
  background: url(../assets/images/retailmap-vpriprave.png) center/cover no-repeat

svg
  max-width: 100%
  height: auto
  position: relative
  z-index: 99
  //transform: scale(1.305) translateX(27.8%) translateY(2.2%)
  transform: scale(1.65) translateX(24.7%) translateY(0.5%)

  g
    opacity: 0
    cursor: pointer

    &.object
      display: none

    &.active
      display: block

    &:hover
      opacity: inherit

.st0
  display: none

.st1
  display: inline
  fill: #192027

.st2
  display: inline
  opacity: 0.2
  fill: #D2D2D1

.st3
  display: inline
  fill: none
  stroke: #6C787B
  stroke-width: 3
  stroke-linecap: round
  stroke-linejoin: round
  stroke-miterlimit: 10

.st4
  display: inline
  fill: none
  stroke: #6C787B
  stroke-width: 3
  stroke-miterlimit: 10

.st5
  display: inline
  fill: none
  stroke: #6C787B
  stroke-width: 5
  stroke-miterlimit: 10

.st6
  display: inline
  fill: #FFFFFF
  stroke: #FFFFFF
  stroke-width: 3
  stroke-linecap: round
  stroke-linejoin: round
  stroke-miterlimit: 10

.st7
  display: inline
  fill: none
  stroke: #6C787B
  stroke-width: 4
  stroke-miterlimit: 10

.st8
  display: inline
  fill: none
  stroke: #6C787B
  stroke-width: 4
  stroke-linecap: round
  stroke-linejoin: round
  stroke-miterlimit: 10

.st9
  display: inline

.st10
  fill: none
  stroke: #6C787B
  stroke-width: 1.122

.st11
  fill: none
  stroke: #6C787B
  stroke-width: 1.002

.st12
  fill: #D2D2D1

.st13
  fill: none
  stroke: #EAD394
  stroke-width: 4
  stroke-miterlimit: 10

.st14
  fill: none
  stroke: #EAD394
  stroke-width: 4
  stroke-miterlimit: 10
  stroke-dasharray: 6.1294, 6.1294

.st15
  fill: none
  stroke: #EAD394
  stroke-width: 4
  stroke-miterlimit: 10
  stroke-dasharray: 5.9976, 5.9976

.st16
  fill: none
  stroke: #EAD394
  stroke-width: 4
  stroke-miterlimit: 10
  stroke-dasharray: 5.8613, 5.8613

.st17
  opacity: 0.8
  fill: #1db5ff

.st18
  display: none
  fill: #192027

.st19
  fill: #0283b5

.st20
  display: none
  fill: #FFFFFF
  stroke: #FFFFFF
  stroke-width: 3
  stroke-linecap: round
  stroke-linejoin: round
  stroke-miterlimit: 10


.st21
  fill: #414042

          </style>