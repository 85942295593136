<template>
  <div class="flats-page">
    <HomeMap />
    <section class="flats" id="tabulka">
      <FlatsTable :show-vr="true"></FlatsTable>
    </section>
  </div>
</template>

<script>

import FlatsTable from "@/components/FlatsTable";
import HomeMap from "@/components/maps/HomeMapNew";
export default {
  name: "Flats",
  components: {HomeMap, FlatsTable},
  mounted() {
    window.scrollTo(0, 0)
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.flats-page
  padding-top: 85px
  background: #2D323A

  @media (max-width: $md)
    padding-top: 45px

</style>
