export default {
	getCookie(){
		const nameEQ = "cookieconsent" + "=";
		const ca = document.cookie.split(';');
		for(let i=0;i < ca.length;i++) {
			let c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) {
				let value = c.substring(nameEQ.length,c.length);
				
				try {
					value = JSON.parse(value);
				} catch(e){const err = e}
				
				return value;
			}
		}
		return null as any;
	},

	getCookieByName(name){
		const nameEQ = name + "=";
		const ca = document.cookie.split(';');
		for(let i=0;i < ca.length;i++) {
			let c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) {
				let value = c.substring(nameEQ.length,c.length);
				
				try {
					value = JSON.parse(value);
				} catch(e){const err = e}
				
				return value;
			}
		}
		return null as any;
	}
}