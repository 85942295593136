<template>
  <div id="app">
    <router-view/>
    <!-- <cookie/> -->
    <ExitModal :showModal="showModal" @update-value="update"/>
    <div id="finance-modal" v-if="showModalFinance">
      <div class="overlay">
        <div class="modal-custom">
          <div class="content">
            <div class="image">
              <div class="close-modal" @click="closeModal">
                <img src="../src/assets/icons/close-gallery.svg" alt="">
              </div>
            </div>
            <div class="box">
              <div class="big">- 3 %</div>
              <!-- <p class="border-bottom-white">Znížený úrok o&nbsp;3 %</p> -->
              <div class="d-flex align-items-center justify-content-center">
                <p class="text-dark">Prečítajte si <a href="../znizeny-urok" class="link" @click="closeModal">ako financovať svoje bývanie so zníženým Bory úrokom</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import cookie from "@/plugins/cookie-banner/cookie.vue"
import utmData from "@/plugins/utmdata/index"
import ExitModal from "@/components/ExitModal"

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export default {
  mounted() {
    this.$store.dispatch('flats/loadFlats')
    utmData.getUtmData(this.$route.query)
    this.showModalFinance = getCookie('disableFinanceModal') != 1 ? true : false
  },
  components: {
    ExitModal
  },
  data() {
    return {
      showModal: false,
      showModalFinance: false
    }
  },
  created () {
    document.addEventListener('mouseout', this.modal)
  },
  methods: {
    modal(evt) {
      if (getCookie('disableExitModal') != 1) {
        if (evt.toElement == null && evt.relatedTarget == null) {
          this.showModal = true
        }
      }
    },
    update(value){
      this.showModal = value
    },
    closeModal() {
      setCookie('disableFinanceModal', '1', 14)
      this.showModalFinance = false
    }
  }
}

</script>


<style lang="sass">
$md: 768px
#app 
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50


#nav 
  padding: 30px

  a 
    font-weight: bold
    color: #2c3e50

    &.router-link-exact-active 
      color: #42b983

#finance-modal
  position: fixed
  z-index: 99999
  width: 100%
  height: 100vh
  top: 0
  left: 0
  display: none

  @media (max-width: $md)
    display: block

  .overlay
    background: rgba(0, 0, 0, .5)
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0

    .modal-custom
      height: 80vh
      position: relative
      top: 50px
      padding: 25px

      .content
        height: 100%
        position: relative

        .close-modal
          width: 34px
          height: 34px
          padding: 9px
          position: absolute
          right: 15px
          top: 15px

          img
            width: 100%
            display: block

      .image
        height: 50%
        background: url('assets/images/finance-modal.jpg') no-repeat
        background-size: cover
        background-position: center left

      .box
        position: relative
        padding: 115px 25px 35px
        max-width: 460px
        background: var(--blue)
        .big
          font-size: 84px
          font-weight: 800
          position: absolute
          top: -10px
          left: 25px

        .border-bottom-white
          padding-bottom: 15px
          border-bottom: 1px solid rgba(255, 255, 255, .4)
          margin-bottom: 20px
          font-size: 42px
          line-height: 52px
          font-weight: normal

        .text-dark
          margin-bottom: 0
          font-size: 16px
          font-weight: 400

          a
            font-weight: 600
            color: #343a40
            text-decoration: underline
            
            &::after
              content: ""
              width: 100%
              height: 100%
              position: absolute
              left: 0
              bottom: 0
              cursor: pointer
    
</style>
