<template>
  <section class="faq">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="col-12">
            <h3>Najčastejšie otázky o&nbsp;štvrti&nbsp;Bory<br><br></h3>
          </div>
          <div class="col-12 pt-3">
            <div class="accordion mb-4 mb-md-5" role="tablist">
              <div class="question-wrapper">
                <div v-b-toggle.accordion-1 class="question">Ako sa dostanem na Bory?</div>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Do štvrte Bory sa jednoducho dostanete autom z centra mesta, cez Patrónku či diaľnicu, ale aj z mestských častí Dúbravka, Devínska Nová Ves, Lamač, Záhorská Bystrica rovnako ako zo Stupavy.<br><br>Do štvrte premávajú aj MHD spoje č. 21 z autobusovej stanice a č. 30 zo zastávky Patrónka, a to priamo pred nákupné centrum Bory Mall alebo Nemocnicu Bory. MHD spoje premávajú v intervaloch 10 – 15 minút, pričom z centra mesta ste na Boroch za 20 minút. Do štvrte sa prímestskou autobusovou dopravou dostanete aj zo Stupavy, a to linkou 216 alebo 269 s&nbsp;prestupom na linku 30.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-2 class="question">Dostanem sa na Bory aj bicyklom?</div>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Do štvrti Bory sa dostanete aj&nbsp;na bicykli za pár minút z&nbsp;mestskej časti Dúbravka či Lamač. Po samotnej štvrti je možné sa na bicykli jednoducho presúvať po cyklochodníku.<br><br>Neustále pracujeme na zlepšovaní a&nbsp;rozširovaní cyklociest. V spolupráci s mestskými časťami je pripravované aj predĺženie cyklochodníka z Borov až do Devínskej Novej Vsi a mestskej časti Lamač.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-3 class="question">Čo všetko nájdem už teraz na Boroch?</div>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>V štvrti Bory nájdete už teraz rozsiahlu nákupnú zónu s bohatou ponukou obchodných prevádzok, ako Kaufland, Metro, Decathlon, Hornbach, Mercury Market, Möbelix či SIKO.<br><br>Súčasťou zóny je aj nákupno-zábavné centrum Bory Mall, v ktorom vybavíte nákupy cez potraviny, oblečenie, elektro, hračky či potreby pre domácich miláčikov, ale aj poštu, čistiareň, banku, kaderníka, opravu mobilov či dokonca krajčírstvo a kľúčovú službu.<br><br>Špičkovú zdravotnú starostlivosť vám poskytne poliklinika ProCare priamo v centre Bory Mall a Nemocnica Bory, najmodernejšia nemocnica na Slovensku.<br><br>Na Boroch sme vybudovali aj škôlku a plánovaná je aj výstavba komplexného školského zariadenia s materskou školou, základnou školou a gymnáziom.<br><br>Okrem iného si v&nbsp;blízkom čase budete môcť vychutnať obľúbený McDonald's alebo&nbsp;nakúpiť v&nbsp;obchodnom dome LIDL.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-4 class="question">Môžem ísť do Nemocnice Bory, aj keď&nbsp; tam nebývam?</div>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Nemocnica Bory je koncová nemocnica, ktorej služby môže využiť celá verejnosť bez ohľadu na to, či bývate v štvrti Bory.<br><br>Je však dôležité pripomenúť, že nemocničné ambulancie v Nemocnici Bory nie sú klasickou poliklinikou, do ktorej odosiela pacientov na prvotné vyšetrenia k špecialistom všeobecný lekár. Na objednanie termínu do väčšiny našich ambulancií je potrebné disponovať výmenným lístkom od lekára – špecialistu.<br><br>Nemocničné ambulancie v Nemocnici Bory slúžia:
                    <ul>
                      <li>ako vstupná brána pre pacientov, ktorí idú na plánovanú hospitalizáciu či operáciu,</li>
                      <li>pre pacientov, ktorí už sú po zákroku a musia byť ešte ďalej ambulantne sledovaní,</li>
                      <li>ako tzv. referenčná ambulancia, teda akési vyššie pracovisko pre iné bežné špecializované ambulancie, kam pacienta odosiela lekár – špecialista za účelom ďalšej diagnostiky alebo liečby.</li>
                    </ul>
                    Špecializované ambulancie, ktoré nájdete v Nemocnici Bory, si môžete pozrieť na stránke <a href="https://nemocnica-bory.sk/ambulancie-a-pracoviska/" target="_blank">Ambulancie a pracoviská – Nemocnica Bory (nemocnica-bory.sk)</a>
                  </div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-5 class="question">Kedy bude na Boroch električka a&nbsp;kadiaľ&nbsp;&nbsp;bude premávať?</div>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Predĺženie električkovej radiály do Borov je aktuálne v&nbsp;štádiu podpísaného memoranda o&nbsp;spolupráci s&nbsp;Magistrátom hl. mesta SR Bratislava, v&nbsp;ktorom sme sa zaviazali obstarať dokumentáciu potrebnú na vydanie EIA – posudzovanie vplyvov na životné prostredie a dokumentáciu pre územné rozhodnutie a stavebné povolenie. Aktuálne prebiehajú koordinačné stretnutia s&nbsp;obstarávateľom dokumentácie.<br><br>V prvej fáze výstavby bude električková trať vybudovaná popred centrum Bory Mall, Nemocnicu Bory až po etapu Bory Prvé Domy, kde bude zriadené dočasné otočisko. V&nbsp;budúcnosti by sa v&nbsp;súlade s&nbsp;územným plánom mala trať predlžovať cez celé územie štvrte Bory až na predĺženie Eisnerovej ulice, čím sa štvrť plynule prepojí s&nbsp;mestskou časťou Devínska Nová Ves.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-6 class="question">Ako sa najrýchlejšie dostanem od nákupno-zábavného centra Bory Mall do etáp Bory Nový Dvor a Bory na Hrádzi?</div>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Na presun z nákupného centra Bory Mall do etapy Bory Nový Dvor a Bory na Hrádzi nemusíte vôbec použiť auto. Postačí vám bicykel, s ktorým túto trasu zvládnete pohodlne do 10 minút, prípadne sa zviesť jednu zastávku MHD z OC Bory Mall na zastávku Tomislava P. Kolakoviča.<br><br>A ak máte radi prechádzky, tak peši trvá táto cesta maximálne 15 minút, ak sa vyberiete popri etape Bory Promenáda, kde sa cez ulicu Vladimíra Jukla jednoducho napojíte na hrádzu vedúcu popri Mercury Market až po etapu Bory na Hrádzi a Bory Nový Dvor.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-7 class="question">Je možné prejsť pešo z&nbsp;etapy Bory Nový Dvor a&nbsp;Bory Na Hrádzi do Kauflandu na denný nákup?</div>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Áno, z&nbsp;etáp Bory Nový Dvor a&nbsp;Bory Na Hrádzi sa dostanete pešo do Kauflandu za približne 5 minút po chodníku, ktorý vedie popri ceste.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-8 class="question">Kde a kedy sa najbližšie plánuje na Boroch stavať?</div>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>V štvrti Bory aktuálne ukončujeme výstavbu etapy Bory Na Hrádzi, pričom v&nbsp;najbližších rokoch plánujeme začať výstavbu ďalších viac ako 1 200 bytov, ktoré budú súčasťou nových etáp štvrti Bory.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-9 class="question">Kedy budú celé Bory postavené?</div>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Štvrť Bory postupne vzniká od roku 2008, pričom je v nasledujúcich 10 rokoch v pláne výstavba ďalších rezidenčných etáp. S určitosťou zatiaľ nie je možné stanoviť konkrétny rok ukončenia výstavby celej štvrte.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-10 class="question">Bude v&nbsp;niektorej z etáp kaviareň, cukráreň alebo bistro?</div>
                <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Etapa Bory Nový Dvor disponuje viacerými obchodnými priestormi, ktoré aktuálne čakajú na svojich záujemcov. Veríme, že si budete môcť už čoskoro posedieť pri voňavej kávičke s chutným koláčom.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-11 class="question">Bude na Boroch aj nejaký podnik / bar/ pohostinstvo na večerné posedenia?</div>
                <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Pohostinstvo, respektíve bar s&nbsp;večernou prevádzkou plánujeme v&nbsp;rámci rozšírenia nákupného centra Bory Mall, ktoré je plánované zo strany Nemocnice Bory.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-12 class="question">Bude na Boroch aj kostol?</div>
                <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Áno, v rámci urbanistickej štúdie je v&nbsp;štvrti v budúcnosti plánovaná aj výstavba rímskokatolíckeho kostola, ohľadom ktorej už aktuálne prebieha diskusia.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-13 class="question">Sú Bory vhodným miestom na bývanie pre rodiny s deťmi? Je&nbsp;tam škôlka a&nbsp;škola?</div>
                <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Štvrť Bory je ideálna aj pre rodiny s&nbsp;deťmi. Štvrť už teraz poskytuje všetko, čo pre život potrebujete. Bohatú občiansku vybavenosť v&nbsp;podobe veľkej nákupnej zóny, nákupného centra, nemocnice, dopravnej dostupnosti, ale aj štátnej škôlky Bory a&nbsp;v&nbsp;najbližších 3 rokoch aj súkromnej školy s&nbsp;predškolskou, základnou aj strednou úrovňou vzdelávania, pričom pre obyvateľov Borov bude zápisné zadarmo.</div>
                </b-collapse>
              </div>
          </div>
        </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="col-12">
            <h3>Najčastejšie otázky klientov, ktorí už v&nbsp;štvrti bývajú alebo čakajú na svoj byt</h3>
          </div>
          <div class="col-12 pt-3">
            <div class="accordion mb-4 mb-md-5" role="tablist">
              <div class="question-wrapper">
                <div v-b-toggle.accordion-15 class="question">Zabudol/zabudla som svoje prihlasovacie meno a&nbsp;heslo do klientskeho portálu. Čo mám robiť?</div>
                <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Vaše prihlasovacie údaje nájdete priamo v kúpnej zmluve, a to e-mail, ktorý ste v nej uviedli, ako aj telefónne číslo, na ktoré vám príde overovacia SMS pre prihlásenie.<br>V prípade, že ste zabudli heslo, využite funkciu „Forgot password”, ktorá vám pomôže resetovať a nastaviť nové heslo cez prihlasovací e-mail.<br><br>V prípade, že vám ani prostredníctvom týchto možností prihlásenie nefunguje, môžete kontaktovať svojho predajcu telefonicky alebo na mailovej adrese byvanie@borybyvanie.sk <br><br>Rovnako tak môžete urobiť v prípade, ak si chcete zmeniť kontaktný e-mail alebo telefónne číslo.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-16 class="question">Čítal/a som, že etapa, v&nbsp;ktorej som kúpil/a byt, je skolaudovaná. Kedy môžem očakávať odovzdanie bytu?</div>
                <b-collapse id="accordion-16" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Po právoplatnej kolaudácii sa byty zapisujú na katastri, čo trvá približne 2 mesiace. Po zápise bytov začíname klientom posielať výzvu na úhradu zvyšnej časti kúpnej ceny. Po zaplatení celej kúpnej ceny si vyberiete termín na podpis kúpnej zmluvy a termín odovzdania bytu.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-17 class="question">Mám za sebou technickú obhliadku a&nbsp;niektoré veci chcem reklamovať. Ako dlho to potrvá?</div>
                <b-collapse id="accordion-17" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Všetky vady zistené počas technickej obhliadky sa odstraňujú počas doby do odovzdania bytu.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-18 class="question">Môžem si balkón alebo predzáhradku zariadiť ako chcem?</div>
                <b-collapse id="accordion-18" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Rozsah úprav jednotlivých častí bytu upravuje Dizajn manuál. Tento manuál, ako aj ostatné pokyny a doplňujúce informácie, postupne nahrávame do klientskeho portálu, konkrétne do časti dokumenty, kde si ich môžete podrobne preštudovať.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-19 class="question">Kde môžu parkovať návštevy?</div>
                <b-collapse id="accordion-19" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>V etape Bory Prvé Domy, Bory Nový Dvor a Bory Na Hrádzi sa nachádzajú parkovacie miesta pre návštevníkov, pre návštevníkov etapy Bory Promenáda je možné využiť vonkajšie parkovacie miesta pred nákupno-zábavným centrom Bory Mall.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-20 class="question">Koľko čipov dostanem do bytového domu a&nbsp;garáže? Čo ak ich potrebujem viac?</div>
                <b-collapse id="accordion-20" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Ak ste si zakúpili garážové státie, budete mať k dispozícii 1 čip od ovládania garážovej brány. V prípade, že budete mať záujem o viac ako 1 ks, môžete po odovzdaní bytu kontaktovať priamo správcu.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-21 class="question">Ako sa prihlásim na odber energií ako nový obyvateľ Borov?</div>
                <b-collapse id="accordion-21" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Konkrétne pokyny pre prihlásenie na prepis odberu elektrickej energie a&nbsp;vzor žiadosti s označenými časťami, ktoré musíte pri prepise vypísať, nájdete priamo v klientskom portáli v časti dokumenty, kde postupne nahrávame všetky potrebné manuály, usmernenia a pokyny.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-22 class="question">Čakám na technickú obhliadku, ale stále mi neprišiel e-mail. Čo mám robiť?</div>
                <b-collapse id="accordion-22" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Možnosť prihlásenia sa na technickú obhliadku vám bude oznámená prostredníctvom notifikácie, ktorá vám príde do e-mailu s informáciou, že je byt pripravený na technickú obhliadku. Priamo v klientskom portáli sa následne môžete prihlásiť na termín technickej obhliadky, ktorý vám vyhovuje.<br><br>Byty dokončujeme postupne, čomu zodpovedá aj postupné odosielanie notifikácií jednotlivým klientom. Prosíme vás o&nbsp;trpezlivosť. Ak ste notifikáciu zatiaľ nedostali, váš byt ešte nie je pripravený na technickú obhliadku.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-23 class="question">Čakám na odovzdanie svojho bytu, ale stále ma nikto nekontaktoval.</div>
                <b-collapse id="accordion-23" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Odovzdanie bytu prebieha po úhrade celej kúpnej ceny a po podpise kúpnej zmluvy. V momente, keď je váš byt pripravený na odovzdanie, dostanete notifikáciu, ktorá vám príde do e-mailu s informáciou o možnosti prihlásiť sa na termín odovzdania bytu. Priamo v klientskom portáli sa následne môžete prihlásiť na termín odovzdania bytu, ktorý vám vyhovuje.</div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-24 class="question">Kto je správcom bývania na Boroch?</div>
                <b-collapse id="accordion-24" accordion="my-accordion" role="tabpanel" class="answer">
                  <div>Správcovské spoločnosti sa v jednotlivých etapách Bory Bývania odlišujú. <br><br>V etape Bory Prvé Domy je správcom spoločnosť H-PROBYT, spol. s r.o., <br>V etape Bory Nový Dvor (1. fáza) je správcom spoločnosť Somat Group, a.s.<br>Pre ostatné etapy – Bory Nový Dvor (2. fáza) a Bory Promenáda, bude slúžiť nová správcovská spoločnosť Property Services by Penta Real Estate, s.r.o., ktorú sme zriadili pre lepšie poskytovanie služieb obyvateľom aj po odovzdaní bytov.</div>
                </b-collapse>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="d-flex text px-5 px-md-4">
        <p>Máte otázku, na ktorú ste nenašli odpoveď? Opýtajte sa nás priamo na <a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></p>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
name: "Faq"
}
</script>

<style lang="sass" scoped>
$md: 768px

.faq
  background: var(--textBright)
  padding: 70px 0

  .border-top-custom
    border-top: 1px solid rgba(255, 255, 255, 0.2)

  .text
    align-items: center
    justify-content: space-between
    width: 100%
    @media (max-width: $md)
      flex-direction: column
    p
     font-size: 16px
     line-height: 24px
     margin-bottom: 0
     @media (max-width: $md)
      margin-bottom: 15px

     a
      color: var(--blue)

  @media (max-width: $md)
    padding: 40px 0

  h3
    font-weight: 300
    font-size: 45px
    line-height: 50px

    @media (max-width: $md)
      font-size: 34px
      line-height: 38px
      margin-bottom: 20px

  .question-wrapper
    border-top: 1px solid rgba(255, 255, 255, 0.20)
    padding: 10px 0

    &:last-child
      border-bottom: 1px solid rgba(255, 255, 255, 0.20)

    @media (max-width: $md)
      padding: 10px 0

    .question
      padding: 15px 0
      font-size: 20px
      font-weight: 700
      line-height: 30px
      padding-left: 50px
      position: relative

      @media (max-width: $md)
        line-height: 24px

      &.not-collapsed
        &:before
          background-image: url(../assets/images/procare/minus.svg)

      &:before
        content: ""
        width: 32px
        height: 32px
        background-color: rgba(255, 255, 255, 0.05)
        background-image: url(../assets/images/procare/plus.svg)
        background-repeat: no-repeat
        background-position: center
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)

    .answer
      font-size: 16px
      font-weight: 400
      line-height: 24px
      padding-left: 50px

      @media (max-width: $md)
        padding-left: 0

      a
        color: var(--blue)
</style>