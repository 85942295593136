<template>
  <div>
    <section class="b3-jazierko">
      <SvgMap :map="mapData" :path-click="pathClick" :marker-click="markerClick" ref="b3mapa" />
    </section>
    <Podlazie v-if="floor && building" ref="floor" :stage="stage" :floor="floor" :building="building" :filter="filter"/>
  </div>
</template>

<script>
import SvgMap from "./SvgMap";
import Podlazie from "./Podlazie";
import map from "@/store/maps.json"
import misc from "@/store/misc.json";
import store from "@/store";
import ScrollTo from "@/plugins/scrollTo.util"
import {mapActions} from "vuex";

export default {
  name: "B3Map",
  components: {SvgMap, Podlazie},
  data() {
    return {
      filter: null,
      stage: 'bory3',
      mapUrl: '/map/b3.jpg',
      showMap: false,
      mapData: map.b3,
      building: this.$route.params.building || null,
      floor: this.$route.params.floor || null
    }
  },

  async mounted() {
    await this.loadFlats()
    this._updateMarkersText()
    this._updateFloorsClasses()
    this.$root.$on('filterChanged', (val) => {
      this.floor = val;
    })
  },

  methods: {
    ...mapActions('flats', ['loadFlats']),
    pathClick: function (oEvent) {
      const data = oEvent.target.dataset.map.split("-")
      this.building = data[0]
      this.floor = data[1]
      this.filter = {
        stages: this.stage,
        dates: [],
        buildings: [this.building],
        rooms: [],
        floors: [this.floor],
        availability: 0,
        priceVat: null,
        totalArea: null
      }

      this.$root.$emit('setFilter',
          {
            stages: this.stage,
            dates: [],
            buildings: [this.building],
            rooms: [],
            floors: [this.floor],
            availability: 0,
            priceVat: null,
            totalArea: null
          }
      )
      setTimeout(() => ScrollTo.id(this, 'floor-plan'), 500)
    },

    markerClick: function (oEvent) {
      if(oEvent.target.parentElement.classList.contains('empty')
          || oEvent.target.classList.contains('empty'))
        return

      const data = oEvent.target.dataset.label
          || oEvent.target.parentElement.dataset.label

      this.$root.$emit('setFilter',
          {
            stages: this.stage,
            dates: [],
            buildings: [data],
            rooms: [],
            floors: [],
            availability: 0,
            priceVat: null,
            totalArea: null
          }
      )
      ScrollTo.id(this, 'tabulka')
    },

    _updateFloorsClasses: function () {
      this.mapData.paths.forEach( (f, i) => {
        const data = f.data.mapId.split("-")
        const count = store.getters["flats/getFreeFlatsByBuildingAndFloor"](this.stage, data[0], data[1])
        this.mapData.paths[i].class = count > 0 ? 'path' : 'hidden'
      })
    },
    _updateMarkersText: function () {
      this.mapData.markers.forEach( (m, i) => {
        const count = store.getters["flats/getFreeFlatsByBuildings"](this.stage, m.label)
        const prereserved = store.getters["flats/getPreReservedFlatsByBuildings"](this.stage, m.label)

        if (prereserved > 0 && count === 0) {
          this.mapData.markers[i].class = 'empty'
          this.mapData.markers[i].text = "Nie je v predaji"
        } else if (count)
          this.mapData.markers[i].text = "V predaji"
        else {
          this.mapData.markers[i].class = 'empty'
          this.mapData.markers[i].text = "Vypredané"
        }
      })


      this.$refs.b3mapa.$forceUpdate()
    }
  }
}
</script>

<style lang="sass" >
.b3-jazierko
  background-image: url("/map/b3.jpg")
  background-repeat: no-repeat
  background-size: cover
  position: relative

  svg
    position: relative
    z-index: 2
    width: 100%
    height: auto
    .marker
      fill: #688495
      cursor: pointer
    .marker-wrapper.empty
      .empty
        fill: white
      text
        fill: #688495
    .marker-wrapper:not(.empty)
      text
        cursor: pointer
    .hidden
      display: none
    .path
      fill: transparent
      transition: all linear .2s
      &:hover
        fill: #688495
        cursor: pointer

</style>
