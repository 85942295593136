import Vue from 'vue'
import VueRouter from 'vue-router'

import VueGtag from "vue-gtag"
import VueGtm from '@gtm-support/vue2-gtm'

// Get cookies info
import cookies from '@/plugins/cookie-banner/getCookie'

export default {
	addGoogleAnalytics(router: VueRouter, config=[]) {
		if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID){

			Vue.use(VueGtag, {
				config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
			})
		}
	},

	addGoogleTagManager(router: VueRouter) {
		if (process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID){

			Vue.use(VueGtm, {
				id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
				defer: false,
				enabled: true,
				debug: false,
				loadScript: true,
				vueRouter: router,
				ignoredViews: [],
				trackOnNextTick: false,
			})
		}
	}, 
}