<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Bory Prvé Domy</h1>
          <p>Prvú etapu projektu tvorí deväť bytových domov s prioritou pešieho pohybu a oddychových voľnočasových aktivít v atraktívnom prostredí mestského parku. Prvá fáza bola dokončená v roku 2018. Okrem dopravnej dostupnosti a cenovo atraktívneho bývania obyvateľov poteší aj blízkosť prírody, rekreačných oblastí a už existujúceho nákupného centra BORY MALL.
          </p>
          <b-button to="/nasa-stvrt" class="d-block d-md-inline-block" squared variant="primary">O bývaní na Boroch</b-button>
        </div>
      </div>
    </div>

    <section class="district p-md-3 pt-3 pt-md-5">
      <div class="d-flex flex-wrap align-items-start top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">Mesto tak, ako ho poznáte</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">Bory kombinujú aktívne rodinné bývanie s bohatým verejným priestorom, kompletnou vybavenosťou, množstvom obchodov a pohodlnou dopravou do centra.</p>
        </div>
      </div>
    </section>



    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 p-0 px-md-5 py-3">
            <h2>Buďte pri tom,<br> čo potrebujete</h2>
            <p>Bory Bývanie je pilotný projekt novej mestskej obytnej štvrte, ktorého cieľom je vytvoriť kvalitné a príjemné prostredie pre život. Základom je zdravé a ekonomické bývanie s jednoduchým a praktickým riešením bytov za použitia prírodných materiálov. Z príjemne zvlneného terénu zelene vyrástlo 9 bytových domov s prioritou pešieho pohybu a oddychových voľnočasových aktivít v atraktívnom prostredí mestského parku.</p>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-1">
              <h3>17 400 m<sup>2</sup><br> zelene</h3>
              <p>Priestranný park plný zelene, detské ihrisko, workout zóna a altánok. Vybavenie dôležité pre oddych, ale aj aktívne trávenie voľného času.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>196 pešo dostupných<br> obchodov</h3>
              <p>Obchodný dom Bory Mall je v pešej dostupnosti, navyše s bohatou ponukou obchodov, služieb a reštaurácií. </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>466 parkovacích miest<br> pre obyvateľov</h3>
              <p>Projekt prináša obyvateľom dostatočný počet parkovacích miest, ktoré navyše nenarúšajú kvalitný verejný priestor. </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="schedule p-3 p-md-5">
      <h2 class="mb-5">Harmonogram výstavby</h2>
      <div class="d-flex flex-wrap">
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3>Zahájenie<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">1. kvartál 2017</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3>Dokončenie<br> hrubej stavby</h3>
          <div class="line fill"></div>
          <p class="date">4. kvartál 2017</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3>Plánovaná<br> kolaudácia</h3>
          <div class="line fill"></div>
          <p class="date">4. kvartál 2018</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3>Odovzdanie<br> bytov</h3>
          <div class="line fill"></div>
          <p class="date">2. kvartál 2019</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kluce-byvanie.svg">
          </div>
          <h3>Vaše<br> bývanie</h3>
          <div class="line fill transparent"></div>
        </div>
      </div>
    </section>

    <section class="about-bottom p-md-3 pl-md-5">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 py-5">
          <h2 class="mb-4">Domov, ktorý je pohodlný</h2>
          <p class="mb-5">Byty v novej štvrti majú praktické dispozície a vysoký štandard. Pripravili sme pre vás funkčné a praktické bývanie 15 minút od centra Bratislavy. Rezidenčný projekt Bory Bývanie je navrhnutý tak, aby svojim obyvateľom poskytol všetko pre komfortný a pohodlný život.</p>
<!--          <b-button squared variant="primary" class="download-btn download-btn-white">Stiahnuť katalóg štandardov</b-button>-->
        </div>
        <div class="col-md-6 pb-3 pb-md-0 pr-md-0">
          <div class="right-img"></div>
        </div>
      </div>
    </section>

    <section class="about-bottom-2">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 bg"></div>
        <div class="col-md-6 signature-section">
          <h2 class="mb-4">Kompletná<br> občianska vybavenosť</h2>
          <p class="">Priamo v novej štvrti majú obyvatelia k dispozícii verejný priestor s množstvom zelených plôch, park, jazierko, multiihriská či cyklochodník. Kompletná občianska vybavenosť ako škôlka, kino, obchody i MHD je v pešej dostupnosti.</p>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
export default {
  name: "Bory1",
  components: {ContactForm},
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.about-bottom-2
  .bg
    background: url(../assets/images/bory1/bottom-image.jpg) center/cover no-repeat
.about-bottom
  .right-img
    background: url(../assets/images/bory1/bory1-standard.jpg) center/cover no-repeat

.schedule
  background: var(--textBright)
  .phase
    @media (max-width: $md)
      border-left: 2px solid var(--textDark)
      padding-left: 15px !important
      display: flex
      flex-direction: column
      padding-bottom: 20px !important
      &.fill
        border-color: var(--blue)
        &:before
          background: var(--blue)
      &.fill-half
        &:before
          background: var(--blue)
        &:after
          content: ""
          width: 2px
          height: 60%
          background: var(--blue)
          position: absolute
          left: -2px
          top: 0
      &:last-child
        border-color: transparent
      &:before
        content: ""
        width: 12px
        height: 12px
        background: var(--textDark)
        border-radius: 50%
        position: absolute
        left: -7px
        top: 0
        margin-top: 0
  h3
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.5px
    @media (max-width: $md)
      order: 3
  p.date
    font-size: 16px
    line-height: 20px
    letter-spacing: -0.5px
    color: var(--blue)
    font-weight: 500
    @media (max-width: $md)
      order: 1
      margin-top: -3px
  .icon
    height: 40px
    margin-bottom: 10px
    @media (max-width: $md)
      order: 2
  .line
    margin: 20px 0
    height: 2px
    background: var(--textDark)
    position: relative
    @media (max-width: $md)
      display: none
    &:before
      content: ""
      width: 12px
      height: 12px
      background: var(--textDark)
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      margin-top: -5px
    &.fill
      background: var(--blue)
      &:before
        background: var(--blue)
    &.fill-half
      &:before
        background: var(--blue)
      &:after
        content: ""
        width: 50%
        height: 100%
        background: var(--blue)
        position: absolute
        left: 0
        top: 0
    &.transparent
      background: transparent

.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .bg-img
    background: url("../assets/images/bory1/budte-pri-tom.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 320px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory1/bory1-zelen.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory1/bory1-vybavenost.jpg") bottom/cover no-repeat
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory1/bory1-parkovanie.jpg") center/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory1/bory1.jpg") bottom/cover no-repeat
  p
    margin-bottom: 30px
    max-width: 770px

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>