<template>
  <svg :x="bar.left" :y="bar.top" class="bar" :class="{showBar: showBar}"
       @mouseover="pathEnter(null, mapId)" @mouseout="pathLeave">
    <rect x="0" y="0" :width="bar.tiles.length * o.size + bar.tiles.length * o.gap" :height="o.size" style="opacity: 0"></rect>
    <g v-for="(b, i) in bar.tiles" :key="i" :data-route="b.route" :data-title="b.title1" :data-stage="bar.stage" @mousedown="pathClick" :class="bar.stage">
      <rect :width="o.size"
            :height="o.size" y="0"
            :x="o.size * i + o.gap * i"
      />
      <text :x="o.size * i + o.gap * i + o.size/2"
            :y="b.subtitle ? (o.t1 * o.size) - (o.off * o.size) : (o.t1 * o.size)"
            dominant-baseline="middle" text-anchor="middle" class="title">{{b.title1}}
      </text>
      <text :x="o.size * i + o.gap * i + o.size/2"
            :y="b.subtitle ? (o.t2 * o.size) - (o.off * o.size) : (o.t2 * o.size)"
            dominant-baseline="middle" text-anchor="middle" class="title">
        {{b.title2}}
      </text>
      <text v-if="b.subtitle"
            :x="o.size * i + o.gap * i + o.size/2"
            :y="o.sub * o.size"
            dominant-baseline="middle" text-anchor="middle" class="subtitle">
        {{b.subtitle}}
      </text>
    </g>
  </svg>
</template>

<script>

export default {
  name: "SvgBar",
  props: {
    showBar: {
      type: [Boolean]
    },
    mapId: {
      type: [Number]
    },
    bar: {
      type: [Object]
    },
    pathEnter: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    },
    pathLeave: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    },
    pathClick: {
      type: [Function],
      default: () => {
        // do nothing.
      }
    }
  },
  data() {
    return {
      o: {
        t1: .37,
        t2: .63,
        sub: .80,
        off: .12,

        size: 110,
        gap: 2
      }
    }
  }
}
</script>

<style lang="sass" >

</style>
