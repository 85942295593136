<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>Bory Nový Dvor</h1>
          <p>Rozsiahle územie druhej etapy projektu umožní obyvateľom aktívny pohyb. Rozloha parkov je dostatočne veľká na prechádzky aj trávenie voľného času. Verejné priestory poskytnú svojim obyvateľom viac ako bývanie, vďaka ich dômyselnému riešeniu môžu tráviť čas napríklad v kaviarni pred domom, na ihriskách s tými najmenšími či stať sa súčasťou komunity.</p>
          <b-button to="/nasa-stvrt" class="d-block d-md-inline-block" squared variant="primary">O bývaní na Boroch</b-button>
<!--          <b-button href="/katalog/bory-byvanie2-brozura.pdf" download="/katalog/bory-byvanie2-brozura.pdf" class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0 download-btn" squared variant="light">Stiahnuť brožúru</b-button>-->
        </div>
      </div>
    </div>

    <section class="district p-md-3 pt-3 pt-md-5">
      <div class="d-flex flex-wrap align-items-start top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">Mesto tak, ako ho poznáte</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">Bory kombinujú aktívne rodinné bývanie s bohatým verejným priestorom, kompletnou vybavenosťou, množstvom obchodov a pohodlnou dopravou do centra.</p>
        </div>
      </div>
      <div id="mapbox">
        <B2Map/>
      </div>
    </section>

    <section id="tabulka">
      <FlatsTable :stages="['bory2']" :showStageAndDate="false" :show-vr="false"></FlatsTable>
    </section>

    <section class="schedule p-3 p-md-5">
      <h2 class="mb-5">Harmonogram výstavby</h2>
      <div class="d-flex flex-wrap">
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3>Začiatok<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2018</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3>Koniec<br> výstavby</h3>
          <div class="line fill"></div>
          <p class="date">3. kvartál 2020</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3>Kolaudačný<br> proces</h3>
          <div class="line fill-min"></div>
          <p class="date">4. kvartál 2020</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3>Odovzdávanie<br> bytov</h3>
          <div class="line"></div>
          <p class="date">2. kvartál 2021</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/kluce-byvanie.svg">
          </div>
          <h3>Vaše<br> bývanie</h3>
          <div class="line transparent"></div>
        </div>
      </div>
    </section>

    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 p-0 px-md-5 py-3">
            <h2>Buďte pri tom,<br> čo je rodinné</h2>
            <p>Rodinné bývanie, aj tak by sa dala charakterizovať druhá etapa projektu Bory Bývanie. Priestranné byty s rôznymi dispozíciami sú navrhnuté vždy tak, aby poskytovali obyvateľom dostatok úložných priestorov. V tejto etape tiež postavíme celkovo 4 detské ihriská obklopené pestrou zeleňou. Vnútrobloky navyše poskytujú bezpečie a súkromie všetkým obyvateľom a práve to rodina potrebuje.</p>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-1">
              <h3>12 000 m<sup>2</sup><br> zelene</h3>
              <p>Jednotlivé bytové domy sú umiestnené na pódiách a ich strechy sú navrhnuté ako zelené poloprivátne priestory pre obyvateľov.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>Jazierko<br> v areáli</h3>
              <p>Jazierko v centre areálu vďaka zadržiavaniu zrážkovej vody v mieste jej dopadu výrazne prispieva k pozitívnej mikroklíme.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>405<br> parkovacích miest</h3>
              <p>Pohodlné parkovanie v bezprostrednej blízkosti domova zaručí dostatočný počet vybudovaných parkovacích miest.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-bottom p-md-3 pl-md-5">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 py-4">
          <h2 class="mb-4">Domov, ktorý je kvalitný</h2>
          <p class="mb-3">Materiály sú vo výbornej kvalite a majú dlhú životnosť. Spoľahnite sa na moderné riešenia a osvedčených dodávateľov. Vyberte si z rôznych dekorov a povrchových materiálov presne podľa vašich predstáv.</p>
<!--          <b-button href="/katalog/katalog_standardov_(002).pdf" download="/katalog/katalog_standardov_(002).pdf" squared variant="primary" class="download-btn download-btn-white">Stiahnuť katalóg štandardov</b-button>-->
        </div>
        <div class="col-md-6 pb-3 pb-md-0 pr-md-0">
          <div class="right-img"></div>
        </div>
      </div>
    </section>

    <section class="about-bottom-2">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 bg"></div>
        <div class="col-md-6 signature-section">
          <p class="mb-4 text-italic font-weight-bolder">„Do rámca tvoreného požiadavkou na dopravný komfort a dostatok parkovacích miest vkladáme vrstvu mestskej krajiny poskytujúcu kvalitné zázemie pre pokojné bývanie. Priestor okolo bytových domov napĺňame výsadbou zelene a zázemím pre aktivity budúcich obyvateľov, tak aby sme maximálne uplatnili sociálne, ekonomické a ekologické princípy tvorby životného priestoru.“</p>
          <p class="name">Laboratórium architektúry krajiny</p>
          <p class="name">Michal Marcinov, Matúš Antolík, Andrej Morávek, <br>Katarína Stanislavová, Zuzana Jurečková</p>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import FlatsTable from "@/components/FlatsTable";
import B2Map from "@/components/maps/B2Map";
import ScrollTo from "@/plugins/scrollTo.util"
import ContactForm from "@/components/ContactForm";
export default {
  name: "Bory2",
  components: {ContactForm, FlatsTable, B2Map},
  mounted () {
    ScrollTo.hash(this,  () => window.scrollTo(0, 0))
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
#mapbox
  @media (max-width: $md)
    overflow: hidden
.about-bottom-2
  .bg
    background: url(../assets/images/bory2/bottom-image.jpg) center/cover no-repeat
.about-bottom
  .right-img
    background: url(../assets/images/bory2/bory2-domov.jpg) center/cover no-repeat

.schedule
  background: var(--textBright)
  .phase
    @media (max-width: $md)
      border-left: 2px solid var(--textDark)
      padding-left: 15px !important
      display: flex
      flex-direction: column
      padding-bottom: 20px !important
      &.fill
        border-color: var(--blue)
        &:before
          background: var(--blue)
      &.fill-half
        &:before
          background: var(--blue)
        &:after
          content: ""
          width: 2px
          height: 60%
          background: var(--blue)
          position: absolute
          left: -2px
          top: 0
      &:last-child
        border-color: transparent
      &:before
        content: ""
        width: 12px
        height: 12px
        background: var(--textDark)
        border-radius: 50%
        position: absolute
        left: -7px
        top: 0
        margin-top: 0
  h3
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.5px
    @media (max-width: $md)
      order: 3
  p.date
    font-size: 16px
    line-height: 20px
    letter-spacing: -0.5px
    color: var(--blue)
    font-weight: 500
    @media (max-width: $md)
      order: 1
      margin-top: -3px
  .icon
    height: 40px
    margin-bottom: 10px
    @media (max-width: $md)
      order: 2
  .line
    margin: 20px 0
    height: 2px
    background: var(--textDark)
    position: relative
    @media (max-width: $md)
      display: none
    &:before
      content: ""
      width: 12px
      height: 12px
      background: var(--textDark)
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      margin-top: -5px
    &.fill
      background: var(--blue)
      &:before
        background: var(--blue)
    &.fill-min
      &:before
        background: var(--blue)
    &.fill-half
      &:before
        background: var(--blue)
      &:after
        content: ""
        width: 50%
        height: 100%
        background: var(--blue)
        position: absolute
        left: 0
        top: 0
    &.transparent
      background: transparent

.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .bg-img
    background: url("../assets/images/bory2/bory2-bpt.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 320px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory2/Bory2.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory2/bory2-jazierko.jpg") bottom/cover no-repeat
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory2/bory2-parkovanie.jpg") right/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory2/bory2-home.jpg") bottom/cover no-repeat
  p
    margin-bottom: 30px
    max-width: 770px

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>
