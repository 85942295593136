<template>
  <div class="modal-body">
    <p class="smaller">
    </p><p>Vitajte na stránke <strong>www.borybyvanie.sk.</strong> Hneď v&nbsp;úvode používania nášho
    webu Vám odporúčame pozorne sa oboznámiť s&nbsp;týmito pravidlami spracúvania osobných údajov, ktorými sa pri spracúvaní
    Vami poskytnutých osobných údajov riadime. </p>
    <ol>
      <li><strong>Úvod</strong>

        <p>Vaše súkromie je pre nás dôležité, preto sa snažíme o&nbsp;zabezpečenie vysokej úrovne ochrany Vašich osobných
          údajov pri
          ich spracúvaní ako aj o&nbsp;zachovanie transparentnosti ich spracúvania v&nbsp;súlade Nariadením Európskeho
          parlamentu a&nbsp;Rady
          (EÚ) 2016/679 z&nbsp;27. apríla 2016, o&nbsp;ochrane osobných údajov ktorým sa zrušuje smernica 95/45/ES (všeobecné
          nariadenie
          o&nbsp;ochrane údajov) (ďalej len „<strong>GDPR</strong>“). </p>
        <p>Na účely GDPR je prevádzkovateľom určujúcim účel a&nbsp;rozsah spracúvania osobných údajov poskytnutých
          prostredníctvom
          niektorej z&nbsp;funkcionalít tohto webu spoločnosť <strong>Penta Real Estate, s. r. o., </strong>so sídlom
          Digital Park
          II, Einsteinova 25, Bratislava 851 01, IČO: 51&nbsp;284&nbsp;502, zapísaná v&nbsp;obchodnom registri Okresného súdu
          Bratislava I,
          oddiel: Sro, vložka č. 124856/B (ďalej len aj ako „<strong>PRE</strong>“ alebo
          <em>„</em><strong>Prevádzkovateľ PRE</strong><em>“</em>). V&nbsp;prípade, ak máte akékoľvek
          otázky
          týkajúce sa spracúvania osobných údajov, ktoré sme od Vás získali prostredníctvom niektorej z&nbsp;funkcionalít
          tohto
          webu, alebo v&nbsp;prípade ak chcete uplatniť niektoré z&nbsp;nižšie uvedených práv vyplývajúcim Vám z&nbsp;GDPR, prosím
          kontaktujte nás prostredníctvom písomných podaní adresovaných na vyššie uvedenú adresu sídla Prevádzkovateľa
          PRE
          alebo elektronicky na e-mailovej adrese <strong>GDPR_PRE_SR@pentarealestate.com</strong>. </p>
        <p>V&nbsp;prípade informačného systému IS Marketing skupiny sú PRE a&nbsp;spoločnosť Penta Investments, s. r. o., so
          sídlom
          Digital Park II, Einsteinova 25, Bratislava 851 01, IČO: 47&nbsp;258 896, zapísaná v&nbsp;obchodnom registri Okresného
          súdu
          Bratislava I, oddiel: Sro, vložka č. 124855/B, spoločnými prevádzkovateľmi (ďalej len „<strong>Spoloční
            prevádzkovatelia</strong>“). </p>
      </li>
      <li><strong>Získavanie osobných údajov</strong>

        <p>Tieto pravidlá sa aplikujú na spracúvanie osobných údajov, ktoré od Vás získavame priamo prostredníctvom (a)
          vyplnenia kontaktného formulára, (b) žiadosti o&nbsp;zasielanie newsletterov, (c) poskytnutia spätnej väzby a (d)
          osobných údajov, ktoré nám poskytnete prostredníctvom telefonickej alebo e-mailovej komunikácie na základe
          kontaktných údajov sprístupnených na tejto webovej stránke. </p>
        <p>Počas používania tohto webu taktiež zbierame cookies. Pre viac informácií <a v-b-modal.cookies href="#">Zásady používania súborov cookies</a></p>
      </li>

      <li><strong>Osobné údaje, ktoré o&nbsp;Vás spracúvame</strong>

        <p>Za účelmi špecifikovanými nižšie v&nbsp;bode 4 týchto pravidiel sú spracúvané osobné údaje: </p>
        <ol style="list-style-type: lower-alpha">
          <li>o&nbsp;jednotlivcoch, ktorí majú záujem o&nbsp;kúpu bytov alebo kúpu resp. prenájom nebytových priestorov v&nbsp;
            realitných
            projektoch PRE, </li>
          <li>o jednotlivcoch, ktorí majú záujem byť informovaní o&nbsp;aktuálnej ponuke v&nbsp;rámci realitných projektov PRE
            a&nbsp; </li>
          <li>o jednotlivcoch, ktorých majú záujem osloviť Spoloční prevádzkovatelia v záujme realizácie ich
            marketingových
            aktivít. </li>
        </ol>
        <p>Získané osobné údaje však spracúvame v&nbsp;súlade so zásadou minimalizácie spracúvania osobných údajov zakotvenou
          v GDPR
          a&nbsp;preto ich spracúvame len v&nbsp;nevyhnutnej miere a&nbsp;nasledujúcom rozsahu: meno a&nbsp;priezvisko, e-mailová adresa
          a&nbsp;telefónne číslo. </p>
        <p>Predmetom nami vykonávaného spracúvania osobných údajov, nie sú osobitné kategórie osobných údajov podľa
          GDPR, ani
          osobné údaje osôb mladších ako 16 rokov. </p>
        <p> </p>
        <p>Je veľmi dôležité aby osobné údaje, ktoré o&nbsp;Vás spracúvame boli pravdivé a&nbsp;aktuálne. Informujete nás prosím,
          ak sa
          Vaše osobné údaje počas trvania spracúvania zmenia. </p>
      </li>

      <li><strong>Účely na ktoré spracúvame Vaše osobné údaje</strong>

        <p>Vaše osobné údaje spracúvame na nasledujúce účely: </p>
        <ol style="list-style-type: lower-alpha">
          <li>na komunikáciu s&nbsp;Vami, poskytnutie reakcie alebo spätnej väzby k&nbsp;prejavenému záujmu alebo podnetu
            z&nbsp;Vašej strany
            predloženému prostredníctvom niektorej z&nbsp;funkcionalít tejto webovej stránky alebo využitia kontaktných
            údajov
            umiestnených na tejto webovej stránke; </li>
          <li>na marketingové účely Spoločných prevádzkovateľov, ktoré sú v&nbsp;súlade so zákonom; a </li>
          <li>na účely správy a&nbsp;ochrany tejto webovej stránky. </li>
        </ol>
        <p>V&nbsp;prípade, ak spracúvame Vaše osobné údaje za účelom priameho marketingu vo forme zasielania newsletteru,
          vždy Vám
          dávame možnosť odhlásiť sa z&nbsp;odberu newsletteru, ak už nebudete mať kedykoľvek v&nbsp;budúcnosti záujem na jeho
          odbere.
        </p>
        <p>Osobné údaje spracúvané na marketingové účely Spoločných prevádzkovateľov sú spracúvané v&nbsp;osobitnom
          informačnom
          systéme IS Marketing skupiny na základe dohody medzi Spoločnými prevádzkovateľmi. </p>
      </li>

      <li><strong>Právny základ spracúvania Vašich osobných údajov </strong>

        <p>Spracúvanie Vašich osobných údajov na účely popísané vyššie v&nbsp;bode 4 pod písm. a) (t.j. komunikácia na
          základe Vami
          prejaveného záujmu) a&nbsp;na účely popísané v&nbsp;bode 4 pod písm. b) (t.j. marketingové aktivity Spoločných
          prevádzkovateľov) vychádza z&nbsp;nášho oprávneného záujmu (i) na aktívnej komunikácii s&nbsp;jednotlivcami, ktorí
          prejavia
          záujem o&nbsp;nehnuteľnosť z&nbsp;nášho realitného portfólia a (ii) na efektívnej marketingovej podpore a&nbsp;propagácii
          našich
          podnikateľských aktivít a&nbsp;ponuky realitného portfólia. Aj keď sú právnym základom spracúvania Vašich
          osobných naše
          oprávnené záujmy, vždy rešpektujeme a uprednostňujeme Vaše práva a záujmy pred našimi záujmami. </p>
        <p>V&nbsp;prípade spracúvania osobných údajov na účel správy a&nbsp;ochrany tejto webovej stránky popísaný v&nbsp;bode 4 pod
          písm. c)
          je tiež právnym základom spracúvania osobných údajov oprávnený záujem, ktorým je zabezpečenie poskytovania
          IT
          služieb a udržanie bezpečnosti. </p>
        <p>Spracúvanie Vašich osobných údajov na základe nášho oprávneného záujmu vyplýva z&nbsp;čl. 6 ods. 1 písm. f) GDPR.
          Máte
          však právo kedykoľvek namietať uvedené spracovanie Vašich osobných údajov. Za týmto účelom nás kedykoľvek
          kontaktuje
          písomne na adrese nášho sídla uvedeného v&nbsp;úvode týchto pravidiel alebo na e-mailovej adrese:
          <strong>GDPR_PRE_SR@pentarealestate.com</strong>. Každé podanie námietky dôsledne vyhodnotíme a o výsledku
          Vás
          budeme informovať. </p>
      </li>

      <li><strong>Doba spracúvania Vašich osobných údajov</strong>

        <p>Vaše osobné údaje uchovávame tak dlho, ako je to potrebné na splnenie účelov, na ktoré sme ich získali. Doba
          spracúvania Vašich osobných údajov je 3 roky odo dňa ich získania. Plynutie doby spracúvania osobných údajov
          pravidelne kontrolujeme a&nbsp;v&nbsp;prípade ak uplynie, spracúvanie osobných údajov ukončíme a&nbsp;osobné údaje vymažeme
          resp.
          zlikvidujeme. </p>
      </li>

      <li><strong>Zdieľanie Vašich osobných údajov s&nbsp;tretími stranami</strong>

        <p>V&nbsp;súlade s&nbsp;účelmi spracovania špecifikovanými v&nbsp;týchto pravidlách sa realizuje spracúvanie Vašich osobných
          údajov aj
          prostredníctvom tretích osôb. </p>
        <p>Takýmito tretími osobami sú poskytovatelia služieb, ktorých Prevádzkovateľ PRE využíva, aby mohlo byť
          realizované
          spracúvanie Vašich osobných údajov na účely podľa týchto pravidiel. Konkrétne ide o služby spojené
          s&nbsp;technickou a
          softwarovou podporou a administráciou marketingových a&nbsp;predajných aktivít Prevádzkovateľa PRE a&nbsp;spoločností,
          prostredníctvom ktorých Prevádzkovateľ PRE realizuje development, výstavbu a&nbsp;predaj nehnuteľností v&nbsp;rámci
          jednotlivých realitných projektov ako aj Spoločných prevádzkovateľov.&nbsp; </p>
        <p>Treťou osobou je aj spoločnosť <strong>Bory Home s. r. o.</strong>, so sídlom Digital Park III. Einsteinova 25, Bratislava - mestská časť Petržalka 851 01, IČO: 48 099 171, zapísaná v obchodnom registri Okresného súdu Bratislava I, oddiel: Sro, vložka č. 103386/B, prostredníctvom ktorej Prevádzkovateľ PRE realizuje development, výstavbu a&nbsp;predaj nehnuteľností v&nbsp;rámci projektu Bory Bývanie a&nbsp;Bory Bývanie II.</p>


        <p>Treťou osobou je aj spoločnosť <strong>Bory Home II s. r. o.</strong>, so sídlom Digital Park III. Einsteinova 25, Bratislava - mestská časť Petržalka 851 01, IČO: 51 035 073, zapísaná v obchodnom registri Okresného súdu Bratislava I, oddiel: Sro, vložka č. 122328/B, prostredníctvom ktorej Prevádzkovateľ PRE realizuje development, výstavbu a&nbsp;predaj nehnuteľností v&nbsp;rámci projektu Bory Bývanie a&nbsp;Bory Bývanie II.</p>


        <p>Treťou osobu je aj spoločnosť <strong>Media and Digital Services
          a.s</strong>., so sídlom Einsteinova 21, 851 01 Bratislava – mestská časť Petržalka, Slovenská
          republika, IČO: 48&nbsp;238&nbsp;902, zapísaná v&nbsp;obchodnom registri Okresného súdu Bratislava I, oddiel: Sa, vložka č.
          6196/B,
          ktorá zabezpečuje pre Prevádzkovateľa PRE administráciu marketingových aktivít. </p>
        <p>Na spracúvaní Vašich osobných údajov sa ako subsprostredkovateľ podieľa aj spoločnosť poskytujúca služby
          webovej
          podpory a&nbsp;marketingovej komunikácie. </p>
        <p>V&nbsp;súvislosti so súbormi cookies, na ktoré nie je potrebné súhlas v&nbsp;zmysle Zásad používania súborov cookies
          (link
          vyššie) sú našimi sprostredkovatelia nasledovné spoločnosti: </p>
        <ul>
          <li><strong>Google IrelandLtd.</strong>, so sídlom Gordon House
            Barrow Street, Dublin 4, DO4E5W5, Írsko, IČO (CRO): 368047, v&nbsp;súvislosti so spracúvaním analytických
            súborov
            cookies prostredníctvom platformy Google Analytics; </li>

          <li><strong>Facebook IrelandLtd.</strong>, so sídlom 4 Grand
            Canal Square, Grand Canal Harbour, Dublin 2, Írsko, IČO (CRO): 462932, v&nbsp;súvislosti so spracúvaním
            analytických
            súborov cookies prostredníctvom platformy Facebook Pixel. </li>
        </ul>
        <p>Prevádzkovateľ PRE uzatvoril so sprostredkovateľmi zmluvy o&nbsp;spracúvaní osobných údajov. </p>
        <p>Sprostredkovatelia aj subsprostredkovateľ sú zaviazaní spracúvať Vaše osobné údaje výlučne na účely, ako sú
          popísané
          v&nbsp;týchto pravidlách. Vaše dáta nie sú sprístupnené tretím stranám na účely ich nezávislého využitia alebo
          spracovania nad rámec účelov podľa týchto pravidiel. </p>
      </li>

      <li><strong>Medzinárodný prenos Vašich osobných údajov</strong>

        <p>Softwarový systém, v&nbsp;ktorom sú Vaše osobné údaje spracúvané na základe licenčnej zmluvy medzi
          Prevádzkovateľom PRE
          a&nbsp;poskytovateľom softwarového systému, je uložený na serveroch poskytovateľa tohto systému so sídlom v
          Spojenom
          kráľovstve Veľkej Británie a&nbsp;Severného Írska. </p>
      </li>

      <li><strong>Vaše práva súvisiace so spracúvaním Vašich osobných údajov</strong>

        <p>GDPR Vám priznáva značný rozsah práv, ktoré rešpektujeme a&nbsp;umožníme Vám uplatňovať ich v&nbsp;plnom rozsahu.
          V&nbsp;rámci
          uplatnenia Vašich práv ste oprávnení najmä: </p>
        <ul>
          <li>požiadať nás o&nbsp;kópiu osobných údajov, ktoré o&nbsp;Vás vedieme, a&nbsp;opis toho ako tieto osobné údaje používame;
          </li>
          <li>požiadať o&nbsp;nápravu osobných údajov, ktoré o&nbsp;Vás vedieme, ak sa domnievate, že tieto osobné údaje môžu
            byť
            nepresné, neaktuálne, nesprávne; </li>
          <li>požiadať o&nbsp;výmaz osobných údajov, ktoré o&nbsp;Vás spracúvame v&nbsp;prípade ak Vaše údaje spracúvame na základe
            súhlasu;
          </li>
          <li>požiadať o&nbsp;poskytnutie Vašich osobných údajov, ktoré o&nbsp;Vás spracúvame automatizovanými prostriedkami na
            základe
            Vášho súhlasu, tretej strane; </li>
          <li>požiadať, aby sme obmedzili používanie Vašich osobných údajov alebo účel ich spracúvania v&nbsp;určitých
            obmedzených
            prípadoch; </li>
          <li>za určitých okolností namietať spracúvania Vašich osobných údajov, keďže spracúvame Vaše osobné údaje na
            základe
            našich oprávnených záujmov. </li>
        </ul>
      </li>

    </ol>
    <p>V&nbsp;prípade ak máte záujem o&nbsp;bližšie informácie o Vašich právach vyplývajúcich z&nbsp;GDPR, ktoré je možné uplatniť voči nám
      vo vzťahu so spracúvaniu Vašich osobných údajov, neváhajte nás kontaktovať písomne na adrese nášho sídla uvedeného
      v&nbsp;úvode týchto pravidiel alebo na e-mailovej adrese: <strong>GDPR_PRE_SR@pentarealestate.com.</strong> </p>
    <p>Našim záujmom je snažiť sa zodpovedať akékoľvek Vaše otázky a&nbsp;vyriešiť akúkoľvek sťažnosť súvisiacu so spracúvaním
      Vašich osobných údajov. Ak však máte pocit, že sme Vašu sťažnosť nevyriešili, berte na vedomie, že máte právo
      sťažovať sa na Úrade na ochranu osobných údajov Slovenskej republiky. </p>
    <p>Tieto pravidlá spracúvania osobných údajov môžu byť jednostranne kedykoľvek doplnené alebo zmenené. </p>
    <p><strong>Penta Real Estate, s.r.o.</strong> </p>
    <p><strong>Penta Investments, s. r. o.</strong> </p>
    <p></p>
  </div>
</template>

<script>
export default {
name: "AgreementsModal"
}
</script>

<style scoped>

</style>