<template>
  <div class="bg-white" :class="[stage, building.replace('.', ''), 'a' + floor]" @mouseleave="mouseLeave">
    <section v-if="floorData && paths" class="podlazie" id="floor-plan" :class="floorSvgClass"
             :style="{backgroundImage: 'url(' + floorData.floor_plan + '?' + timestamp + ')' }">
      <svg x="0px" y="0px" :viewBox="floorMap[this.stage][this.building].viewBox" :style="{transform: floorMap[stage][building][floor]}" @mouseleave="mouseLeave">
        <g v-for="(path, i) in paths" :key="i">
          <path v-if="path.d.startsWith('M')"
                @mouseenter="mouseEnter" @mousemove="mouseMove" @mouseleave="mouseLeave" @click="showDetail"
                :data-pathId="path.id" :data-pentaId="path.pentaId" :d="path.d" :class="path.class" />
          <polygon v-else
                   @mouseenter="mouseEnter" @mousemove="mouseMove" @mouseleave="mouseLeave" @click="showDetail"
                   :data-pathId="path.id" :data-pentaId="path.pentaId" :points="path.d" :class="path.class" />
        </g>
      </svg>
    </section>
    <div id="info-tabulka" :class="{show: showTable}" :style="{left: tableLeft + 'px', top: tableTop + 'px'}">
        <div class="d-flex border-bottom py-2">
          <div class="col-auto">Stav:</div>
          <div class="col text-right pl-2">{{tableStatus}}</div>
        </div>
        <div class="d-flex border-bottom py-2" v-if="flatStatus == 0 || flatStatus == 1">
          <div class="col-auto">Počet izieb:</div>
          <div class="col text-right pl-2">{{tableDisposition}}</div>
        </div>
        <div class="d-flex border-bottom py-2" v-if="flatStatus == 0 || flatStatus == 1">
          <div class="col-auto">Výmera bytu:</div>
          <div class="col text-right pl-2">{{tableArea}} m<sup>2</sup></div>
        </div>
        <div class="d-flex py-2" v-if="flatStatus == 0 || flatStatus == 1">
          <div class="col-auto">Cena s DPH:</div>
          <div class="col text-right pl-2" v-if="tablePrice">{{currency(tablePrice)}}</div>
        </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import floorMap from "@/store/floors.json"

export default {
  name: "Podlazie",
  props: {
    stage: {
      type: [String],
      default: null
    },
    building: {
      type: [String],
      default: null
    },
    floor: {
      type: [String],
      default: null
    },
    filter: {
      type: Object,
      default: null
    }
  },
  watch: {
    floor: async function () {
      await this.loadData()
      this.$forceUpdate()
    },
    building: async function () {
      await this.loadData()
      this.$forceUpdate()
    }
  },
  data() {
    return {
      tableStatus: "",
      flatStatus: null,
      flatBuilding: "",
      flatStage: "",
      tableDisposition: "",
      tableArea: "",
      tablePrice: "",
      tableLeft: 0,
      tableTop: 0,
      showTable: false,

      timestamp: 0,
      floorMap: floorMap,
      floorData: null,
      floorId: null,
      paths: [],
      floorSvgClass: '',
      classes: {
        "0": "free",
        "1": "prereserved",
        "2": null,
        "3": null,
        "4": "contact",
        "5": "contact"
      },
      statuses: {
        "0": "Voľný",
        "1": "Predrezervovaný",
        "2": "Rezervovaný",
        "3": "Predaný",
        "4": "Kontaktujte predajcu",
        "5": "Kontaktujte predajcu",
      }
    }
  },
  mounted() {
    this.loadData()
  },
  created () {
    window.addEventListener('scroll', this.mouseLeave);
  },
  destroyed () {
    window.removeEventListener('scroll', this.mouseLeave);
  },
  methods: {
    async mouseEnter(oEvent) {
      const id = oEvent.target.getAttribute("data-pentaId")
      const resp = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/apartment-houses/${this.stage}/${id}`)
      const data = resp.data.data
      this.showTable = true

      // flat_status
      this.tableStatus = this.statuses[data.flat_status]
      this.flatStatus = data.flat_status
      this.flatBuilding = data.flat_penta_internal_id
      this.flatStage = data.building.building_stage
      // flat_disposition
      this.tableDisposition = data.flat_disposition
      // flat_area
      this.tableArea = data.flat_area
      // flat_price
      if(data.flat_status == "0")
        this.tablePrice = data.flat_price + ' €'
      else
        this.tablePrice = null


    },

    currency: price => {
      let value = (price)
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return `${value}`
    },

    mouseMove(oEvent) {
      this.tableLeft = oEvent.clientX + 15
      this.tableTop = oEvent.clientY + 15
      if (window.innerWidth - this.tableLeft < 270)
        this.tableLeft = oEvent.clientX - 200
      this.$forceUpdate()
    },

    mouseLeave() {
      this.showTable = false
    },

    showDetail() {
      if ( this.flatStatus == 0 || this.flatStatus == 1) {
        this.$router.push({
          name:'FlatDetail',
          params:{
            id: this.flatBuilding,
            stage: this.flatStage,
            filter: this.filter,
            floor: this.floor,
            building: this.building
          }
        });
      }

      if (this.flatStatus == 4 || this.flatStatus == 5) {
        const contactForm = document.getElementById('contact-form')
        contactForm.scrollIntoView({behavior: "smooth", block: 'center'})
      }
    },

    async loadData() {
      this.paths = []
      this.floorId = this.building + "-" + this.floor
      const resp = await axios.get(`${process.env.VUE_APP_API_URL}/api/v1/buildings/${this.stage}/${this.building}`)
      this.timestamp = Math.floor((new Date().getTime())/1000)
      this.floorData = resp.data.data.building_floors.find( f => f.floor_number == this.floor)

      if (this.building == 'B1.2') {
        this.floorSvgClass = 'smallSvg'
      } else if (
          this.building == 'C2.1' 
          || this.building == 'C1.2' 
          || (this.stage == 'bory4' && this.building == 'A2')
          || (this.stage == 'bory4' && this.building == 'A1')
          || (this.stage == 'bory4' && this.building == 'A3')
          || (this.stage == 'bory4' && this.building == 'A4')
        ) {
        this.floorSvgClass = 'mediumSvg'
      } else {
        this.floorSvgClass = ''
      }

      if(floorMap[this.stage][this.building]['image' + this.floor])
          // eslint-disable-next-line @typescript-eslint/camelcase
        this.floorData.floor_plan = floorMap[this.stage][this.building]['image' + this.floor]

        this.floorData.floor_flats.forEach( f =>  {
          const id = f.flat_penta_internal_id.split(".").join("_").split("-").join("_")
          if(floorMap[this.stage][this.building][id]) {
            if (this.paths.find(o => o.id === id)) return
            this.paths.push({
              id: id,
              pentaId: f.flat_penta_internal_id,
              d: floorMap[this.stage][this.building][id],
              class: this.classes[f.flat_status]
            })
          }
            
        })

    }
  }
}
</script>

<style lang="sass" scoped>
#info-tabulka
  position: fixed
  left: 0
  top: 0
  display: none
  background: rgba(255, 255, 255, 1)
  z-index: 10
  color: var(--darkBlue)
  &.show
    display: block
.bory2b
  .podlazie
    padding: 50px 0
    background-size: 80%
  &.B21,
  &.A23,
  &.A21,
  &.A22
    .podlazie
      padding: 0
      svg
        display: block
        width: 45%
        margin: auto
  &.B22
    .podlazie
      padding: 100px 0
      svg
        display: block
        width: 45%
        margin: auto
  &.B21.a1,
  &.A23.a1,
  &.A22.a1
    .podlazie
      padding: 50px 0
.podlazie
  background-position: center center
  background-size: cover
  background-repeat: no-repeat
  background-color: white
  overflow: hidden
  &.smallSvg
    max-width: 660px
    margin: auto
  &.mediumSvg
    max-width: 60%
    margin: auto
  svg
    transform: scale(0.91) translateX(2%)
    position: relative
    z-index: 2
    width: 100%
    height: auto
    path, polygon
      fill: rgba(0, 0, 0, 0.7)
      &.prereserved
        fill: transparent
        cursor: pointer
        &:hover
          fill: rgba(226, 105, 26, 0.4)
      &.free
        fill: transparent
        cursor: pointer
        &:hover
          fill: rgba(69, 167, 46, 0.4)
      &.contact
        cursor: pointer
        fill: transparent
  pre
    color: white

</style>


<!--<script>-->
<!--var json = {}-->
<!--$(".floors .tab-pane").each(function(){-->
<!--  var acka = $(this).find("svg #layer_2 a")-->
<!--  acka.each(function(){-->
<!--    var idecko = $(this).get(0).id.replace("apartment_", "")-->
<!--    var path = $(this).find('path').attr("d") || $(this).find('polygon').attr("points")-->
<!--    json[idecko] = path-->
<!--  })-->
<!--})-->
<!--window.copy(json)-->
<!--</script>-->
