import Vue from 'vue'
import Hotjar from 'vue-hotjar'

// Get cookies info
import cookies from '@/plugins/cookie-banner/getCookie'

export default {
	addHotjar() {
		if (process.env.VUE_APP_HOTJAR_ID) {
			Vue.use (Hotjar, {
				id: process.env.VUE_APP_HOTJAR_ID
			})
		}
	},  
}